import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import Beautiful from '../../components/Beautiful'
import Loading from '../../components/Loading'
import GridSecundario from '../../components/GridSecundario'
import FormTipoAtividade from '../../components/FormTipoAtividade'
import ModalPrincipal from '../../components/ModalAlert/modal'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function ActivityAdd() {

	const navigate = useNavigate();
	const [qualTitulo, setQualTitulo] = useState(<></>)
	const [qualGrid, setQualGrid] = useState(<></>)

	const [idActive, setIdActive] = useState('')
	const [description, setDescription] = useState('')
	const [integrationid, setIntegrationid] = useState('')
	const [active, setActive] = useState(true)
	const [aty_id, setAty_id] = useState('')
	const [executiontype, setExecutiontype] = useState('')
	const [displayorder, setDisplayorder] = useState('')
	const [impeditiveactivity, setImpeditiveactivity] = useState('')
	const [datetimeinsert, setDatetimeinsert] = useState('')
	const [datetimeupdate, setDatetimeupdate] = useState('')


	const [optionsTipoAtividade, setOptionsTipoAtividade] = useState([])
	const [optionsAtividade, setOptionsAtividade] = useState([])

	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)
	const [habilitar, setHabilitar] = useState('')

	const [selected, setSelected] = useState([]);
	const [optionsATV, setOptionsATV] = useState([])

	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1).toString().padStart(2, "0") +
			"-" +
			today.getDate().toString().padStart(2, "0");
		let time =
			today.getHours().toString().padStart(2, "0") +
			":" +
			today.getMinutes().toString().padStart(2, "0");
		let JuntarDate = date + "T" + time;
		setDatetimeinsert(JuntarDate)

	
		validatToken();

	}, []);

	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {

			setOpenModal(false)
			GetTipoAtividade();
			GetAtividade();
			GetAtv();


		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});
	}

	//--------------------------------------------- TIPO DE LOCAL

	function ChamarModalTipoAtividade() {

		setAty_id("")

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''


		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			setOpenModalIncluirNovo(true)
			sessionStorage.removeItem("dadosTipoAtividade");
			setQualTitulo(<><h1 className='modal-tit'>Cadastrar Tipo de atividade</h1></>)
			setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
				th1='Ativo'
				th2='Descrição'
				th3='Identificador Alternativo'
				th4='Subcategoria'
				th5='Data da Inclusão'
				th6='Data Atualização'
				endPoint='activitytype/listar'
				consult='N'
				col1='3'
				col2='2'
				col3='1'
				ambiente='%'
			/></>)
			)


		}).catch(function (error) {


			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
				<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
			</div>)

			setOpenModal(true)


		});

	}

	function ChamarCadastroTipoAtividade() {
		sessionStorage.removeItem("dadosTipoAtividade");
		setQualGrid(<><FormTipoAtividade voltar={BtnVoltarTipoAtividade} /></>)
	}

	function BtnVoltarTipoAtividade() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
			th1='Ativo'
			th2='Descrição'
			th3='Identificador Alternativo'
			th4='Subcategoria'
			th5='Data da Inclusão'
			th6='Data Atualização'
			endPoint='activitytype/listar'
			consult='N'
			col1='3'
			col2='2'
			col3='1'
			ambiente='%'
		/></>))

		GetTipoAtividade();
	}

	async function EditarTipoAtividade(obj: any) {

		sessionStorage.setItem("dadosTipoAtividade", JSON.stringify(obj));
		setQualGrid(<><FormTipoAtividade voltar={BtnVoltarTipoAtividade} /></>)
	}

	function TipoAtividadeDeletar(obj: any) {
	
		setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarTipoAtividade(obj.aty_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)

	}

	async function deletarTipoAtividade(id: any) {

		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;

		setOpenModal(false)

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			axios({
				method: "delete",
				url: endpoint + '/activitytype/delete',
				headers: {
					Authorization: _user
				},
				data: {
					id: id
				},
			}).then(async function (response) {

				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
					th1='Ativo'
					th2='Descrição'
					th3='Identificador Alternativo'
					th4='Subcategoria'
					th5='Data da Inclusão'
					th6='Data Atualização'
					endPoint='activitytype/listar'
					consult='N'
					col1='3'
					col2='2'
					col3='1'
					ambiente='%'
				/></>))
				GetTipoAtividade();
				setAty_id("")

			})
				.catch(function (error) {

					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
						th1='Ativo'
						th2='Descrição'
						th3='Identificador Alternativo'
						th4='Subcategoria'
						th5='Data da Inclusão'
						th6='Data Atualização'
						endPoint='activitytype/listar'
						consult='N'
						col1='3'
						col2='2'
						col3='1'
						ambiente='%'
					/></>))
					GetTipoAtividade();
					setAty_id("")
				});

		}).catch(function (error) {


			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
				<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
			</div>)

			setOpenModal(true)


		});
	}

	//-------------------------------------------- GRUPO DE LOCAIS

	async function GetAtv() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/activity/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsATV(valor)

			}

		})
			.catch(function (error) {
				setOptionsATV([])
			});
	}


	async function GetAtividade() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/activity/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {

			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: user.act_id, label: user.act_description });
				});
				setOptionsAtividade(arr)

			}
		})
		.catch(function (error) {
			setOptionsAtividade([])
		});
	}

	async function GetTipoAtividade() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/activitytype/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.aty_id), label: user.aty_description });
				});
				setOptionsTipoAtividade(arr)

			}
		})
		.catch(function (error) {
			setOptionsTipoAtividade([])
		});
	}

	function PegarValorGrid(valor: any) {

		setAty_id(String(valor.aty_id))
		setOpenModalIncluirNovo(false)

	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	const tipoExec = [{
		value: 1,
		label: 'Finalizar Atividade'
	},
	{
		value: 2,
		label: 'Finalizar Tarefa'
	},
	]

	function fecharModalToken() {
		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		setOpenModal(false)
		navigate('/login');

	}

	function fecharModal() {
		setOpenModal(false)
	}

	function fecharPag() {
		navigate('/activity');
	}



	async function validatTokenConfi() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			confirmarUser()

		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModalToken}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});
	}


	async function confirmarUser() {

		setOpenModal(true)
		setQualAlert(<div className='div-alert'><Loading /></div>)

		const FiltrarLogin = optionsATV.filter(
			(e: any) => {
				return e.act_description.toLowerCase() === description.toLowerCase()
			}
		);

		const FiltrarIntegrationid = optionsATV.filter(
			(e: any) => {
				return e.act_integrationid.toLowerCase() === integrationid.toLowerCase()
			}
		);

		if (FiltrarLogin.length > 0) {


			setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
			<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
		</div>)


		} else

			if (description === "") {

				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

			} else

			if (FiltrarIntegrationid.length > 0) {


				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)
	
	
			} else if (integrationid === "") {

				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Digite o Identificador Alternativo.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

			} else if (aty_id === '') {

				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Selecione o Tipo de Atividade.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)


			} else if (executiontype === '') {

				setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Selecione o Tipo de Execução.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

			} else {
				let ret: AxiosResponse;

				const dados = [{
					act_description: description,
					act_integrationid: integrationid,
					act_active: active,
					aty_id: aty_id || null,
					act_displayorder: displayorder,
					act_impeditiveactivity: impeditiveactivity || null,
					act_executiontype: executiontype || null,
					age_id_insert: 1 //colocar o usuario
				}];

				const _user = secureLocalStorage.getItem('t') as ''
				const endpoint = secureLocalStorage.getItem('ep') as ''

				axios({
					method: "post",
					url: endpoint + '/activity/insert',
					headers: {
						Authorization: _user
					},
					data: {
						data: dados,
						dadosimpediment: selected
					}
				}).then(async function (ret) {
					if (ret.data.status === 200) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-check ControleIconAlert"></span>
							<div className='div-info-alert'><span className="info-alert">Atividade criada com sucesso.</span></div>
							<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
						</div>)

					}
				})
					.catch(function (error) {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert'><span className="info-alert">Não foi possível criar a atividade.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)

					});
			}
	}



	function finalizarModal1() {
		navigate('/activity');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}


	function HabilitarFunc() {
		setOpenModal(false)
		setHabilitar('S')
	}

	function cancelar() {
		navigate('/activity');
	}


	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};

	function handleChange(selectedOption: any) {
		setSelected(selectedOption)
	};

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod19">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										type="text"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
										autoComplete="off"
									/>

								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Identificador Alternativo *</span>
									<input
										type="text"
										onChange={(e) => setIntegrationid(e.target.value)}
										value={integrationid}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content3">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Tipo de Atividade *</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' onClick={ChamarModalTipoAtividade} />
										<span className="details-esc-ativo" onClick={ChamarModalTipoAtividade}>Incluir Novo</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsTipoAtividade}
										styles={customStyles}
										value={optionsTipoAtividade.filter(function (option: any) {
											return option.value === aty_id;
										})}
										onChange={(options: any) =>
											!options ? setAty_id('') : setAty_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod2">
							<div id="content1">
								<div className="select-box-ativos-select">
									<span className="details">Tipo de Execução *</span>
									<Select
										placeholder={'Selecione...'}
										options={tipoExec}
										styles={customStyles}
										value={tipoExec.filter(function (option: any) {
											return option.value === executiontype;
										})}
										onChange={(options: any) =>
											!options ? setExecutiontype('') : setExecutiontype(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Ordem de exibição</span>
									<input
										type="text"
										onChange={(e) => setDisplayorder(maskOnlyNumbers(e.target.value))}
										value={displayorder}
										autoComplete="off"
									/>

								</div>
							</div>
							<div id="content3">
								<div className="select-box-ativos-select">
									<span className="details">Atividade Impeditiva</span>
									<Select
										styles={customStyles}
										isMulti
										value={selected}
										options={optionsAtividade}
										onChange={(options: any) =>
											!options ? handleChange([]) : handleChange(options)
										}

										placeholder={'Selecione...'}
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>
						</div>



						<div className="container-cad-prod18">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Data da Inclusão</span>
									<input
										type="datetime-local"
										value={datetimeinsert}
										disabled
									/>
								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Data da Última Atualização</span>
									<input
										type="datetime-local"
										value={datetimeupdate}
										disabled
									/>
								</div>
							</div>
						</div>


						{habilitar === 'S' ? (
							<>
								<div className="title-Page">
									<div className="title-text">
										<div className="size-x circle">2</div>
										<h2>Crie e edite as seções desta atividade.</h2>
									</div>
									<hr />
								</div>

								<Beautiful />
							</>
						) : null}




						<div className="Bt-button-cad">
							<button type="submit" onClick={validatTokenConfi}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar} >
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>
					</div>
				</main>
			</div>

			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

		</>
	);

}

export default ActivityAdd;