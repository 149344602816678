import React from 'react'
import './modal.css'

export default function Modal({ isOpen, setModalOpen, children }) {
  if (isOpen) {
    return (
      <div className='backgruound-style'>
        <div className='modal-alert'>
          <div>{children}</div>
        </div>
      </div>
    )
  }

  return null
}



