import React, {useState} from 'react';
import './style.css'


const Loading = () => {
    return (
        <div className='container-loader'>
        <div className='loader'></div>
        <h1 className='loader-text'>Carregando...</h1>
        </div>

     )
}

export default Loading;