import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet
} from "react-router-dom";

import Dashboard from "../pages/Dashboard"
import Login from "../pages/Login";
import NoPage from "../pages/NoPage"

import VisualizarUsuarios from '../pages/Usuarios'
import UsuariosAdd from "../pages/UsuariosAdd";
import UsuariosEdit from "../pages/UsuariosEdit";

import ProtectedRoutes from "./ProtectedRoutes"
import PublicRoutes from "./PublicRoutes"
import PermissionDenied from "./PermissionDenied"
import InnerContent from "./InnerContent"

import VisualizarTeam from '../pages/Team'
import TeamAdd from "../pages/TeamAdd";
import TeamEdit from "../pages/TeamEdit"

import VisualizarLocais from '../pages/Locais'
import LocaisAdd from "../pages/LocaisAdd";
import LocaisEdit from "../pages/LocaisEdit"

import VisualizarActivity from '../pages/Activity'
import ActivityAdd from "../pages/ActivityAdd";
import ActivityEdit from "../pages/ActivityEdit";

import VisualizarMyrecords from '../pages/Myrecords'

import VisualizarTask from "../pages/Task"
import TaskAdd from "../pages/TaskAdd"
import TaskEdit from "../pages/TaskEdit"

import VisualizarPlanner from "../pages/Planner"
import PlannerAdd from "../pages/PlannerAdd"
import PlannerEdit from "../pages/PlannerEdit"

import Importdata from "../pages/ImportData"

export function Router() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>

            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<Dashboard />} />

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/user" element={<VisualizarUsuarios />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/user/add" element={<UsuariosAdd />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/user/edit/:id" element={<UsuariosEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DIRECIONADOR,DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/team" element={<VisualizarTeam />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DIRECIONADOR,DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/team/add" element={<TeamAdd />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DIRECIONADOR,DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/team/edit/:id" element={<TeamEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/locais" element={<VisualizarLocais />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/locais/add" element={<LocaisAdd />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/locais/edit/:id" element={<LocaisEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/activity" element={<VisualizarActivity />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/activity/add" element={<ActivityAdd />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/activity/edit/:id" element={<ActivityEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/myrecords" element={<VisualizarMyrecords />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DIRECIONADOR,DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/task" element={<VisualizarTask />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DIRECIONADOR,DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/task/add" element={<TaskAdd />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DIRECIONADOR,DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/task/edit/:id" element={<TaskEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/planner" element={<VisualizarPlanner />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/planner/add" element={<PlannerAdd />} />
            </Route>
            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/planner/edit/:id" element={<PlannerEdit />} />
            </Route>

            <Route element={<ProtectedRoutes roleRequired="DESENVOLVEDOR,ADMINISTRADOR,CONSULTOR" />}>
              <Route path="/importdata" element={<Importdata />} />
            </Route>
          </Route>

        </Route>


        <Route path="login" element={<PublicRoutes />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/denied" element={<PermissionDenied />} />
        <Route path="*" element={<NoPage />} />
      </Routes>


    </BrowserRouter>
  )
}

export default Router
