import React, { useState, useEffect } from "react";
import "line-awesome/dist/line-awesome/css/line-awesome.css";
import "./index.css";
import logomenu from "../../img/now.png"
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router';
import secureLocalStorage from "react-secure-storage";

const Nav = () => {

  const navigate = useNavigate();

  const [componenteNav, setComponenteNav] = useState(<></>)


  useEffect(() => {

    const _user = secureLocalStorage.getItem('acp')

  
    if (_user === 'DESENVOLVEDOR' || _user === 'ADMINISTRADOR' || _user === 'CONSULTOR') {

      setComponenteNav(<div className="sidebar-menu">
        <ul>

          <li className="side">
            <Link to='/dashboard'>
              <span className='fa fa-area-chart ControleIcon'></span> <span className="lab-icone">Dashboard</span>
            </Link>
            <h1 className="Legenda-side">Dashboard</h1>
          </li>

          <li className="side">
            <Link to="/user">
              <span className="fa fa-user ControleIcon"></span>{" "}
              <span className="lab-icone">Usuários</span>
            </Link>
            <h1 className="Legenda-side">Usuários</h1>
          </li>
          <li className="side">
            <Link to='/team'>
              <span className="fa fa-users ControleIcon"></span>{" "}
              <span className="lab-icone">Equipes</span>
            </Link>
            <h1 className="Legenda-side">Equipes</h1>
          </li>

          <li className="side">
            <Link to='/locais'>
              <span className="fa-sharp fa-solid fa-city ControleIcon"></span>{" "}
              <span className="lab-icone">Locais</span>
            </Link>
            <h1 className="Legenda-side">Locais</h1>
          </li>

          <li className="side">
            <Link to='/activity'>
              <span className="fa-solid fa-clipboard-list ControleIcon"></span>{" "}
              <span className="lab-icone">Atividades</span>
            </Link>
            <h1 className="Legenda-side">Atividades</h1>
          </li>

          <li className="side">
            <Link to='/myrecords'>
              <span className='fa fa-list ControleIcon'>
              </span><span className="lab-icone">Meus Cadastros</span>
            </Link>
            <h1 className="Legenda-side">Meus Cadastros</h1>
          </li>

          <li className="side">
            <Link to='/task'>
              <span className='fa-sharp fa-solid fa-table-list ControleIcon'></span>
              <span className="lab-icone">Tarefas</span>
            </Link>
            <h1 className="Legenda-side">Tarefas</h1>
          </li>

          <li className="side">
            <Link to='/planner'>
              <span className='fa-solid fa-calendar-days ControleIcon'></span>
              <span className="lab-icone">Planejador</span>
            </Link>
            <h1 className="Legenda-side">Planejador</h1>
          </li>

          <li className="side" onClick={Sair}>
            <a><span className='fa-solid fa-arrow-right-from-bracket ControleIcon'></span><span className="lab-icone">Sair</span> </a>
            <h1 className="Legenda-side">Sair</h1>
          </li>

        </ul>
      </div>)

    }else  if (_user === 'DIRECIONADOR') {
      setComponenteNav(<div className="sidebar-menu">
      <ul>

        <li className="side">
          <Link to='/dashboard'>
            <span className='fa fa-area-chart ControleIcon'></span> <span className="lab-icone">Dashboard</span>
          </Link>
          <h1 className="Legenda-side">Dashboard</h1>
        </li>

        <li className="side">
          <Link to='/team'>
            <span className="fa fa-users ControleIcon"></span>{" "}
            <span className="lab-icone">Equipes</span>
          </Link>
          <h1 className="Legenda-side">Equipes</h1>
        </li>

        <li className="side">
          <Link to='/task'>
            <span className='fa-sharp fa-solid fa-table-list ControleIcon'></span>
            <span className="lab-icone">Tarefas</span>
          </Link>
          <h1 className="Legenda-side">Tarefas</h1>
        </li>

        <li className="side" onClick={Sair}>
          <a><span className='fa-solid fa-arrow-right-from-bracket ControleIcon'></span><span className="lab-icone">Sair</span> </a>
          <h1 className="Legenda-side">Sair</h1>
        </li>

      </ul>
    </div>)
    }

  }, []);

  function Sair() {
    navigate('/login');
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')

  }

  return (
    <>
      <input type="checkbox" id="nav-toggle" />
      {/* Side bar  menu */}
      <div className="sidebar">
        <div className="sidebar-brand">
          <div className="logimg"><img className="logoNav" src={logomenu} alt="logo" width={170} height={60} /></div>
        </div>


        {componenteNav}


      </div>
    </>
  );
};

export default Nav;
