import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import FormTipoLocal from '../../components/FormTipoLocal'
import FormGrupoLocal from '../../components/FormGrupoLocal'
import ModalPrincipal from '../../components/ModalAlert/modal'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function LocaisAdd() {

	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState(<></>)
	const [qualGrid, setQualGrid] = useState(<></>)

	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)

	const [active, setActive] = useState(true)
	const [description, setDescription] = useState('')
	const [corporatename, setCorporatename] = useState('')
	const [alternativeidentifier, setAlternativeidentifier] = useState('')

	const [age_id, setAge_id] = useState("")
	const [tea_id, setTea_id] = useState("")
	const [lty_id, setLty_id] = useState("")
	const [lgp_id, setlgp_id] = useState("")

	const [ddi_mobilephone, setDdi_mobilephone] = useState('')
	const [ddd_mobilephone, setDdd_mobilephone] = useState('')
	const [mobilephone, setMobilephone] = useState('')
	const [ddi_phone, setDdi_phone] = useState('')
	const [ddd_phone, setDdd_phone] = useState('')
	const [phone, setPhone] = useState('')
	const [mail, setMail] = useState('')

	const [lcy_id, setLcy_id] = useState("")
	const [datetimeinsert, setDatetimeinsert] = useState('')
	const [datetimeupdate, setDatetimeupdate] = useState('')

	const [observation, setObservation] = useState('')

	const [geoposition, setGeoposition] = useState('')
	const [country, setCountry] = useState('')
	const [state, setState] = useState('')
	const [city, setCity] = useState('')
	const [neighborhood, setNeighborhood] = useState('')
	const [street, setStreet] = useState('')
	const [streetnumber, setStreetnumber] = useState('')
	const [streetnumbercomplement, setStreetnumbercomplement] = useState('')
	const [zipcode, setZipcode] = useState('')

	const [optionsAgentGroup, setOptionsAgentGroup] = useState([])
	const [optionsAccessProfile, setOptionsAccessProfile] = useState([])

	const [optionsResponsavel, setOptionsResponsavel] = useState([])
	const [optionsEquipe, setOptionsEquipe] = useState([])
	const [optionsTipo, setOptionsTipo] = useState([])
	const [optionsGrupo, setOptionsGrupo] = useState([])
	const [optionsCriticidade, setOptionsCriticidade] = useState([])
	const [optionsLocal, setOptionsLocal] = useState([])

	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

	useEffect(() => {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		let today = new Date();
		let date =
			today.getFullYear() +
			"-" +
			(today.getMonth() + 1).toString().padStart(2, "0") +
			"-" +
			today.getDate().toString().padStart(2, "0");
		let time =
			today.getHours().toString().padStart(2, "0") +
			":" +
			today.getMinutes().toString().padStart(2, "0");
		let JuntarDate = date + "T" + time;
		setDatetimeinsert(JuntarDate)


		validatToken();

	}, []);


	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {

			setOpenModal(false)
			GetResponsavel();
			GetEquipe();
			GetLocaltype();
			GetlocalGroup();
			GetLocalCriticality();
			GetLocal();


		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});
	}

	function fecharModal() {
		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		setOpenModal(false)
		navigate('/login');

	}

	async function GetLocal() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/locais/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsLocal(valor)

			}
		})
			.catch(function (error) {
				setOptionsLocal([])

			});
	}

	async function GetResponsavel() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/user/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.age_id), label: user.age_name });
				});
				setOptionsResponsavel(arr)

			}

		})
			.catch(function (error) {
				setOptionsResponsavel([])
			});
	}

	async function GetEquipe() {
		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/team/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.tea_id), label: user.tea_description });
				});
				setOptionsEquipe(arr)



			}
		})
			.catch(function (error) {
				setOptionsEquipe([])
			});
	}


	async function GetLocaltype() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/localtype/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.lty_id), label: user.lty_description });
				});
				setOptionsTipo(arr)

			}
		})
			.catch(function (error) {
				setOptionsTipo([])
			});
	}


	async function GetlocalGroup() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/localgroup/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.lgp_id), label: user.lgp_description });
				});
				setOptionsGrupo(arr)

			}
		})
			.catch(function (error) {
				setOptionsGrupo([])
			});
	}


	async function GetLocalCriticality() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/localcriticality/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;

				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.lcy_id), label: user.lcy_description });
				});
				setOptionsCriticidade(arr)

			}
		})
			.catch(function (error) {
				setOptionsCriticidade([])
			});
	}

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}


	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	//--------------------------------------------- TIPO DE LOCAL

	function ChamarModalTipoLocal(tipoModal: any) {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''


		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {

			if (tipoModal === 'Função') {
				setOpenModalIncluirNovo(true)
				sessionStorage.removeItem("dadosTipoLocal");
				setQualTitulo(<><h1 className='modal-tit'>Cadastrar Tipo de Local</h1></>)
				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridlty_id} Iconeadd={ChamarCadastroTipoLocal} Iconeditar={EditarTipoLocal} iconeExcluir={TipoLocalDeletar}
					th1='Ativo'
					th2='Descrição'
					th3='Identificador Alternativo'
					th4='Subcategoria'
					th5='Data da Inclusão'
					th6='Data Atualização'
					endPoint='localtype/listar'
					consult='N'
					col1='1'
					col2='3'
					col3='2'
					ambiente='%'
				/></>)
				)
			}

		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});


	}

	function ChamarCadastroTipoLocal() {
		sessionStorage.removeItem("dadosTipoLocal");
		setQualGrid(<><FormTipoLocal voltar={BtnVoltarAgentGroup} /></>)
	}

	function BtnVoltarAgentGroup() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridlty_id} Iconeadd={ChamarCadastroTipoLocal} Iconeditar={EditarTipoLocal} iconeExcluir={TipoLocalDeletar}
			th1='Ativo'
			th2='Descrição'
			th3='Identificador Alternativo'
			th4='Subcategoria'
			th5='Data da Inclusão'
			th6='Data Atualização'
			endPoint='localtype/listar'
			consult='N'
			col1='1'
			col2='3'
			col3='2'
			ambiente='%'
		/></>))

		GetLocaltype()
	}

	async function EditarTipoLocal(obj: any) {

		sessionStorage.setItem("dadosTipoLocal", JSON.stringify(obj));
		setQualGrid(<><FormTipoLocal voltar={BtnVoltarAgentGroup} /></>)
	}

	function TipoLocalDeletar(obj: any) {

		setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarTipoLocal(obj.lty_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)

	}

	async function deletarTipoLocal(id: any) {

		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;

		setOpenModal(false)

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			axios({
				method: "delete",
				url: endpoint + '/localtype/delete',
				headers: {
					Authorization: _user
				},
				data: {
					id: id
				},
			}).then(async function (response) {

				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridlty_id} Iconeadd={ChamarCadastroTipoLocal} Iconeditar={EditarTipoLocal} iconeExcluir={TipoLocalDeletar}
					th1='Ativo'
					th2='Descrição'
					th3='Identificador Alternativo'
					th4='Subcategoria'
					th5='Data da Inclusão'
					th6='Data Atualização'
					endPoint='localtype/listar'
					consult='N'
					col1='1'
					col2='3'
					col3='2'
					ambiente='%'
				/></>))
				GetLocaltype()


			})
				.catch(function (error) {

					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridlty_id} Iconeadd={ChamarCadastroTipoLocal} Iconeditar={EditarTipoLocal} iconeExcluir={TipoLocalDeletar}
						th1='Ativo'
						th2='Descrição'
						th3='Identificador Alternativo'
						th4='Subcategoria'
						th5='Data da Inclusão'
						th6='Data Atualização'
						endPoint='localtype/listar'
						consult='N'
						col1='1'
						col2='3'
						col3='2'
						ambiente='%'
					/></>))
					GetLocaltype()
				});

		}).catch(function (error) {


			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
				<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
			</div>)

			setOpenModal(true)


		});
	}

	//-------------------------------------------- GRUPO DE LOCAIS

	function ChamarModalGrupoLocais() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''


		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			setOpenModalIncluirNovo(true)
			sessionStorage.removeItem("dadosTipoLocal");
			setQualTitulo(<><h1 className='modal-tit'>Cadastrar Grupo de Local</h1></>)
			setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridllgp_id} Iconeadd={ChamarCadastroGrupoLocais} Iconeditar={EditarGrupoLocais} iconeExcluir={GrupoLocaisDeletar}
				th1='Ativo'
				th2='Descrição'
				th3='Identificador Alternativo'
				th4='Subcategoria'
				th5='Data da Inclusão'
				th6='Data Atualização'
				endPoint='localgroup/listar'
				consult='N'
				col1='1'
				col2='2'
				col3='3'
				ambiente='%'
			/></>)
			)



		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});

	}

	function ChamarCadastroGrupoLocais() {
		sessionStorage.removeItem("dadosGrupoLocal");
		setQualGrid(<><FormGrupoLocal voltar={BtnVoltarGrupoLocais} /></>)
	}

	function BtnVoltarGrupoLocais() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridllgp_id} Iconeadd={ChamarCadastroGrupoLocais} Iconeditar={EditarGrupoLocais} iconeExcluir={GrupoLocaisDeletar}
			th1='Ativo'
			th2='Descrição'
			th3='Identificador Alternativo'
			th4='Subcategoria'
			th5='Data da Inclusão'
			th6='Data Atualização'
			endPoint='localgroup/listar'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente='%'
		/></>))

		GetlocalGroup()
	}

	async function EditarGrupoLocais(obj: any) {
		sessionStorage.setItem("dadosGrupoLocal", JSON.stringify(obj));
		setQualGrid(<><FormGrupoLocal voltar={BtnVoltarGrupoLocais} /></>)
	}

	function GrupoLocaisDeletar(obj: any) {

		setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarGrupoLocais(obj.lgp_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)


	}

	async function deletarGrupoLocais(id: any) {

		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;

		setOpenModal(false)

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			axios({
				method: "delete",
				url: endpoint + '/localgroup/delete',
				headers: {
					Authorization: _user
				},
				data: {
					id: id
				},
			}).then(async function (response) {

				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridllgp_id} Iconeadd={ChamarCadastroGrupoLocais} Iconeditar={EditarGrupoLocais} iconeExcluir={GrupoLocaisDeletar}
					th1='Ativo'
					th2='Descrição'
					th3='Identificador Alternativo'
					th4='Subcategoria'
					th5='Data da Inclusão'
					th6='Data Atualização'
					endPoint='localgroup/listar'
					consult='N'
					col1='1'
					col2='2'
					col3='3'
					ambiente='%'
				/></>))
				GetlocalGroup()


			})
				.catch(function (error) {

					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridllgp_id} Iconeadd={ChamarCadastroGrupoLocais} Iconeditar={EditarGrupoLocais} iconeExcluir={GrupoLocaisDeletar}
						th1='Ativo'
						th2='Descrição'
						th3='Identificador Alternativo'
						th4='Subcategoria'
						th5='Data da Inclusão'
						th6='Data Atualização'
						endPoint='localgroup/listar'
						consult='N'
						col1='1'
						col2='2'
						col3='3'
						ambiente='%'
					/></>))
					GetlocalGroup()
				});

		}).catch(function (error) {


			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
				<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
			</div>)

			setOpenModal(true)


		});
	}

	//--------------------------------------------- 

	function cancelar() {
		navigate('/locais');
	}

	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};

	async function validatTokenConf() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {

			confirmarLocais()


		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});
	}


	function confirmarLocais() {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)


		const Filtrardescription = optionsLocal.filter(
			(e: any) => {
				return e.loc_description.toLowerCase() === description.toLowerCase()
			}
		);

		const FiltrarAlternativeidentifier = optionsLocal.filter(
			(e: any) => {
				return e.loc_alternativeidentifier.toLowerCase() === alternativeidentifier.toLowerCase()
			}
		);

	
		if (Filtrardescription.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)


		} else
			if (FiltrarAlternativeidentifier.length > 0) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
					<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
				</div>)

			} else
				if (description === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				} else
					if (corporatename === "") {

						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Digite o pavimento.</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)

					} else
						if (alternativeidentifier === "") {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						} else if (lgp_id === "" || lgp_id === null) {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Selecione o grupo de locais.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)


						}
						else {
							salvarLocal()
						}
	}


	async function salvarLocal() {

		let ret: AxiosResponse;

		const dados = [{
			loc_active: active,
			loc_description: description,
			loc_corporatename: corporatename,
			loc_alternativeidentifier: alternativeidentifier,
			loc_observation: observation,
			loc_country: country,
			loc_state: state,
			loc_city: city,
			loc_neighborhood: neighborhood,
			loc_street: street,
			loc_streetnumber: streetnumber,
			loc_streetnumbercomplement: streetnumbercomplement,
			loc_zipcode: zipcode,
			loc_ddi_phone: ddi_phone,
			loc_ddd_phone: ddd_phone,
			loc_phone: phone,
			loc_ddi_mobilephone: ddi_mobilephone,
			loc_ddd_mobilephone: ddd_mobilephone,
			loc_mobilephone: mobilephone,
			loc_mail: mail,
			loc_geoposition: geoposition,
			lty_id: lty_id || null,
			lgp_id: lgp_id || null,
			age_id: age_id || null,
			tea_id: tea_id || null,
			lcy_id: lcy_id || null,
			age_id_insert: '1',//inserir usuario
			age_id_lastupdate: undefined,
		}]

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''


		axios({
			method: "post",
			url: endpoint + '/locais/insert',
			headers: {
				Authorization: _user
			},
			data: {
				data: dados,
			}
		}).then(async function (ret) {

			if (ret.data.status === 200) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-check ControleIconAlert"></span>
					<div className='div-info-alert'><span className="info-alert">Local criado com sucesso.</span></div>
					<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
				</div>)


			}
		})
			.catch(function (error) {
				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Não foi possível criar o local.</span></div>
					<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
				</div>)
			});
	}

	function finalizarModal1() {
		navigate('/locais');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	function PegarValorGridlty_id(valor: any) {

		setLty_id(String(valor.lty_id))
		setOpenModalIncluirNovo(false)

	}
	
	
	function PegarValorGridllgp_id(valor: any) {

		setlgp_id(String(valor.lgp_id))
		setOpenModalIncluirNovo(false)

	}
	

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod1">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										type="text"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
										autoComplete="off"
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Pavimento *</span>
									<input
										type="text"
										onChange={(e) => setCorporatename(e.target.value)}
										value={corporatename}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Identificador Alternativo *</span>
									<input
										type="text"
										onChange={(e) => setAlternativeidentifier(e.target.value)}
										value={alternativeidentifier}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>


						<div className="container-cad-prod6">

							<div id="content1">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Responsável</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsResponsavel}
										styles={customStyles}
										value={optionsResponsavel.filter(function (option: any) {
											return option.value === age_id;
										})}
										onChange={(options: any) =>
											!options ? setAge_id('') : setAge_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>

							<div id="content2">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Equipe</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsEquipe}
										styles={customStyles}
										value={optionsEquipe.filter(function (option: any) {
											return option.value === tea_id;
										})}
										onChange={(options: any) =>
											!options ? setTea_id('') : setTea_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>

							<div id="content3">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Tipo de Local</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' />
										<span className="details-esc-ativo" onClick={() => ChamarModalTipoLocal('Função')}>Incluir Novo</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsTipo}
										styles={customStyles}
										value={optionsTipo.filter(function (option: any) {
											return option.value === lty_id;
										})}
										onChange={(options: any) =>
											!options ? setLty_id('') : setLty_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>

							<div id="content4">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Grupo de Local *</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' />
										<span className="details-esc-ativo" onClick={() => ChamarModalGrupoLocais()}>Incluir Novo</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsGrupo}
										styles={customStyles}
										value={optionsGrupo.filter(function (option: any) {
											return option.value === lgp_id;
										})}
										onChange={(options: any) =>
											!options ? setlgp_id('') : setlgp_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>

						</div>

						<div className="container-cad-prod10">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">
										E-mail</span>
									<input
										type="text"
										onChange={(e) => setMail(e.target.value)}
										value={mail}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setDdi_mobilephone(maskOnlyNumbers(e.target.value))}
										value={ddi_mobilephone}
										autoComplete="off"
									/>
								</div>
							</div>


							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setDdd_mobilephone(maskOnlyNumbers(e.target.value))}
										value={ddd_mobilephone}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Celular</span>
									<input
										maxLength={18}
										type="text"
										onChange={(e) => setMobilephone(maskOnlyNumbers(e.target.value))}
										value={mobilephone}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setDdi_phone(maskOnlyNumbers(e.target.value))}
										value={ddi_phone}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content6">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setDdd_phone(maskOnlyNumbers(e.target.value))}
										value={ddd_phone}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content7">
								<div className="input-box-ativos">
									<span className="details">Telefone</span>
									<input
										maxLength={18}
										type="text"
										onChange={(e) => setPhone(maskOnlyNumbers(e.target.value))}
										value={phone}
										autoComplete="off"
									/>
								</div>
							</div>

						</div>

						<div className="container-cad-prod14">
							<div id="content1">
								<div className="select-box-ativos-select">
									<span className="details">Criticidade</span>
									<Select
										placeholder={'Selecione...'}
										options={optionsCriticidade}
										styles={customStyles}
										value={optionsCriticidade.filter(function (option: any) {
											return option.value === lcy_id;
										})}
										onChange={(options: any) =>
											!options ? setLcy_id('') : setLcy_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Data da Inclusão</span>
									<input
										type="datetime-local"
										value={datetimeinsert}
										disabled
									/>
								</div>
							</div>

							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Data da Última Atualização</span>
									<input
										type="datetime-local"
										value={datetimeupdate}
										disabled
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod3">
							<div className="textareaBox-ativo">
								<span className="details">Observação</span>
								<textarea
									onChange={(e) => setObservation(e.target.value)}
									value={observation}
								/>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Endereço</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod15">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Coordenada GPS</span>
									<input
										type="text"
										onChange={(e) => setGeoposition(e.target.value)}
										value={geoposition}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">País</span>
									<input
										type="text"
										onChange={(e) => setCountry(e.target.value)}
										value={country}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Estado</span>
									<input
										type="text"
										onChange={(e) => setState(e.target.value)}
										value={state}
										autoComplete="off" />
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Cidade</span>
									<input
										type="text"
										onChange={(e) => setCity(e.target.value)}
										value={city}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">Bairro</span>
									<input
										type="text"
										onChange={(e) => setNeighborhood(e.target.value)}
										value={neighborhood}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod12">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Logradouro</span>
									<input
										type="text"
										onChange={(e) => setStreet(e.target.value)}
										value={street}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Número</span>
									<input
										type="text"
										onChange={(e) => setStreetnumber(e.target.value)}
										value={streetnumber}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Complemento</span>
									<input
										type="text"
										onChange={(e) => setStreetnumbercomplement(e.target.value)}
										value={streetnumbercomplement}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">CEP</span>
									<input
										type="text"
										onChange={(e) => setZipcode(e.target.value)}
										value={zipcode}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>


						<div className="Bt-button-cad">
							<button type="submit" onClick={validatTokenConf}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>
			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>


		</>
	);

}

export default LocaisAdd;