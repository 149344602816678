import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import api from '../../Services/api'
import Swal from 'sweetalert2';

function FormLogExecucao(props: any) {
   
    return (
        <>
            <div className="container-modal-pric">
                
                <div className='cont-log'>
                    <h1 className='cont-label'>{props.label}</h1>
                </div>    

            </div>
        </>
    );

}

export default FormLogExecucao;