import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import api from "../../Services/api";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from 'react-select'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    minHeight: '30px',
    height: '30px',
    borderColor: state.isFocused ? 'grey' : '#92989e',

  }),


  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    padding: '0 6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
    fontSize: 14,

  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),
};


export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 10,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clicked: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      optionsLocal: [],
      optionsUser: [],
      optionsTeam: [],
      optionsSituacao: [],
      optionsTasktype: [],
      tsk_active: '',
      loc_id: '',
      age_id: '',
      tea_id: '',
      stn_id: '',
      tty_id: '',
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }



  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };




  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  do_search = () => {

    this.setState({
      dataT: this.state.orgtableData,
    });
    //------------------------------BUSCAR
    if (this.state.search === '' || this.state.search === undefined || this.state.search === null) {

    } else {


      const temp_rows = this.state.orgtableData.filter(
        (e) => {
          return (
            JSON.stringify(e)
              .toLowerCase()
              .indexOf(this.state.search.toLowerCase()) >= 0
          );
        }
      );

      var slice = temp_rows.slice(
        this.state.offset,
        this.state.offset + this.state.perPage
      );

      this.setState({
        pageCount: Math.ceil(temp_rows.length / this.state.perPage),
        orgtableData: this.state.orgtableData,
        tableData: slice,
        dataT: temp_rows,
        length: temp_rows.length,
        pagAtual: 1,
      });

      this.handlePageClick_clear()
    }


  };

  clear_search = () => {
    //------------------------------LIMPAR
    this.setState({ search: '' });

    var slice = this.state.orgtableData.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    this.setState({
      pageCount: Math.ceil(this.state.orgtableData.length / this.state.perPage),
      orgtableData: this.state.orgtableData,
      tableData: slice,
      dataT: this.state.orgtableData,
      length: this.state.orgtableData.length,
      pagAtual: 0,
      forcePage: 0,
    });

    this.handlePageClick_clear();

  };

  componentDidMount() {

    this.getData();
    this.clear_search();

    if (this.props.pag === "tarefas") {

      this.setState({ tsk_active: '1' });
      this.GetLocais();
      this.GetUser();
      this.GetTeam();
      this.GetSituacao();
      this.GetTasktype();
    }

  }


  GetTasktype() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    axios({
      method: "get",
      url: endpoint + '/tasktype/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const equipe = valor.filter(
          (e) => {
              return e.tty_active === 1
          }
      );

        const arr = [];
        
        let result = equipe;
        result.map((user) => {
          return arr.push({ value: String(user.tty_id), label: user.tty_description });
        });

        this.setState({
          optionsTasktype: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsTasktype: []
        });

      });
  }



  GetSituacao() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    axios({
      method: "get",
      url: endpoint + '/tasksituation/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const equipe = valor.filter(
          (e) => {
              return e.stn_active === 1
          }
      );

        const arr = [];
        
        let result = equipe;
        result.map((user) => {
          return arr.push({ value: String(user.stn_id), label: user.stn_description });
        });

        this.setState({
          optionsSituacao: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsSituacao: []
        });

      });
  }

  GetTeam() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    axios({
      method: "get",
      url: endpoint + '/team/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const equipe = valor.filter(
          (e) => {
              return e.tea_active === 1
          }
      );

        const arr = [];
        
        let result = equipe;
        result.map((user) => {
          return arr.push({ value: String(user.tea_id), label: user.tea_description });
        });

        this.setState({
          optionsTeam: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsTeam: []
        });

      });
  }

  GetUser() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    axios({
      method: "get",
      url: endpoint + '/user/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const user = valor.filter(
          (e) => {
              return e.age_active === 1
          }
      );

        const arr = [];
        
        let result = user;
        result.map((user) => {
          return arr.push({ value: String(user.age_id), label: user.age_name });
        });

        this.setState({
          optionsUser: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsUser: []
        });

      });
  }

  GetLocais() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    axios({
      method: "get",
      url: endpoint + '/locais/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const locais = valor.filter(
          (e) => {
              return e.loc_active === 1
          }
      );

        const arr = [];

        let result = locais;
        result.map((user) => {
          return arr.push({ value: String(user.loc_id), label: user.loc_description });
        });

        this.setState({
          optionsLocal: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsLocal: []
        });

      });
  }

  getData() {
    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    let dados = [];

    if (this.props.pag === "tarefas") {
      dados.push({
        tsk_active: '1',
        loc_id: this.state.loc_id,
        age_id: this.state.age_id,
        tea_id: this.state.tea_id,
        stn_id: this.state.stn_id,
        tty_id: this.state.tty_id,
        search: this.state.search
      })
    }

    axios({
      method: "get",
      url: endpoint + '/' + this.props.endPoint,
      params: {
        data: dados
      },
    }).then((res) => {

      var data = res.data.data;

      if (res.data.status === 200) {
        var slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: res.data.data,
          tableData: slice,
          dataT: res.data.data,
          length: data.length,
        });

      }

    })
      .catch(function (res) {

      });

  }


  toggle = (index) => {
    if (this.state.clicked === index) {
      //if clicked question is already active, then close it
      return this.setState({ clicked: null });
    }
    this.setState({ clicked: index });
  };

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });
      console.log(this.state.isChecked);

    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });
      console.log(this.state.isChecked);

    }

  }

  chamarCust = (valor) => {
    this.props.funtoggle(valor, this.props.form);

  }

  exibirFiltro = () => {

    console.log('oi',)
    if (this.state.clicked === true) {
      this.setState({
        clicked: false
      });
    } else {
      this.setState({
        clicked: true
      });
    }
  }


  handleInputChangeAtivoActive = (event) => {
    this.setState({ tsk_active: event.target.value });
  };

  render() {
    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">
          <div className="InputBusca">
            <input
              placeholder="Pesquisar..."
              onChange={this.handleInputChange}
              value={this.state.search}
            />

            <div className="icone-buscaT" onClick={this.do_search}>
              <FcSearch className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.clear_search}>
              <i className="fa-solid fa-eraser icone-add"></i>
            </div>

            <div className="icone-buscaT" onClick={this.exibirFiltro}>
              <FcFilledFilter className="icone-add" />
            </div>

          </div>
          <Link to={this.props.fromAdd} className="icone-buscaAdd" >
            <FcPlus className="icone-add" />
          </Link>
        </div>

        <div className="ContainerDropdown">
          {this.state.clicked === true ? (
            <div className="container-Dropdown-busca">


              <div className="container-cad-prod25">
                <div id="content1">
                  <div className="input-box-ativos">
                    <span className="details">Ativo</span>
                    <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivoActive} value={this.state.tsk_active}>
                      <option value="">Todos</option>
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                  </div>
                </div>

                <div id="content2">
                  <div className="select-box-ativos-select">
                    <span className="details">Onde</span>
                    <Select
                      placeholder={'Selecione...'}
                      options={this.state.optionsLocal}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ loc_id: '' }) : this.setState({ loc_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>

                <div id="content3">
                  <div className="select-box-ativos-select">
                    <span className="details">Agente</span>
                    <Select
                      placeholder={'Selecione...'}
                      options={this.state.optionsUser}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ age_id: '' }) : this.setState({ age_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>
                <div id="content4">
                  <div className="select-box-ativos-select">
                    <span className="details">Equipe</span>
                    <Select
                      placeholder={'Selecione...'}
                      options={this.state.optionsTeam}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ tea_id: '' }) : this.setState({ tea_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>

              </div>

              <div className="container-cad-prod6">
               

                <div id="content1">
                  <div className="select-box-ativos-select">
                    <span className="details">Situação</span>
                    <Select
                      placeholder={'Selecione...'}
                      options={this.state.optionsSituacao}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ stn_id: '' }) : this.setState({ stn_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>

                <div id="content2">
                <div className="select-box-ativos-select">
                    <span className="details">Tipo de tarefa</span>
                    <Select
                      placeholder={'Selecione...'}
                      options={this.state.optionsTasktype}
                      styles={customStyles}
                      onChange={(options) =>
                        !options ? this.setState({ tty_id: '' }) : this.setState({ tty_id: options.value })
                      }
                      isClearable
                      noOptionsMessage={() => "Não encontrado..."}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 4,
                        colors: {
                          ...theme.colors,
                          primary25: '#ebebeb',
                          primary: 'black',
                        },
                      })}
                    />
                  </div>
                </div>

              </div>




























            </div>
          ) : null}
        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  <th className="Title">{this.props.th3}</th>
                  <th className="Title">{this.props.th4}</th>
                  <th className="Title">{this.props.th5}</th>
                  <th className="Title">{this.props.th6}</th>
                  <th className="Title">{this.props.th8}</th>
                  <th className="Title">{this.props.th7}</th>

                  <th className="Title">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[0]}>
                    <td className="ColunaCheck-user" align="center"><input type='checkbox' checked={Object.values(tdata)[this.props.col1]} onChange={() => { }} /></td>
                    <td className="Coluna-id">{Object.values(tdata)[this.props.col2]}</td>
                    <td className="Coluna-descricao">{Object.values(tdata)[this.props.col3]}</td>
                    <td className="Coluna-quem">{Object.values(tdata)[this.props.col4]}</td>



                    {Object.values(tdata)[this.props.col6] === null ? (
                      <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col5]?.substring(8, 10)}/{Object.values(tdata)[this.props.col5]?.substring(5, 7)}/{Object.values(tdata)[this.props.col5]?.substring(0, 4)} {Object.values(tdata)[this.props.col5]?.substring(11, 16)}</td>
                    ) : <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col5]?.substring(8, 10)}/{Object.values(tdata)[this.props.col5]?.substring(5, 7)}/{Object.values(tdata)[this.props.col5]?.substring(0, 4)} {Object.values(tdata)[this.props.col5]?.substring(11, 16)} - {Object.values(tdata)[this.props.col6]?.substring(11, 16)}</td>}

                    {Object.values(tdata)[this.props.col9] !== null ? (
                      Object.values(tdata)[this.props.col10] === null ? (
                        <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col9]?.substring(8, 10)}/{Object.values(tdata)[this.props.col9]?.substring(5, 7)}/{Object.values(tdata)[this.props.col9]?.substring(0, 4)} {Object.values(tdata)[this.props.col9]?.substring(11, 16)}</td>
                      ) : <td className="Coluna-data-previsto">{Object.values(tdata)[this.props.col9]?.substring(8, 10)}/{Object.values(tdata)[this.props.col9]?.substring(5, 7)}/{Object.values(tdata)[this.props.col9]?.substring(0, 4)} {Object.values(tdata)[this.props.col9]?.substring(11, 16)} - {Object.values(tdata)[this.props.col10]?.substring(11, 16)}</td>
                    ) : <td className="Coluna-data-previsto"></td>}



                    <td className="Coluna-tipo">{Object.values(tdata)[this.props.col8]}</td>

                    <td className="Coluna-situacao">{Object.values(tdata)[this.props.col7]}</td>


                    <td className="Coluna-icone-btn">
                      <span onClick={() => this.props.iconEditar(Object.values(tdata)[0], Object.values(tdata)[9])}>
                        <i className="fa fa-pen icone-acao1"></i>
                      </span>

                      <span onClick={() => this.props.iconVisualizar(Object.values(tdata)[0], Object.values(tdata)[6])}>
                        <i className="fa fa-search icone-acao3"></i>
                      </span>


                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}

            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Child;
