import { IUser } from "./types";
import  secureLocalStorage  from  "react-secure-storage";

export function setUserLocalStorage(user: IUser | null){
    secureLocalStorage.setItem('u', JSON.stringify(user));
}

export function getUserLocalStorage(){

    const json =  secureLocalStorage.getItem('u')

    if(!json){
        return null;

    }
    
    const user = json

    return user ?? null;
}