import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import api from "../../Services/api";
import { AxiosResponse } from "axios";
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';

function Usuarios() {

    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [renderFuncao, setRenderFuncao] = useState(<></>)

	const [optionsAgentGroup, setOptionsAgentGroup] = useState([])

    useEffect(() => {

        validatToken();

    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''


        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            setExibirGrid(<GridPrincipal
                iconeExcluir={ModalDeletar}
                th1='Ativo'
                th2='ID'
                th3='Nome'
                th4='Login'
                th5='Função'
                th6='Data da Inclusão'
                endPoint='user/buscaruser'
                col1='0'
                col2='1'
                col3='2' //5
                col4='5'
                col5='21'
                col6='22'
                Delete='N'
                fromAdd='/user/add'
                fromEdit='/user/edit'
                ambiente='ADLIMNOW'
                pag="user"
            />)



        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }



    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }

    function ModalDeletar(obj: any) {


    }



    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='div-titulo-pages'>
                            <span className='fa fa-user' />
                            <h1 className='div-titulo-pages-text'>Usuários</h1>
                        </div>
                        {exibirGrid}
                    </div>
                </main>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Usuarios;