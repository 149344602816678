import React, { PureComponent, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./index.css";
import { useNavigate } from "react-router";
import { FcSearch, FcPlus, FcFilledFilter } from "react-icons/fc";
import { AxiosResponse } from "axios";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Select from 'react-select'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    minHeight: '30px',
    height: '30px',
    borderColor: state.isFocused ? 'grey' : '#92989e',

  }),


  valueContainer: (provided, state) => ({
    ...provided,
    height: '30px',
    padding: '0 6px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
    fontSize: 14,

  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '30px',
  }),
};

export class Child extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      tableData: [],
      orgtableData: [],
      perPage: 11,
      currentPage: 0,
      pagAtual: 1,
      length: 0,
      endpoint: this.props.endPoint,
      clickeduser: false,
      clickedteam: false,
      atualiza: 0,
      search: '',
      isChecked: [],
      consult: this.props.consult,
      forcePage: 0,
      optionsAgentgroup: [],
      agg_id: '',
      age_integrationid: '',
      age_name: '',
      age_active: '',
      tea_active: '',
      tea_integrationid: '',
      tea_description: '',
      tet_id: '',
      optionsTeamType: [],
      clickedlocais: false,
      clickedatividades: false,
      loc_active: '',
      loc_description: '',
      loc_corporatename: '',
      lgp_id: '',
      loc_alternativeidentifier: '',
      lty_id: '',
      loc_state: '',
      loc_city: '',
      optionsGrupo: [],
      optionsTipo: [],
      aty_id: '',
      act_integrationid: '',
      act_description: '',
      act_active: '',
      optionsAtivitytype: [],
      clickedtarefas: false,
      loc_active: '',
      loc_description: '',
      age_id: '',
      stn_id: '',
      tty_id: '',
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }





  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  loadMoreData() {

    const data = this.state.dataT;

    const slice = data.slice(
      this.state.offset,
      this.state.offset + this.state.perPage,

    );


    //////////////////////aqui
    this.setState({
      pageCount: Math.ceil(data.length / this.state.perPage),
      tableData: slice,
      length: data.length,
    });

  }


  handlePageClick_clear = () => {
    const selectedPage = 0;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
        pagAtual: selectedPage + 1,
        forcePage: selectedPage,
      },
      () => {
        this.loadMoreData();
      }
    );


  };

  handleInputChange = (event) => {
    this.setState({ search: event.target.value });
  };

  handleInputChangeAtivo = (event) => {
    this.setState({ age_active: event.target.value });
  };

  handleInputChangeAtivoTeamType = (event) => {
    this.setState({ tea_active: event.target.value });
  };

  handleInputChangeAtivoLocais = (event) => {
    this.setState({ loc_active: event.target.value });
  };

  handleInputChangeAtivoActive = (event) => {
    this.setState({ act_active: event.target.value });
  };

  do_search = () => {

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    let dados = [];

    if (this.props.pag === "user") {
      dados.push({
        agg_id: this.state.agg_id,
        age_integrationid: this.state.age_integrationid,
        age_name: this.state.age_name,
        age_active: this.state.age_active,
        search: this.state.search
      })
    }

    if (this.props.pag === "team") {
      dados.push({
        tet_id: this.state.tet_id,
        tea_integrationid: this.state.tea_integrationid,
        tea_description: this.state.tea_description,
        tea_active: this.state.tea_active,
        search: this.state.search
      })
    }
    
    if (this.props.pag === "locais") {

      dados.push({
        loc_active: this.state.loc_active,
        loc_description: this.state.loc_description,
        loc_corporatename: this.state.loc_corporatename,
        lgp_id: this.state.lgp_id,
        loc_alternativeidentifier: this.state.loc_alternativeidentifier,
        lty_id: this.state.lty_id,
        loc_state: this.state.loc_state,
        loc_city: this.state.loc_city,
        search: this.state.search
      })

    }

    if (this.props.pag === "atividades") {
      dados.push({
        aty_id: this.state.aty_id,
        act_integrationid: this.state.act_integrationid,
        act_description: this.state.act_description,
        act_active: this.state.act_active,
        search: this.state.search
      })
    }

    axios({
      method: "get",
      url: endpoint + '/' + this.props.endPoint,
      params: {
        data: dados
      },
    }).then((res) => {

      var data = res.data.data;

      if (res.data.status === 200) {

        var slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: res.data.data,
          tableData: slice,
          dataT: res.data.data,
          length: data.length,
        });

      }

    })
      .catch(function (res) {

      });



  };

  clear_search = () => {

    window.location.reload();

  };


  componentDidMount() {

    this.getData();

    if (this.props.pag === "user") {

      this.setState({ age_active: '1' });
      this.GetAgentGroup()

    }


    if (this.props.pag === "team") {

      this.setState({ tea_active: '1' });
      this.GetTeamType()

    }

    if(this.props.pag === "locais"){
      this.setState({ loc_active: '1' });
      this.GetlocalGroup();
      this.GetLocaltyp();
    }

    if (this.props.pag === "atividades") {
      this.setState({ act_active: '1' });
      this.GetAtivitytype();
    }

  }


  GetAtivitytype() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')


    axios({
      method: "get",
      url: endpoint + '/activitytype/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
				let result = valor;
				result.map((user) => {
					return arr.push({ value: String(user.aty_id), label: user.aty_description });
				});

        this.setState({
          optionsAtivitytype: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsAtivitytype: []
        });

      });

  }

  GetLocaltyp() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')


    axios({
      method: "get",
      url: endpoint + '/localtype/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
				let result = valor;
				result.map((user) => {
					return arr.push({ value: String(user.lty_id), label: user.lty_description });
				});

        this.setState({
          optionsTipo: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsTipo: []
        });

      });

  }


  GetlocalGroup() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')


    axios({
      method: "get",
      url: endpoint + '/localgroup/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
				let result = valor;
				result.map((user) => {
					return arr.push({ value: String(user.lgp_id), label: user.lgp_description });
				});

        this.setState({
          optionsGrupo: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsGrupo: []
        });

      });

  }

  GetTeamType() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')


    axios({
      method: "get",
      url: endpoint + '/teamtype/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.tet_id), label: user.tet_description });
        });

        this.setState({
          optionsTeamType: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsTeamType: []
        });

      });

  }

  GetAgentGroup() {

    let valor

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')


    axios({
      method: "get",
      url: endpoint + '/agentgroup/listar'
    }).then((res) => {
      var data = res.data.data;
      if (res.data.status === 200) {

        valor = res.data.data;

        const arr = [];
        let result = valor;
        result.map((user) => {
          return arr.push({ value: String(user.agg_id), label: user.agg_description });
        });

        this.setState({
          optionsAgentgroup: arr
        });

      }

    })
      .catch(function (res) {

        this.setState({
          optionsAgentgroup: []
        });

      });



  }

  getData() {

    const _user = secureLocalStorage.getItem('t')
    const endpoint = secureLocalStorage.getItem('ep')

    let dados = [];

    if (this.props.pag === "user") {
      dados.push({
        agg_id: this.state.agg_id,
        age_integrationid: this.state.age_integrationid,
        age_name: this.state.age_name,
        age_active: '1',
        search: this.state.search
      })
    }

    if (this.props.pag === "team") {
      dados.push({
        tet_id: this.state.tet_id,
        tea_integrationid: this.state.tea_integrationid,
        tea_description: this.state.tea_description,
        tea_active: '1',
        search: this.state.search
      })
    }

    if (this.props.pag === "locais") {
      dados.push({
        loc_active: '1',
        loc_description: this.state.loc_description,
        loc_corporatename: this.state.loc_corporatename,
        lgp_id: this.state.lgp_id,
        loc_alternativeidentifier: this.state.loc_alternativeidentifier,
        lty_id: this.state.lty_id,
        loc_state: this.state.loc_state,
        loc_city: this.state.loc_city,
        search: this.state.search,
      })
    }

    if (this.props.pag === "atividades") {
      dados.push({
        search: this.state.search,
        aty_id: this.state.aty_id,
        act_integrationid: this.state.act_integrationid,
        act_description: this.state.act_description,
        act_active: '1',
      })
    }

    axios({
      method: "get",
      url: endpoint + '/' + this.props.endPoint,
      params: {
        data: dados
      },
    }).then((res) => {

      console.log('-------', res.data.data)

      var data = res.data.data;

      if (res.data.status === 200) {
        var slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          orgtableData: res.data.data,
          tableData: slice,
          dataT: res.data.data,
          length: data.length,
        });

      }

    })
      .catch(function (res) {

      });


  }

  handlecheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {

      this.setState({
        isChecked: ([...this.state.isChecked, value])
      });


    } else {


      this.setState({
        isChecked: (this.state.isChecked.filter((e) => e !== value))
      });


    }

  }

  chamarCust = (valor) => {
    this.props.funtoggle(valor, this.props.form);

  }

  exibirFiltro = () => {


    if (this.props.pag === "user") {
      if (this.state.clickeduser === true) {
        this.setState({
          clickeduser: false
        });
      } else {
        this.setState({
          clickeduser: true
        });
      }
    } else if (this.props.pag === "team") {
      if (this.state.clickedteam === true) {
        this.setState({
          clickedteam: false
        });
      } else {
        this.setState({
          clickedteam: true
        });
      }
    }else if (this.props.pag === "locais") {
      if (this.state.clickedlocais === true) {
        this.setState({
          clickedlocais: false
        });
      } else {
        this.setState({
          clickedlocais: true
        });
      }
    }else if (this.props.pag === "atividades") {
      if (this.state.clickedatividades === true) {
        this.setState({
          clickedatividades: false
        });
      } else {
        this.setState({
          clickedatividades: true
        });
      }
    }
    else if (this.props.pag === "tarefas") {
      if (this.state.clickedtarefas === true) {
        this.setState({
          clickedtarefas: false
        });
      } else {
        this.setState({
          clickedtarefas: true
        });
      }
    }
  }

  render() {
    return (
      <div className="ContainerGrid">
        <div className="ContainerBody">

          <div className="InputBusca">
            <input
              placeholder="Pesquisar..."
              onChange={this.handleInputChange}
              value={this.state.search}
            />

            <div className="icone-buscaT" onClick={this.do_search}>
              <FcSearch className="icone-add" />
            </div>

            <div className="icone-buscaT" onClick={this.clear_search}>
              <i
                className="fa-solid fa-eraser icone-add"
              ></i>
            </div>
            <div className="icone-buscaT" onClick={this.exibirFiltro}>
              <FcFilledFilter className="icone-add" />
            </div>
          </div>
          <Link to={this.props.fromAdd} className="icone-buscaAdd" >
            <FcPlus className="icone-add" />
          </Link>
        </div>

        <div className="ContainerDropdown">
          <>
            {this.state.clickeduser === true ? (

              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivo} value={this.state.age_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">ID para Integração</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_integrationid: e.target.value })}
                        value={this.state.age_integrationid}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Nome</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ age_name: e.target.value })}
                        value={this.state.age_name}
                      />
                    </div>
                  </div>

                  <div id="content4">
                    <div className="select-box-ativos-select">
                      <span className="details">Função</span>
                      <Select
                        placeholder={'Selecione...'}
                        options={this.state.optionsAgentgroup}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ agg_id: '' }) : this.setState({ agg_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.clickedteam === true ? (
              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivoTeamType} value={this.state.tea_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ tea_description: e.target.value })}
                        value={this.state.tea_description}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">ID para Integração</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ tea_integrationid: e.target.value })}
                        value={this.state.tea_integrationid}
                      />
                    </div>
                  </div>

                 

                  <div id="content4">
                    <div className="select-box-ativos-select">
                      <span className="details">Equipe responsável</span>
                      <Select
                        placeholder={'Selecione...'}
                        options={this.state.optionsTeamType}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ tet_id: '' }) : this.setState({ tet_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {this.state.clickedlocais === true ? (

              <div className="container-Dropdown-busca">

                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivoLocais} value={this.state.loc_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_description: e.target.value })}
                        value={this.state.loc_description}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Pavimento</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_corporatename: e.target.value })}
                        value={this.state.loc_corporatename}
                      />
                    </div>
                  </div>

                  <div id="content4">
                    <div className="select-box-ativos-select">
                      <span className="details">Grupos de Locais</span>
                      <Select
                        placeholder={'Selecione...'}
                        options={this.state.optionsGrupo}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ lgp_id: '' }) : this.setState({ lgp_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
                
                <div className="container-cad-prod6">
                  <div id="content1">
                  <div className="input-box-ativos">
                      <span className="details">Identificador Alternativo</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_alternativeidentifier: e.target.value })}
                        value={this.state.loc_alternativeidentifier}
                      />
                    </div>
                  </div>

                  <div id="content2">
                  <div className="select-box-ativos-select">
                      <span className="details">Tipo de Local</span>
                      <Select
                        placeholder={'Selecione...'}
                        options={this.state.optionsTipo}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ lty_id: '' }) : this.setState({ lty_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">Estado</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_state: e.target.value })}
                        value={this.state.loc_state}
                      />
                    </div>
                  </div>

                  <div id="content4">
                  <div className="input-box-ativos">
                      <span className="details">Cidade</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ loc_city: e.target.value })}
                        value={this.state.loc_city}
                      />
                    </div>
                  </div>
                   
                </div>

              </div>
            ) : null}

            {this.state.clickedatividades === true ? (
              <div className="container-Dropdown-busca">
                <div className="container-cad-prod6">
                  <div id="content1">
                    <div className="input-box-ativos">
                      <span className="details">Ativo</span>
                      <select id="SELCAT" name="SELCAT" key='CAT0' onChange={this.handleInputChangeAtivoActive} value={this.state.act_active}>
                        <option value="">Todos</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>

                  <div id="content2">
                    <div className="input-box-ativos">
                      <span className="details">Descrição</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ act_description: e.target.value })}
                        value={this.state.act_description}
                      />
                    </div>
                  </div>

                  <div id="content3">
                    <div className="input-box-ativos">
                      <span className="details">ID para Integração</span>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ act_integrationid: e.target.value })}
                        value={this.state.act_integrationid}
                      />
                    </div>
                  </div>

                 

                  <div id="content4">
                    <div className="select-box-ativos-select">
                      <span className="details">Tipo de Atividade</span>
                      <Select
                        placeholder={'Selecione...'}
                        options={this.state.optionsAtivitytype}
                        styles={customStyles}
                        onChange={(options) =>
                          !options ? this.setState({ aty_id: '' }) : this.setState({ aty_id: options.value })
                        }
                        isClearable
                        noOptionsMessage={() => "Não encontrado..."}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 4,
                          colors: {
                            ...theme.colors,
                            primary25: '#ebebeb',
                            primary: 'black',
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

          </>
        </div>

        {this.state.pageCount > 0 ? (
          <div className="grid-tab-user">
            <table>
              <tbody>
                <tr id="grid-header">
                  <th className="Title">{this.props.th1}</th>
                  <th className="Title">{this.props.th2}</th>
                  <th className="Title">{this.props.th3}</th>
                  <th className="Title">{this.props.th4}</th>
                  <th className="Title">{this.props.th5}</th>
                  <th className="Title">{this.props.th6}</th>
                  <th className="Title">Ações</th>
                </tr>
              </tbody>
              <tbody>
                {this.state.tableData.map((tdata, i) => (
                  <tr className="TR-Coluna" key={Object.values(tdata)[this.props.col2]}>
                    <td className="ColunaCheck-user" align="center"><input type='checkbox' checked={Object.values(tdata)[this.props.col1]} onChange={() => { }} /></td>
                    <td className="Coluna-id">{Object.values(tdata)[this.props.col2]}</td>
                    <td className="Coluna-descricao">{Object.values(tdata)[this.props.col3]}</td>
                    <td className="Coluna-padrao">{Object.values(tdata)[this.props.col4]}</td>
                    <td className="Coluna-padrao">{Object.values(tdata)[this.props.col5]}</td>
                    <td className="Coluna-data">{Object.values(tdata)[this.props.col6]?.substring(8, 10)}/{Object.values(tdata)[this.props.col6]?.substring(5, 7)}/{Object.values(tdata)[this.props.col6]?.substring(0, 4)} {Object.values(tdata)[this.props.col6]?.substring(11, 16)}</td>
                    <td className="Coluna-icone-btn">

                      <Link to={this.props.fromEdit + '/' + Object.values(tdata)[this.props.col2]}>
                        <i className="fa fa-pen icone-acao1"></i>
                      </Link>

                      {this.props.Delete === 'N' ? (
                        null
                      ) : <i className="fa fa-trash-alt icone-acao2" onClick={() => { this.props.iconeExcluir(Object(tdata)) }}  ></i>}

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div className="ContainerPag">
          <div className="InlineContainery">

            {this.state.pageCount === null || this.state.pageCount === 0 ? (
              <div className="SemResut">
                <h2>
                  Nenhum resultado encontrado.
                </h2>
                <h2>
                  Página 1 de 1
                </h2>
              </div>
            ) : null}

            <div className="InputBusca">
              {this.state.pageCount > 0 ? (
                <div className="Quant">
                  <h2>
                    Página {this.state.pagAtual} de {this.state.pageCount}
                  </h2>
                </div>
              ) : null}

              {this.state.pageCount > 1 ? (
                <ReactPaginate
                  previousLabel={<i className="fas fa-angle-left"></i>}
                  nextLabel={<i className="fas fa-angle-right"></i>}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  forcePage={this.state.forcePage}
                />
              ) : null}
            </div>
          </div>
          {this.state.pageCount > 0 ? (
            <div className="paginate">
              <h2>Total de registros: {this.state.length}</h2>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Child;
