import styled, {keyframes} from 'styled-components';

interface IContainerProps {
    color: string;
}

const animate = keyframes`
    0%{
        transform: translateX(100px);
        opacity: 0;
    }
    50%{     
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div<IContainerProps>`
    
    width: 100%;
    height: 85px;
    margin: 10px 0;
   // border-top: 3px solid #13293D;
   border-radius: 10px;
    padding: 12px 10px;
    position: relative;
    overflow: hidden;
    animation: ${animate} .5s; 
     -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    background-color: #fff;
    > i {
        font-size: 14px;
    }

    .ali-icon{
        position: absolute;
        width:30px;
        right: 10px;
        padding: 6px;
        top: 10px;
        background-color: ${props => props.color};
        border-radius: 5px;
        text-align: center;
    }

    .link {
      width: max-content;
      font-size: 12px;
      border-bottom: 1px solid gray;
      cursor: pointer;
    }

    .link:hover {
        border-bottom: 1px solid #000;
    }

    > span {
        font-weight: bold;
      font-size: 14px;
      color: rgb(160, 160, 160);
    }

    > small {
        font-size: 12px;
        position: absolute;
        bottom: 10px; 
     
    }

    h1{ 
        margin-left: 10px;
        font-size: 22px;
        font-weight: 300;
        margin-top: 5px;       
    }

   


    @media(max-width: 770px){
        width: 100%;

        > span {
            font-size: 14px;
        }

        > h1 {
            word-wrap: break-word;
            font-size: 22px;

            strong {
                display: inline-block;
                width: 100%;
                font-size: 16px;
            }
        }
    }

    @media(max-width: 420px){
        width: 100%;
       
        > h1 {
            display: flex;
            
            strong {
                position: initial;        
                width: auto;
                font-size: 22px;
            }

            strong:after {
                display: inline-block;
                content: '';
                width: 1px;                
            }
        }
    }
`;