import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import FormTipoEquipe from '../../components/FormTipoEquipe';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import DualListBox from "react-dual-listbox";
import ModalPrincipal from '../../components/ModalAlert/modal'
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useParams } from 'react-router-dom';
import axios from 'axios'; 
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function TeamEdit() {
	let { id } = useParams();
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState(<></>)
	const [qualGrid, setQualGrid] = useState(<></>)

	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)

	const [active, setActive] = useState(true)
	const [integrationid, setIntegrationId] = useState('')
	const [integrationidIni, setIntegrationIdIni] = useState('')
	const [description, setDescription] = useState('')
	const [descriptionIni, setDescriptionIni] = useState('')
	const [teamsupervisor, setTeamsupervisor] = useState('')
	const [tet_id, setTet_id] = useState('')
	const [age_id_insert, setAge_id_insert] = useState('')
	const [age_id_lastupdate, setAge_id_lastupdate] = useState('')

	const [optionsTeamSupervisor, setOptionsTeamSupervisor] = useState([])
	const [optionsTeamType, setOptionsTeamType] = useState([])

	const [userSelecionada, setUserSelecionada] = useState([]);
	const [dadosUser, setDadosUser] = useState([]);

	const [optionsTeam, setOptionsTeam] = useState([])

	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

	useEffect(() => {
		
		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		validatToken();

	}, []);


	async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
       
        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {

			setOpenModal(false)
			setUserSelecionada([]);
			setDadosUser([]);
			usereditar();
			GetTeamSupervisor();
			GetTeamType();
			GetUser();
              
            })
            .catch(function (error) {
      

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
      
      
            });
    }

	function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');
     
    }

	async function GetTeam() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/team/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {

			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsTeam(valor)

			}
		})
			.catch(function (error) {
				setOptionsTeam([])
			});
	}

	async function usereditar() {
		let ret: AxiosResponse;
		let valor: any;



		if (/^\d+$/.test(id || '') === true) {
				const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/team/usereditar',
					headers: {
						Authorization: _user
					},
					  params: {
						id: id
					  }
				}).then(async function (ret) {

				if (ret.data.status === 200) {

					const newarray = ret.data.data.userEquipe.map((element: any) => {
						return String(element.age_id)
					})

					setUserSelecionada(newarray)

					setActive(ret.data.data.listar[0].tea_active != undefined ? Boolean(ret.data.data.listar[0].tea_active) : true)
					setIntegrationId(ret.data.data.listar[0].tea_integrationid || '')
					setIntegrationIdIni(ret.data.data.listar[0].tea_integrationid || '')
					setDescription(ret.data.data.listar[0].tea_description || '')
					setDescriptionIni(ret.data.data.listar[0].tea_description || '')
					if (ret.data.data.listar[0].tea_teamsupervisor !== null) {
						setTeamsupervisor(String(ret.data.data.listar[0].tea_teamsupervisor || ''))
					}
					setTet_id(String(ret.data.data.listar[0].tet_id || ''))

					GetTeam();
					setOpenModal(false)
				} else {
					setOpenModal(false)
					navigate('/team/edit/' + id + '/n');
				}
			})
			.catch(function (error) {
				setOpenModal(false)
				navigate('/team/edit/' + id + '/n');
			});
			
		} else {
			setOpenModal(false)
			navigate('/team/edit/' + id + '/n');
		}
	}

	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	async function GetTeamSupervisor() {
		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/team/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;

				result.map((user: any) => {
					return arr.push({ value: String(user.tea_id), label: user.tea_description });
				});

				setOptionsTeamSupervisor(arr)

			}
		})
			.catch(function (error) {
				setOptionsTeamSupervisor([])
			});
	}

	async function GetTeamType() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/teamtype/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.tet_id), label: user.tet_description });
				});
				setOptionsTeamType(arr)

			}
		})
			.catch(function (error) {
				setOptionsTeamType([])
			});
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};

	function PegarValorGrid(valor: any) {

		setTet_id(String(valor.tet_id))
		setOpenModalIncluirNovo(false)

	}

	function ChamarModalEquipe() {

		let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
       
        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {

			setOpenModalIncluirNovo(true)

		sessionStorage.removeItem("dadosTipoAgente");
		setQualTitulo(<><h1 className='modal-tit'>Cadastrar Tipo de Equipe</h1></>)
		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
			th1='Ativo'
			th2='Descrição'
			th3='Identificador Alternativo'
			th4='Subcategoria'
			th5='Data da Inclusão'
			th6='Data Atualização'
			endPoint='teamtype/listar'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente='%'
		/></>)
		)

	})
	.catch(function (error) {


		setQualAlert(<div className='div-alert'>
		<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
		<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
		<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
		<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
	</div>)

	setOpenModal(true)


	});

	}

	function ChamarCadastroTipoEquipe() {
		sessionStorage.removeItem("dadosTipoEquipe");
		setQualGrid(<><FormTipoEquipe voltar={BtnVoltarTipoEquipe} /></>)
	}

	function BtnVoltarTipoEquipe() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
			th1='Ativo'
			th2='Descrição'
			th3='Identificador Alternativo'
			th4='Subcategoria'
			th5='Data da Inclusão'
			th6='Data Atualização'
			endPoint='teamtype/listar'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente='%'
		/></>))
		GetTeamType()

	}

	async function EditarTipoEquipe(obj: any) {
		sessionStorage.setItem("dadosTipoEquipe", JSON.stringify(obj));
		setQualGrid(<><FormTipoEquipe voltar={BtnVoltarTipoEquipe} /></>)
	}

	function TipoEquipeDeletar(obj: any) {

		setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarEditarTipoEquipe(obj.tet_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)

	
	}

	async function deletarEditarTipoEquipe(id: any) {

		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;

		setOpenModal(false)


		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {


			axios({
				method: "delete",
				url: endpoint + '/teamtype/delete',
				headers: {
					Authorization: _user
				},
				data: {
					id: id
				},
			}).then(async function (ret) {
				if (ret.data.status === 200) {

					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
						th1='Ativo'
						th2='Descrição'
						th3='Identificador Alternativo'
						th4='Subcategoria'
						th5='Data da Inclusão'
						th6='Data Atualização'
						endPoint='teamtype/listar'
						consult='N'
						col1='1'
						col2='2'
						col3='3'
						ambiente='%'
					/></>))
					GetTeamType()

				}
			})
				.catch(function (error) {
					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroTipoEquipe} Iconeditar={EditarTipoEquipe} iconeExcluir={TipoEquipeDeletar}
					th1='Ativo'
					th2='Descrição'
					th3='Identificador Alternativo'
					th4='Subcategoria'
					th5='Data da Inclusão'
					th6='Data Atualização'
					endPoint='teamtype/listar'
					consult='N'
					col1='1'
					col2='2'
					col3='3'
					ambiente='%'
				/></>))
				GetTeamType()
				});

		}).catch(function (error) {


			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
				<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
			</div>)

			setOpenModal(true)


		});

	}

	async function GetUser() {
		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/user/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;

				setDadosUser(valor);

			}
		})
			.catch(function (error) {

			});
	}


	let options = dadosUser.map((element: any) => {
		return { value: String(element.age_id), label: element.age_name }
	});

	function valor(selected: any) {

		setUserSelecionada(selected)

	}
	

	function validarinsertEquipe(){

		let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      
   
			insertEquipe()
              
            })
            .catch(function (error) {
      

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
      
      
            });
			
	}



	async function insertEquipe() {
		let ret: AxiosResponse;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		const FiltrarDescricao = optionsTeam.filter(
			(e: any) => {
				return e.tea_description.toLowerCase()  === description.toLowerCase()
			}
		);

		const FiltrarIntegrationid = optionsTeam.filter(
			(e: any) => {
				return e.tea_integrationid.toLowerCase() === integrationid.toLowerCase()
			}
		);


		if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarDescricao.length > 0) {
		
			
			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

	
	} else

		if (integrationidIni.toLowerCase() !== integrationid.toLowerCase() && FiltrarIntegrationid.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">ID para Integração já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

		
		} else
			if (description === "") {

				setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
			<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
		</div>)

			} else
				if (integrationid === "") {

					setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
			<div className='div-info-alert'><span className="info-alert">Digite o ID para Integração.</span></div>
			<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
		</div>)

				} else if (userSelecionada.length === 0) {
					
					setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Selecione ao menos uma pessoa a equipe.</span></div>
					<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
				</div>)
		

				} else {

					const _user = secureLocalStorage.getItem('t') as ''
					const endpoint = secureLocalStorage.getItem('ep') as ''

					const dados = {
						tea_id: id,
						tea_integrationid: integrationid,
						tea_description: description,
						tea_active: active,
						tea_teamsupervisor: teamsupervisor,
						tet_id: tet_id,
						age_id_lastupdate: 1, //colocar o usuario
					}

					axios({
						method: "put",
						url: endpoint + '/team/update',
						headers: {
							Authorization: _user
						},
						data: {
							data: dados,
							dadosuser: userSelecionada,
						}
					}).then(async function (ret) {

						if (ret.data.status === 200) {

							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
							
								<div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)


						}
					})
					.catch(function (error) {
						setQualAlert(<div className='div-alert'>
							<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
							<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
							<div className='div-info-alert'><span className="info-alert">Não foi possível editar a Equipe</span></div>
							<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
						</div>)
					});
				}
	}

	function finalizarModal1() {
		navigate('/team');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

	function cancelar() {
		navigate('/team');
	}

	
	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}


	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>
						<div className="container-cad-prod6">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Descrição *</span>
									<input
										type="text"
										onChange={(e) => setDescription(e.target.value)}
										value={description}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">ID para Integração *</span>
									<input
										type="text"
										onChange={(e) => setIntegrationId(e.target.value)}
										value={integrationid}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content3">
								<div className="select-box-ativos-select">
									<span className="details">Equipe Responsável</span>
									<Select
										placeholder={'Selecione...'}
										options={optionsTeamSupervisor}
										styles={customStyles}
										value={optionsTeamSupervisor.filter(function (option: any) {
											return option.value === teamsupervisor;
										})}
										onChange={(options: any) =>
											!options ? setTeamsupervisor("") : setTeamsupervisor(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>
							<div id="content4">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Tipo</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' />
										<span className="details-esc-ativo" onClick={() => ChamarModalEquipe()}>Incluir Novo</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsTeamType}
										styles={customStyles}
										value={optionsTeamType.filter(function (option: any) {
											return option.value === tet_id;
										})}
										onChange={(options: any) =>
											!options ? setTet_id("") : setTet_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Pessoas vinculadas à equipe</h2>
							</div>
							<hr />
						</div>

						<div className='DualListBox'>
							<DualListBox
								options={options}
								selected={userSelecionada}
								onChange={valor}
								icons={{
									moveLeft: "<",
									moveAllLeft: "<<",
									moveRight: ">",
									moveAllRight: ">>"
								}}
								preserveSelectOrder={true}
							/>
						</div>

						<div className="Bt-button-cad">
							<button type="submit" onClick={validarinsertEquipe}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>

			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>


		</>
	);

}

export default TeamEdit;