
import React, { useState, useEffect, useContext } from 'react'
import './index.css'
import { useNavigate } from 'react-router';
import { MdEmail, MdLock } from "react-icons/md"
import { HiEye, HiEyeOff } from "react-icons/hi"
import { FaBuilding } from "react-icons/fa"
import { AxiosResponse } from 'axios';
import logo from '../../img/now.png'
import Swal from 'sweetalert2';
import api from '../../Services/api'
import { useAuth } from '../../context/useAuth';
import  secureLocalStorage  from  "react-secure-storage";
import axios from 'axios'; 
import ModalPrincipal from '../../components/ModalAlert/modal'

function Login() {

   const auth = useAuth()

   const [exibirGrid, setExibirGrid] = useState(<></>);
   const [openModal, setOpenModal] = useState(false)
   const [qualAlert, setQualAlert] = useState(<></>)

   const navigate = useNavigate();
   const [company, setCompany] = useState("")
   const [login, setLogin] = useState("")
   const [senha, setSenha] = useState("")
   const [show, setShow] = useState(false)
   const [sucesso, setSucesso] = useState('');

   useEffect(() => {

      localStorage.removeItem("PrivateRoute");

   }, []);

   const handleClick = (e: any) => {
      e.preventDefault()
      setShow(!show);
   }


   async function ValidarLogin() {

      let ret: AxiosResponse;

      try {   ///https://apicentral.adlim.com.br:3334/ambientes/validar
          ret = await api.get('https://apicentral.adlim.com.br:3334/ambientes/validar', {
              params: {
                  ambiente: company,
              }
          })

          if (ret.data.status === 200) {


              secureLocalStorage.setItem('ep', ret.data.data[0].amb_endpointweb);
              secureLocalStorage.setItem('cp', company) 

              //https://apicentral.adlim.com.br:3337
              let endpoint = ret.data.data[0].amb_endpointweb

              axios({
                  method: "get",
                  url: endpoint+'/user/login',
                   params: {
                           company: company,
                           login: login,
                           senha: senha
                           },
                }).then(async function (response) {
         

                      secureLocalStorage.setItem('t', response.data.userToken);
                      secureLocalStorage.setItem('acp', response.data.data[0].acp_integrationid);
                      secureLocalStorage.setItem('n', response.data.data[0].age_name);
                      navigate('/dashboard');
                    
                  })
                  .catch(function (error) {

                     
                      Swal.fire({
                          icon: 'error',
                          title: 'ATENÇÃO',
                          text: `Login inválido.`,
                          confirmButtonColor: "#222d32",
                      })
            
                  });

          }

      } catch (e: any) {

          Swal.fire({
              icon: 'error',
              title: 'ATENÇÃO',
              text: `Login inválido.`,
              confirmButtonColor: "#222d32",
          })

      }
   
      
   }

   function handleInput(e: any) {
      var ss = e.target.selectionStart;
      var se = e.target.selectionEnd;
      e.target.value = e.target.value.toUpperCase();
      e.target.selectionStart = ss;
      e.target.selectionEnd = se;
   }

   return (
      <div className="login">

         <div className="login-inf">
            <span className='title-informativo'></span>
         </div>

         <div className="login-right">
            <img className="logo" src={logo} alt="logo" />

            <div className="login-loginInputAmbiente">
               <FaBuilding />
               <input
                  className='mascInpt'
                  placeholder="Ambiente"
                  value={company}
                  onChange={e => setCompany(e.target.value)}
               />
            </div>

            <div className="login-loginInputEmail">
               <MdEmail />
               <input
                  placeholder="USUÁRIO"
                  value={login}
                  onChange={e => setLogin(e.target.value)}
               />
            </div>

            <div className="login-loginInputPassword">
               <MdLock />
               <input
                  placeholder="SENHA"
                  type={show ? "text" : "password"}
                  value={senha}
                  onChange={e => setSenha(e.target.value)}
               />
               <div className="login-eye">
                  {show ? (
                     <HiEye
                        size={20}
                        onClick={handleClick}
                     />
                  ) : (
                     <HiEyeOff
                        size={20}
                        onClick={handleClick}
                     />
                  )}
               </div>
            </div>
            <div className='contente-senha'>
               <span className='title-senha'>Esqueceu sua senha?</span>
            </div>
            {sucesso === 'N' ? <div className='alerta'><p>Usuário ou senha inválida.</p></div> : null}
            <button type="submit" onClick={ValidarLogin}>
               Entrar
            </button>

         </div>

         <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

      </div>
   )
}

export default Login

