import React, { useEffect, useState } from "react";
import { AxiosResponse } from 'axios';
import {Navigate, Outlet} from "react-router-dom"
import api from '../Services/api'
import { useAuth  } from '../context/AuthContext';
import  secureLocalStorage  from  "react-secure-storage";

  function useAuthe (){

	let user: any
	
	const _user = secureLocalStorage.getItem('t')
	
	if (_user) {
		user = _user
	}
	
	if (user) {
		return {
			auth: true,
			role: null,
		}
	} else {
		return {
			auth: false,
			role: null,
		}
	}
}

 function ProtectedRoutes(props: any){

	const {auth, role} = useAuthe()

	const acp = secureLocalStorage.getItem('acp')

	if (props.roleRequired) {
	
		const permiT = props.roleRequired?.split(",")
		
		const filtrarPerm = permiT.filter(
			(e: any) => {
				return e === acp
			}
		);

		let res;

		if (filtrarPerm.length > 0){
			res= true
		}else{
			res= false
		}

		return auth ? (
			res
			? (

				<Outlet />

			) : (

				<Navigate to="/denied" />

			)
		) : (

				<Navigate to="/login" />

		)
		
	} else {
		
		return auth ? <Outlet /> : <Navigate to="/login" />

	}

	
}

export default ProtectedRoutes
