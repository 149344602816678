import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { AxiosResponse } from "axios";
import './index.css'
import Select from 'react-select'
import moment from 'moment';
import PersonalizadoData from "../../components/PersonalizadoData";
import GridLocais from '../../components/GridLocais'
import Loading from '../../components/Loading';
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";

function PlannerAdd() {
    const navigate = useNavigate();

    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [tabIndex, setTabIndex] = useState(0);
    
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [toggleState, setToggleState] = useState(1);
    const [date, setDate] = useState('');

    const [id, setIdAgenda] = useState('');
    const [descricao, setDescricao] = useState(''); 
    const [ativo, setAtivo] = useState(true);
    const [ambiente, setAmbiente] = useState('');
    const [pessoa, SetPessoa] = useState('');
    const [equipe, SetEquipe] = useState('');
    const [situacao, SetSituacao] = useState('');
    const [frequencia, SetFrequencia] = useState('');
    const [freq_dias, SetFreq_dias] = useState('');
    const [pular_dom, SetPular_dom] = useState(false)
    const [pular_sab, SetPular_sab] = useState(false)
    const [dataIncial, SetDataIncial] = useState('');
    const [datafim, SetDatafim] = useState('');
    const [horaIncial, SetHoraIncial] = useState('');
    const [horafim, SetHorafim] = useState('');
    const [d1, SetD1] = useState(false);
    const [d2, SetD2] = useState(false);
    const [d3, SetD3] = useState(false);
    const [d4, SetD4] = useState(false);
    const [d5, SetD5] = useState(false);
    const [d6, SetD6] = useState(false);
    const [d7, SetD7] = useState(false);
    const [proximaExec, setProximaExec] = useState('');
    const [dateproximaExec, setDateProximaExec] = useState(new Date());
    const [forma, SetForma] = useState('');
    const [tipoTarefa, SetTipoTarefa] = useState('');
    const [antecipar, SetAntecipar] = useState('');
    const [modoAtividade, SetModoAtividade] = useState('');
    const [msgDia, SetMsgDia] = useState('');
    const [notificacao, SetNotificacao] = useState(false);
    const [tts, SetTts] = useState(false);
    const [sms, SetSms] = useState(false);
    const [email, SetEMail] = useState(false);
    const [recriar, SetRecriar] = useState(false);
    const [desativar, SetDesativar] = useState(false);
    const [observacao, SetObservacao] = useState(''); //ATENÇÃO FALTA INCLUIR
    const [subtipo, SetSubtipo] = useState(''); //ATENÇÃO FALTA INCLUIR
    const [mail, setMail] = useState('')

    const [frequencia10, setFrequencia10] = useState('');
    const [dadosPersonalizado2, setDadosPersonalizado2] = useState([]);

    const [proximaExecFunc, setProximaExecFunc] = useState('');
    const [dadosAtividade, setDadosAtividade] = useState([]);
    const [atividadeSelecionada, setAtividadeSelecionada] = useState([]);

    const [isDisabledForma, setIsDisabledForma] = useState(true)

    const [optionsAgent, setOptionsAgent] = useState([])
    const [optionsEquipe, setOptionsEquipe] = useState([])
    const [optionsForma, setOptionsForma] = useState([])
    const [optionsSituacao, setOptionsSituacao] = useState([])
    const [optionsTasktype, setOptionsTasktype] = useState([])
    const [optionsSubgrupoPreventiva, setOptionsSubgrupoPreventiva] = useState([])
    const [optionsFrequency, setOptionsFrequency] = useState([])

    const [dadosLocais, setDadosLocais] = useState([]);
    const [dadosLocal, setDadosLocal] = useState([]);
    const [optionsAtividade, setOptionsAtividade] = useState([]);

    useEffect(() => {
        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        validatToken();


    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            setOpenModal(false)
            GetForma();
            GetAgent();
            GetEquipe();
            GetSituacao();
            GetTasktype();
            GetSubgrupoPreventiva();
            GetFrequency();
            GetPlanner();

        }).catch(function (error) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        });
    }


    async function GetPlanner() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/planner/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {

			if (ret.data.status === 200) {
				valor = ret.data.data;
		
				setOptionsAtividade(valor)

			}
		})
		.catch(function (error) {
			setOptionsAtividade([])
		});
	}

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');
    }

    async function GetFrequency() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/frequency/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {

            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.fqc_id), label: user.fqc_description });
                });

                setOptionsFrequency(arr)

            }
        })
            .catch(function (error) {
                setOptionsFrequency([])
            });
    }

    async function GetSubgrupoPreventiva() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/preventivesubgroup/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.sgp_id), label: user.sgp_description });
                });

                setOptionsSubgrupoPreventiva(arr)

            }
        })
            .catch(function (error) {
                setOptionsSubgrupoPreventiva([])
            });
    }

    async function GetTasktype() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/tasktype/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;



                valor.map((user: any) => {
                    return arr.push({ value: String(user.tty_id), label: user.tty_description });
                });

                setOptionsTasktype(arr)

            }
        })
            .catch(function (error) {
                setOptionsTasktype([])
            });
    }


    async function GetSituacao() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/tasksituation/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;

                const situacao = valor.filter(
                    (e: any) => {
                        return e.stn_id !== 60 && e.stn_id !== 50
                    }
                );

                situacao.map((user: any) => {
                    return arr.push({ value: String(user.stn_id), label: user.stn_description });
                });



                setOptionsSituacao(arr)

            }
        })
            .catch(function (error) {
                setOptionsSituacao([])
            });
    }

    async function GetForma() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/taskform/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tsf_id), label: user.tsf_description });
                });

                setOptionsForma(arr)

            }
        })
            .catch(function (error) {

                setOptionsForma([])
            });
    }

    async function GetEquipe() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/team/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tea_id), label: user.tea_description });
                });

                setOptionsEquipe(arr)

            }
        })
            .catch(function (error) {
                setOptionsEquipe([])
            });
    }


    async function GetAgent() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/user/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {

            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.age_id), label: user.age_name });
                });

                setOptionsAgent(arr)

            }
        })
            .catch(function (error) {
                setOptionsAgent([])
            });
    }

    function ChamaTab1() {
        setTabIndex(0)
    }

    function ChamaTab2() {
        setTabIndex(1)
    }

    function ChamaTab3() {
        setTabIndex(2)
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active") as HTMLInputElement;

        if (e.checked) {

            setAtivo(true)
        } else {

            setAtivo(false)
        }
    }

    function ValitarDesable() {
        let e = document.getElementById("checkbox-desativar") as HTMLInputElement;

        if (e.checked) {

            SetDesativar(true)
        } else {

            SetDesativar(false)
        }
    }

    function ValitarRecreate() {
        let e = document.getElementById("checkbox-recreate") as HTMLInputElement;

        if (e.checked) {
            SetRecriar(true)
        } else {

            SetRecriar(false)
        }
    }


    let options = dadosAtividade.map((element: any) => {
        return { value: String(element.age_id), label: element.age_name }
    });

    function valor(selected: any) {
        setAtividadeSelecionada(selected)
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function SelecionarAgent(id: any) {

        if (id === '') {
            // setAge_id("")
            //   setTea_id("")
            //  setTsf_id("")
            setIsDisabledForma(true)
        } else {
            //  setTea_id("")
            // setTsf_id("")
            //  setAge_id(id)
            setIsDisabledForma(true)
        }
    }

    function SelecionarEquipe(id: any) {

        if (id === '') {
            //  setTea_id("")
            //  setAge_id("")
            // setTsf_id("")
            setIsDisabledForma(true)
        } else {
            // setAge_id("")
            // setTea_id(id)
            setIsDisabledForma(false)
        }
    }

    function ValidarPular_sab() {
        let e = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
        if (e.checked) {

            SetPular_sab(true)
        } else {

            SetPular_sab(false)
        }
    }

    function ValidarPular_dom() {
        let e = document.getElementById("checkbox-pular-dom") as HTMLInputElement;
        if (e.checked) {

            SetPular_dom(true)
        } else {

            SetPular_dom(false)
        }
    }


    function ValitarNotificacao() {
        let e = document.getElementById("checkbox-notificacao") as HTMLInputElement;
        if (e.checked) {

            SetNotificacao(true)

        } else {

            SetNotificacao(false)

        }
    }

    function ValitarTTS() {
        let e = document.getElementById("checkbox-tts") as HTMLInputElement;
        if (e.checked) {

            SetTts(true)

        } else {

            SetTts(false)

        }
    }

    function ValitarSMS() {
        let e = document.getElementById("checkbox-sms") as HTMLInputElement;
        if (e.checked) {

            SetSms(true)

        } else {

            SetSms(false)

        }
    }

    function ValitarEMail() {
        let e = document.getElementById("checkbox-email") as HTMLInputElement;
        if (e.checked) {

            SetEMail(true)

        } else {

            SetEMail(false)

        }
    }


    function showDate(dateString: any, daysMonthsYears: any) {
        const dateToShow = new Date(dateString);

        let timeI = document.getElementById("TIMEINI") as HTMLInputElement;

        if (!dateString || !dateToShow.getTime()) return '';

        dateToShow.setUTCHours(3);

        const { days, months, years } = daysMonthsYears;

        if (days) dateToShow.setDate(dateToShow.getDate() + Number(days));
        if (months) dateToShow.setMonth(dateToShow.getMonth() + Number(months));
        if (years) dateToShow.setFullYear(dateToShow.getFullYear() + Number(years));
        return setProximaExec(dateToShow.toLocaleDateString() + ' ' + timeI.value), setDateProximaExec(dateToShow)

    }

    const [daysMonthsYears, setDaysMonthsYears] = useState({
        days: 0,
        months: 0,
        years: 0,
    });

    function Frequencia() {
        let datain = document.getElementById("DATAINI") as HTMLInputElement;
        setDate(datain.value);

        let freq = document.getElementById("FREQUEC") as HTMLInputElement;

        let PROXE = document.getElementById("PROX") as HTMLInputElement;
        let dataI = document.getElementById("DATAINI") as HTMLInputElement;
        let dataF = document.getElementById("DATAFIM") as HTMLInputElement;
        let timeI = document.getElementById("TIMEINI") as HTMLInputElement;
        let timeF = document.getElementById("TIMEFIM") as HTMLInputElement;

        let checkd1 = document.getElementById("checkbox-D1") as HTMLInputElement;
        let checkd2 = document.getElementById("checkbox-D2") as HTMLInputElement;
        let checkd3 = document.getElementById("checkbox-D3") as HTMLInputElement;
        let checkd4 = document.getElementById("checkbox-D4") as HTMLInputElement;
        let checkd5 = document.getElementById("checkbox-D5") as HTMLInputElement;
        let checkd6 = document.getElementById("checkbox-D6") as HTMLInputElement;
        let checkd7 = document.getElementById("checkbox-D7") as HTMLInputElement;
        let checkboxpularsab = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
        let checkboxpulardom = document.getElementById("checkbox-pular-dom") as HTMLInputElement;

        let vfreqdias = document.getElementById("validar-freqdias") as HTMLInputElement;
        SetDataIncial(dataI.value)
        SetHoraIncial(timeI.value)
        SetHorafim(timeF.value)
        //SetFrequencia(frequencia)
        setProximaExecFunc('OK')


        if (frequencia === '1') {
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            showDate(datain.value, { ...daysMonthsYears, days: Number(1), months: Number(0), years: Number(0) });

            SetFreq_dias("")
            setFrequencia10('N')
        } else if (frequencia === '2') {
            SetPular_sab(false)
            SetPular_dom(false)
            setFrequencia10('N')
            checkd1.disabled = false;
            checkd2.disabled = false;
            checkd3.disabled = false;
            checkd4.disabled = false;
            checkd5.disabled = false;
            checkd6.disabled = false;
            checkd7.disabled = false;
            checkboxpularsab.disabled = true;
            checkboxpulardom.disabled = true;
            SetFreq_dias("")


            if (checkd1.checked) {
                SetD1(true)
            } else {

                SetD1(false)
            }
            if (checkd2.checked) {
                SetD2(true)
            } else {
                SetD2(false)
            }
            if (checkd3.checked) {
                SetD3(true)
            } else {
                SetD3(false)
            }
            if (checkd4.checked) {
                SetD4(true)
            } else {
                SetD4(false)
            }
            if (checkd5.checked) {
                SetD5(true)
            } else {
                SetD5(false)
            }
            if (checkd6.checked) {
                SetD6(true)
            } else {
                SetD6(false)
            }
            if (checkd7.checked) {
                SetD7(true)
            } else {
                SetD7(false)
            }

            const dt = new Date(dataI.value);


            const atual = dt.getDay();

            var newArray = [checkd2.checked, checkd3.checked, checkd4.checked, checkd5.checked, checkd6.checked, checkd7.checked, checkd1.checked];


            // 2
            var newArrayPt1 = newArray.slice(atual + 1)
            var newArrayPt2 = newArray.slice(0, atual + 1)



            var pos = newArrayPt1.indexOf(true) + (atual + 1);

            if (newArrayPt1.indexOf(true) === -1) {

                pos = newArrayPt2.indexOf(true);
            }

            const prox = pos;

            if (pos === -1) {
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
            } else {

                if (prox <= atual) {

                    showDate(dataI.value, { ...daysMonthsYears, days: Number((7 - atual) + prox), months: Number(0), years: Number(0) });
                } else {

                    showDate(dataI.value, { ...daysMonthsYears, days: Number((prox - atual)), months: Number(0), years: Number(0) });
                }

            }

        } else if (frequencia === '3') {

            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            showDate(datain.value, { ...daysMonthsYears, days: Number(15), months: Number(0), years: Number(0) });

            SetFreq_dias("")
        } else if (frequencia === '4') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(1), years: Number(0) });
        } else if (frequencia === '5') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(2), years: Number(0) });
        } else if (frequencia === '6') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(3), years: Number(0) });
        } else if (frequencia === '7') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(6), years: Number(0) });
        } else if (frequencia === '8') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            setFrequencia10('N')
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)

            SetFreq_dias("")
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(1) });
        } else if (frequencia === '9') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            setFrequencia10('N')
            SetFreq_dias(vfreqdias.value)

            showDate(datain.value, { ...daysMonthsYears, days: Number(vfreqdias.value), months: Number(0), years: Number(0) });

        } else if (frequencia === '10') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            setFrequencia10('S')

            SetFreq_dias("")
            localStorage.setItem("dadosPersonalizado2", JSON.stringify([]));
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
        }
        else if (frequencia === '11') {
            checkboxpularsab.disabled = false;
            checkboxpulardom.disabled = false;
            // showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
            checkd1.disabled = true;
            checkd2.disabled = true;
            checkd3.disabled = true;
            checkd4.disabled = true;
            checkd5.disabled = true;
            checkd6.disabled = true;
            checkd7.disabled = true;
            SetD1(false)
            SetD2(false)
            SetD3(false)
            SetD4(false)
            SetD5(false)
            SetD6(false)
            SetD7(false)
            setFrequencia10('N')
            showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
            SetFreq_dias(vfreqdias.value)


            if (vfreqdias.value === '' && datain.value === "") {

            } else {


                let status = moment(datain.value + ' ' + timeI.value).add(Number(vfreqdias.value), 'hour').format('DD/MM/YYYY HH:mm:ss');
                setProximaExec(status)

            }

        }

    }



    function FrequenciaInlcuir(id: any) {



        if (id === '') {
            setFrequencia10('N')
            SetFrequencia(id)
            setProximaExec('')
        } else {
            let datain = document.getElementById("DATAINI") as HTMLInputElement;
            setDate(datain.value);

            let freq = document.getElementById("FREQUEC") as HTMLInputElement;

            let PROXE = document.getElementById("PROX") as HTMLInputElement;
            let dataI = document.getElementById("DATAINI") as HTMLInputElement;
            let dataF = document.getElementById("DATAFIM") as HTMLInputElement;
            let timeI = document.getElementById("TIMEINI") as HTMLInputElement;
            let timeF = document.getElementById("TIMEFIM") as HTMLInputElement;

            let checkd1 = document.getElementById("checkbox-D1") as HTMLInputElement;
            let checkd2 = document.getElementById("checkbox-D2") as HTMLInputElement;
            let checkd3 = document.getElementById("checkbox-D3") as HTMLInputElement;
            let checkd4 = document.getElementById("checkbox-D4") as HTMLInputElement;
            let checkd5 = document.getElementById("checkbox-D5") as HTMLInputElement;
            let checkd6 = document.getElementById("checkbox-D6") as HTMLInputElement;
            let checkd7 = document.getElementById("checkbox-D7") as HTMLInputElement;
            let checkboxpularsab = document.getElementById("checkbox-pular-sab") as HTMLInputElement;
            let checkboxpulardom = document.getElementById("checkbox-pular-dom") as HTMLInputElement;

            let vfreqdias = document.getElementById("validar-freqdias") as HTMLInputElement;
            SetDataIncial(dataI.value)
            SetHoraIncial(timeI.value)
            SetHorafim(timeF.value)
            SetFrequencia(id)
            setProximaExecFunc('OK')

            if (id === '1') {
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                showDate(datain.value, { ...daysMonthsYears, days: Number(1), months: Number(0), years: Number(0) });

                SetFreq_dias("")
                setFrequencia10('N')
            } else if (id === '2') {
                SetPular_sab(false)
                SetPular_dom(false)
                setFrequencia10('N')
                checkd1.disabled = false;
                checkd2.disabled = false;
                checkd3.disabled = false;
                checkd4.disabled = false;
                checkd5.disabled = false;
                checkd6.disabled = false;
                checkd7.disabled = false;
                checkboxpularsab.disabled = true;
                checkboxpulardom.disabled = true;
                SetFreq_dias("")


                if (checkd1.checked) {
                    SetD1(true)
                } else {

                    SetD1(false)
                }
                if (checkd2.checked) {
                    SetD2(true)
                } else {
                    SetD2(false)
                }
                if (checkd3.checked) {
                    SetD3(true)
                } else {
                    SetD3(false)
                }
                if (checkd4.checked) {
                    SetD4(true)
                } else {
                    SetD4(false)
                }
                if (checkd5.checked) {
                    SetD5(true)
                } else {
                    SetD5(false)
                }
                if (checkd6.checked) {
                    SetD6(true)
                } else {
                    SetD6(false)
                }
                if (checkd7.checked) {
                    SetD7(true)
                } else {
                    SetD7(false)
                }

                const dt = new Date(dataI.value);


                const atual = dt.getDay();

                var newArray = [checkd2.checked, checkd3.checked, checkd4.checked, checkd5.checked, checkd6.checked, checkd7.checked, checkd1.checked];


                // 2
                var newArrayPt1 = newArray.slice(atual + 1)
                var newArrayPt2 = newArray.slice(0, atual + 1)



                var pos = newArrayPt1.indexOf(true) + (atual + 1);

                if (newArrayPt1.indexOf(true) === -1) {

                    pos = newArrayPt2.indexOf(true);
                }

                const prox = pos;

                if (pos === -1) {
                    showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
                } else {

                    if (prox <= atual) {

                        showDate(dataI.value, { ...daysMonthsYears, days: Number((7 - atual) + prox), months: Number(0), years: Number(0) });
                    } else {

                        showDate(dataI.value, { ...daysMonthsYears, days: Number((prox - atual)), months: Number(0), years: Number(0) });
                    }

                }

            } else if (id === '3') {


                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                setFrequencia10('N')
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)
                showDate(datain.value, { ...daysMonthsYears, days: Number(15), months: Number(0), years: Number(0) });

                SetFreq_dias("")
            } else if (id === '4') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                setFrequencia10('N')
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)

                SetFreq_dias("")
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(1), years: Number(0) });
            } else if (id === '5') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                setFrequencia10('N')
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)

                SetFreq_dias("")
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(2), years: Number(0) });
            } else if (id === '6') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                setFrequencia10('N')
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)

                SetFreq_dias("")
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(3), years: Number(0) });
            } else if (id === '7') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                setFrequencia10('N')
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)

                SetFreq_dias("")
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(6), years: Number(0) });
            } else if (id === '8') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                setFrequencia10('N')
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)

                SetFreq_dias("")
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(1) });
            } else if (id === '9') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)
                setFrequencia10('N')
                SetFreq_dias(vfreqdias.value)

                showDate(datain.value, { ...daysMonthsYears, days: Number(vfreqdias.value), months: Number(0), years: Number(0) });

            } else if (id === '10') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)
                setFrequencia10('S')

                SetFreq_dias("")
                localStorage.setItem("dadosPersonalizado2", JSON.stringify([]));
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
            }
            else if (id === '11') {
                checkboxpularsab.disabled = false;
                checkboxpulardom.disabled = false;
                // showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
                checkd1.disabled = true;
                checkd2.disabled = true;
                checkd3.disabled = true;
                checkd4.disabled = true;
                checkd5.disabled = true;
                checkd6.disabled = true;
                checkd7.disabled = true;
                SetD1(false)
                SetD2(false)
                SetD3(false)
                SetD4(false)
                SetD5(false)
                SetD6(false)
                SetD7(false)
                setFrequencia10('N')
                showDate(datain.value, { ...daysMonthsYears, days: Number(0), months: Number(0), years: Number(0) });
                SetFreq_dias(vfreqdias.value)


                if (vfreqdias.value === '' && datain.value === "") {

                } else {


                    let status = moment(datain.value + ' ' + timeI.value).add(Number(vfreqdias.value), 'hour').format('DD/MM/YYYY HH:mm:ss');
                    setProximaExec(status)

                }

            }
        }

    }

    function PessoaAjuste(pessoa: any) {

        if (pessoa === '') {
            SetPessoa('')
            SetEquipe('')
            SetForma('')
            setIsDisabledForma(true)
        } else {
            SetPessoa(pessoa)
            SetEquipe('')
            SetForma('')
            setIsDisabledForma(true)
        }

    }


    function EquipeAjuste(equipe: any) {
        if (equipe === '') {
            SetEquipe('')
            SetPessoa('')
            SetForma("")
            setIsDisabledForma(true)
        } else {
            SetEquipe(equipe)
            SetPessoa('')
            setIsDisabledForma(false)
        }
    }

    function FormaAjuste(forma: any) {

        if (forma === 'Selecione...') {
            SetForma('')
            SetPessoa('')
        } else {
            SetForma(forma)
            SetPessoa('')
        }
    }

    function validaDataFim(id: any) {

        if (id !== "") {

            if (dataIncial !== "") {

                if (new Date(id) < new Date(dataIncial)) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">A data final não pode ser menor que data inicial.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)

                } else {
                    SetDatafim(id)
                }
            } else {
                SetDatafim(id)
            }
        } else {
            SetDatafim(id)
        }


    }


    function personalizado2func(valor: any) {
        setDadosPersonalizado2(valor)

    }


    async function validatTokenConf() {

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

         
            SalvarPlan()

        }).catch(function (error) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

 

        });
    }

    async function SalvarPlan() {



        const temp_rows = dadosLocais.filter(
            (e: any) => {
                return e.ok === 1
            }
        );

        const FiltrarDescricao = optionsAtividade.filter(
			(e: any) => {
				return e.pla_description.toLowerCase() === descricao.toLowerCase()
			}
		);
		
        if (FiltrarDescricao.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

		} else
        if (descricao === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)


        }
        else if (pessoa === '' && equipe === '') {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o usuário ou equipe desejada.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)


        } else if (equipe !== '' && forma === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o modo de equipe desejada.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        }
        else if (situacao === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a situação desejada.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (tipoTarefa === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione o tipo de tarefa.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (dataIncial === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a data inicial desejada.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (horaIncial === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a hora inicial desejada.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (datafim !== '' && horafim === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a hora final desejada.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (horafim !== '' && datafim === '') {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Selecione a data final desejada.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else if (datafim !== '' && datafim < dataIncial) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Data final não pode ser menor que data incial.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

        } else
            if (dataIncial === datafim && horafim < horaIncial) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">A hora final não pode ser menor ou igual que hora inicial.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            }
            else if (frequencia === '' || frequencia === 'Selecione...') {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione a frequência desejada.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            } else if (frequencia === '2' && d1 === false && d2 === false && d3 === false && d4 === false && d5 === false && d6 === false && d7 === false) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione ao menos um dia.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            } else if (frequencia === '9' && freq_dias === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Informe a quantidade de dias.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            } else if (frequencia === '10' && dadosPersonalizado2.length === 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione as datas e horas personalizadas.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            }

            else if (frequencia === '11' && freq_dias === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Informe a quantidade de horas desejada.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            }

            else if (temp_rows.length === 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Selecione ao menos um local.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            }
            else {

                let datafims = ''

                if (datafim === '') {
                    datafims = ''
                } else {
                    datafims = (moment(datafim).format("DD/MM/YYYY"))
                }


                if (tipoTarefa === '') {
                    SetTipoTarefa('')
                }

                if (subtipo === '') {
                    SetSubtipo('')
                }

                let validaPessoa = null
                let validaEquipe = null
                let validaForma = null
                let validaTipoTarefa = null
                let validasubtipo = null

                if (pessoa === '') {
                    validaPessoa = (null)
                } else {
                    validaPessoa = (pessoa)
                }


                if (equipe === '') {
                    validaEquipe = (null)
                } else {
                    validaEquipe = equipe
                }


                if (forma === '') {
                    validaForma = (null)
                } else {
                    validaForma = forma
                }

                if (tipoTarefa === '') {
                    validaTipoTarefa = (null)
                } else {
                    validaTipoTarefa = tipoTarefa
                }

                if (subtipo === '') {
                    validasubtipo = (null)
                } else {
                    validasubtipo = subtipo
                }

                const dados = [{
                    pla_description: descricao,
                    pla_active: ativo,
                    pla_environment: "ADLIMSEDE", //INCLUIR AMBIENTE
                    age_id: validaPessoa,
                    tea_id: validaEquipe,
                    stn_id: situacao,
                    fqc_id: frequencia,
                    pla_amount: freq_dias,
                    pla_skipsunday: pular_dom,
                    pla_skipsaturday: pular_sab,
                    pla_startdate: dataIncial,
                    pla_enddate: datafim || null,
                    pla_starttime: horaIncial,
                    pla_endtime: horafim || null,
                    pla_sunday: d1,
                    pla_monday: d2,
                    pla_tuesday: d3,
                    pla_wednesday: d4,
                    pla_thursday: d5,
                    pla_friday: d6,
                    pla_saturday: d7,
                    pla_nextexecution: dateproximaExec,
                    tsf_id: validaForma,
                    tty_id: validaTipoTarefa,
                    pla_anticipate: antecipar,
                    pla_activitymode: 1,
                    pla_advancenotification: msgDia,
                    pla_notification: notificacao,
                    pla_tts: tts,
                    pla_sms: sms,
                    pla_mail: email,
                    pla_recreate: recriar,
                    pla_disable: desativar,
                    pla_observation: observacao,
                    sgp_id: validasubtipo,
                    pla_email: mail
                }]


                //-----------------------------------LOCAIS----------------------

                const temp_rows = dadosLocais.filter(
                    (e: any) => {
                        return e.ok === 1
                    }
                );

                const _user = secureLocalStorage.getItem('t') as ''
                const endpoint = secureLocalStorage.getItem('ep') as ''


                axios({
                    method: "post",
                    url: endpoint + '/planner/insert',
                    headers: {
                        Authorization: _user
                    },
                    data: {
                        dadosagenda: dados,
                        dadoslocal: temp_rows,
                        dadospersonalizado: dadosPersonalizado2,
                    }
                }).then(async function (ret) {

                    if (ret.data.status === 200) {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                            <div className='div-info-alert'><span className="info-alert">Agenda criada com sucesso.</span></div>
                            <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    }
                })
                    .catch(function (error) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível criar a tarefa.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)

                    });
            }
    }

    function finalizarModal1() {
        navigate('/planner');
    }

    function finalizarModal2() {
        setOpenModal(false)
    }


    function dadosLoc(obj: any) {

        setDadosLocais(obj)
    }


    function carregalocal(valor: any) {

        setDadosLocal(valor)
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal2'>
                        <div className="container-cad-prod21">
                            <div id="content1">
                                <div className='planner-btn'>
                                    <div className='btn-planner' onClick={() => setToggleState(1)} >
                                        <span className='fa-solid fa-user ControleIcon'></span>
                                        <span className="lab-icone">O Que/Quem </span>
                                    </div>
                                    <div className='btn-planner' onClick={() => setToggleState(2)}>
                                        <span className='fa-solid fa-calendar-plus ControleIcon'></span>
                                        <span className="lab-icone">Quando</span>
                                    </div>
                                    <div className='btn-planner' onClick={() => setToggleState(3)}>
                                        <span className='fa-solid fa-map-location ControleIcon'></span>
                                        <span className="lab-icone">Onde</span>
                                    </div>
                                </div>
                            </div>
                            <div id="content2">
                                <div className='planner-exibicao'>


                                    <div className="content-tabs">
                                        <div
                                            className={
                                                toggleState === 1 ? "content  active-content" : "content"
                                            }
                                        >



                                            <div className="planner-panel">

                                                <div className="container-cad-prod22">

                                                    <div id="content1">
                                                        <div className="input-box3">
                                                            <div className="input-c">
                                                                <input type="checkbox" id="checkbox-active" checked={ativo} onChange={ValitarActive} />
                                                                <span className="details">Ativo</span>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div id="content2">
                                                        <div className="input-box3">
                                                            <div className="input-c">
                                                                <input type="checkbox" id="checkbox-recreate" checked={recriar} onChange={ValitarRecreate} />
                                                                <span className="details">Recriar</span>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div id="content3">
                                                        <div className="input-box3">
                                                            <div className="input-c">
                                                                <input type="checkbox" id="checkbox-desativar" checked={desativar} onChange={ValitarDesable} />
                                                                <span className="details">Desativar</span>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>



                                                <div className="container-cad-prod4">

                                                    <div id="content1">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Descrição *</span>
                                                            <input
                                                                type="text"
                                                                autoComplete="off"
                                                                onChange={(e) => setDescricao(e.target.value)}
                                                                value={descricao}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div id="content2">
                                                        <div className="select-box-ativos-select">
                                                            <span className="details">Usuário</span>
                                                            <Select
                                                                placeholder={'Selecione...'}
                                                                options={optionsAgent}
                                                                styles={customStyles}
                                                                value={optionsAgent.filter(function (option: any) {
                                                                    return option.value === pessoa;
                                                                })}
                                                                onChange={(options: any) =>
                                                                    !options ? PessoaAjuste("") : PessoaAjuste(options.value)
                                                                }
                                                                isClearable
                                                                noOptionsMessage={() => "Não encontrado..."}
                                                                theme={(theme: any) => ({
                                                                    ...theme,
                                                                    borderRadius: 4,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ebebeb',
                                                                        primary: 'black',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>


                                                </div>

                                                <div className="container-cad-prod4">
                                                    <div id="content1">
                                                        <div className="select-box-ativos-select">
                                                            <span className="details">Equipe</span>
                                                            <Select
                                                                placeholder={'Selecione...'}
                                                                options={optionsEquipe}
                                                                styles={customStyles}
                                                                value={optionsEquipe.filter(function (option: any) {
                                                                    return option.value === equipe;
                                                                })}
                                                                onChange={(options: any) =>
                                                                    !options ? EquipeAjuste("") : EquipeAjuste(options.value)
                                                                }
                                                                isClearable
                                                                noOptionsMessage={() => "Não encontrado..."}
                                                                theme={(theme: any) => ({
                                                                    ...theme,
                                                                    borderRadius: 4,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ebebeb',
                                                                        primary: 'black',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div id="content2">
                                                        <div className="select-box-ativos-select">
                                                            <span className="details">Forma</span>
                                                            <Select
                                                                placeholder={'Selecione...'}
                                                                options={optionsForma}
                                                                styles={customStyles}
                                                                isDisabled={isDisabledForma}
                                                                value={optionsForma.filter(function (option: any) {
                                                                    return option.value === forma;
                                                                })}
                                                                onChange={(options: any) =>
                                                                    !options ? FormaAjuste("") : FormaAjuste(options.value)
                                                                }
                                                                isClearable
                                                                noOptionsMessage={() => "Não encontrado..."}
                                                                theme={(theme: any) => ({
                                                                    ...theme,
                                                                    borderRadius: 4,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ebebeb',
                                                                        primary: 'black',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="container-cad-prod2">
                                                    <div id="content1">
                                                        <div className="select-box-ativos-select">
                                                            <span className="details">Situação *</span>
                                                            <Select
                                                                placeholder={'Selecione...'}
                                                                options={optionsSituacao}
                                                                styles={customStyles}
                                                                value={optionsSituacao.filter(function (option: any) {
                                                                    return option.value === situacao;
                                                                })}
                                                                onChange={(options: any) =>
                                                                    !options ? SetSituacao("") : SetSituacao(options.value)
                                                                }
                                                                isClearable
                                                                noOptionsMessage={() => "Não encontrado..."}
                                                                theme={(theme: any) => ({
                                                                    ...theme,
                                                                    borderRadius: 4,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ebebeb',
                                                                        primary: 'black',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="content2">
                                                        <div className="select-box-ativos-select">
                                                            <span className="details">Tipo de Tarefa *</span>
                                                            <Select
                                                                placeholder={'Selecione...'}
                                                                options={optionsTasktype}
                                                                styles={customStyles}
                                                                value={optionsTasktype.filter(function (option: any) {
                                                                    return option.value === tipoTarefa;
                                                                })}
                                                                onChange={(options: any) =>
                                                                    !options ? SetTipoTarefa("") : SetTipoTarefa(options.value)
                                                                }
                                                                isClearable
                                                                noOptionsMessage={() => "Não encontrado..."}
                                                                theme={(theme: any) => ({
                                                                    ...theme,
                                                                    borderRadius: 4,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ebebeb',
                                                                        primary: 'black',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="content3">
                                                        <div className="select-box-ativos-select">

                                                            <span className="details">Subgrupo Preventiva</span>
                                                            <Select
                                                                placeholder={'Selecione...'}
                                                                options={optionsSubgrupoPreventiva}
                                                                styles={customStyles}

                                                                value={optionsSubgrupoPreventiva.filter(function (option: any) {
                                                                    return option.value === subtipo;
                                                                })}
                                                                onChange={(options: any) =>
                                                                    !options ? SetSubtipo("") : SetSubtipo(options.value)
                                                                }
                                                                isClearable
                                                                noOptionsMessage={() => "Não encontrado..."}
                                                                theme={(theme: any) => ({
                                                                    ...theme,
                                                                    borderRadius: 4,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ebebeb',
                                                                        primary: 'black',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="container-cad-prod3">
                                                    <div className="textareaBox-ativo">
                                                        <span className="details">Instrução</span>
                                                        <textarea
                                                            onChange={(e) => SetObservacao(e.target.value)}
                                                            value={observacao}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div
                                            className={
                                                toggleState === 2 ? "content  active-content" : "content"
                                            }
                                        >
                                            <div className="planner-panel">
                                                <div className="container-cad-prod2">
                                                    <div id="content1">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Data Inicial *</span>
                                                            <input
                                                                type="date" id="DATAINI"
                                                                value={dataIncial}
                                                                onChange={Frequencia}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="content2">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Hora Inicial *</span>
                                                            <input
                                                                type="time" id="TIMEINI" onChange={Frequencia}
                                                                value={horaIncial} />
                                                        </div>
                                                    </div>
                                                    <div id="content3">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Prox. Execução</span>
                                                            <input
                                                                type="text" disabled
                                                                id="PROX"
                                                                onChange={(e) => setProximaExec(e.target.value)}
                                                                value={proximaExec}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="container-cad-prod2">
                                                    <div id="content1">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Data Final</span>
                                                            <input
                                                                type="date" id="DATAFIM" onChange={(e) => validaDataFim(e.target.value)} value={datafim}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="content2">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Hora Final</span>
                                                            <input
                                                                type="time" id="TIMEFIM" onChange={(e) => SetHorafim(e.target.value)} value={horafim} />
                                                        </div>
                                                    </div>
                                                    <div id="content3">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Antecipar Tarefa</span>
                                                            <input
                                                                autoComplete="off"
                                                                onChange={(e) => SetAntecipar(e.target.value)}
                                                                value={antecipar}
                                                                type="number"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>







                                                <div className="container-cad-prod2">
                                                    <div id="content1">
                                                        <div className="select-box-ativos-select">
                                                            <span className="details">Frequência *</span>
                                                            <Select
                                                                maxMenuHeight={200}
                                                                id="FREQUEC"
                                                                placeholder={'Selecione...'}
                                                                options={optionsFrequency}
                                                                styles={customStyles}
                                                                value={optionsFrequency.filter(function (option: any) {
                                                                    return option.value === frequencia;
                                                                })}
                                                                onChange={(options: any) =>
                                                                    !options ? FrequenciaInlcuir("") : FrequenciaInlcuir(options.value)
                                                                }
                                                                isClearable
                                                                noOptionsMessage={() => "Não encontrado..."}
                                                                theme={(theme: any) => ({
                                                                    ...theme,
                                                                    borderRadius: 4,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#ebebeb',
                                                                        primary: 'black',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="content2">
                                                        <div className="input-box-ativos">
                                                            <span className="details">Quantidade</span>
                                                            <input
                                                                type="number"
                                                                onChange={Frequencia}
                                                                id="validar-freqdias"
                                                                value={freq_dias}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="content3">
                                                        <div className="input-box-ativos">

                                                            <span className="details">Antecipar Not.</span>
                                                            <input
                                                                onChange={(e) => SetMsgDia(e.target.value)}
                                                                value={msgDia}
                                                                type="number"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>


                                                {email === true ?
                                                    <div className="container-cad-prod3">
                                                        <div id="content1">
                                                            <div className="input-box-ativos">
                                                                <span className="details">E-mail</span>
                                                                <input
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    onChange={(e) => setMail(e.target.value)}
                                                                    value={mail}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}

                                                <div className="user-details">
                                                    <div className="input-box3">
                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-D1" checked={d1} onChange={Frequencia} />
                                                            <span className="details">Domingo</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-D2" checked={d2} onChange={Frequencia} />
                                                            <span className="details">Segunda - Feira</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-D3" checked={d3} onChange={Frequencia} />
                                                            <span className="details">Terça - Feira</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-D4" checked={d4} onChange={Frequencia} />
                                                            <span className="details">Quarta - Feira</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-D5" checked={d5} onChange={Frequencia} />
                                                            <span className="details">Quinta - Feira</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-D6" checked={d6} onChange={Frequencia} />
                                                            <span className="details">Sexta - Feira</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-D7" checked={d7} onChange={Frequencia} />
                                                            <span className="details">Sábado</span>
                                                        </div>
                                                    </div>
                                                    <div className="input-box3">
                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-pular-sab" checked={pular_sab} onChange={ValidarPular_sab} />
                                                            <span className="details">Pular Sábado</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-pular-dom" checked={pular_dom} onChange={ValidarPular_dom} />
                                                            <span className="details">Pular Domingo</span>
                                                        </div>
                                                    </div>
                                                    <div className="input-box3">
                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-notificacao" checked={notificacao} onChange={ValitarNotificacao} />
                                                            <span className="details">Notificação</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-sms" checked={sms} onChange={ValitarSMS} />
                                                            <span className="details">SMS</span>
                                                        </div>
                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-email" checked={email} onChange={ValitarEMail} />
                                                            <span className="details">E-Mail</span>
                                                        </div>

                                                        <div className="input-c">
                                                            <input type="checkbox" id="checkbox-tts" checked={tts} onChange={ValitarTTS} />
                                                            <span className="details">TTS</span>
                                                        </div>

                                                    </div>
                                                </div>


                                                {frequencia10 === 'S' ?
                                                    <PersonalizadoData frequencia={frequencia} personalizado2func={personalizado2func} dataIncial={dataIncial} />
                                                    : null}




                                            </div>
                                        </div>

                                        <div
                                            className={
                                                toggleState === 3 ? "content  active-content" : "content"
                                            }
                                        >


                                            {dadosLocais !== undefined ?
                                                <div className="planner-panel">
                                                    <GridLocais
                                                        th1='ATIVO'
                                                        th2='ID'
                                                        th3='DESCRIÇÃO'
                                                        th4='FREQUÊNCIA'
                                                        th5='HORA'
                                                        th6='ÚLTIMA EXECUÇÃO'
                                                        th7='PROXIMA EXECUÇÃO'
                                                        endPoint='localAgenda/listar'
                                                        params1={''}
                                                        params2={''}
                                                        col1='7'
                                                        col2='0'
                                                        col3='5'
                                                        col4='3'
                                                        col5='4'
                                                        carregalocal={carregalocal}
                                                        dadosLoc={dadosLoc}
                                                    />
                                                </div>
                                                : <div className="planner-panel">
                                                    <Loading />
                                                </div>}

                                        </div>

                                    </div>


                                    <div className="Bt-button-cad">
                                        <button type="submit" onClick={validatTokenConf}>
                                            <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                                        </button>
                                        <button type="submit" onClick={finalizarModal1}>
                                            <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                                        </button>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </main>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default PlannerAdd;