import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPrincipal from '../../components/GridPrincipal'
import { AxiosResponse } from "axios";
import DropdownItem from '../../components/DropdownItem'
import { useNavigate } from 'react-router';
import axios from 'axios'; 
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'

function Locais() {

    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {
      
        validatToken()

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      
            setExibirGrid(<GridPrincipal
                iconeExcluir={ModalDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Pavimento'
                th5='Identificador alternativo'
                th6='Data da Inclusão'
                endPoint='locais/buscarlocal'
                col1='1'
                col2='0'
                col3='2'
                col4='3'
                col5='4'
                col6='27'
                Delete='N'
                fromAdd='/locais/add'
                fromEdit='/locais/edit'
                ambiente='%'
                pag="locais"
            />)
           
              
            })
            .catch(function (error) {
      

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
      
      
            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');
     
    }

    function ModalDeletar(obj: any) {

    }

    
    function funtoggle(form: any) {

        if(form === 'Importar'){
            
            navigate('/importdata');
        }
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                                <span className='fa-sharp fa-solid fa-city' />
                                <h1 className='div-titulo-pages-text'>Locais</h1>
                            </div>
                            <DropdownItem funtoggle={funtoggle} />
                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>

                </main>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>
            
        </>
    );

}

export default Locais;