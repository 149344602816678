import axios from 'axios';
import secureLocalStorage from "react-secure-storage";

const api = axios.create({
    baseURL: 'http://192.168.4.146:333532323'

})

//ACESSOS AMBIENTE/ ENDPOINT

//'1', '1', 'TESTE', 'http://apicenter.adlim.com.br:3337', 'https://apicentral.adlim.com.br:3337'
//'2', '1', 'ADLIMSEDE', 'https://apicenter.adlim.com.br:3334', 'https://apicentral.adlim.com.br:3333'
//'4', '1', 'MOGNOS', 'http://apicenter.adlim.com.br:3336', 'https://apicentral.adlim.com.br:3336'
//'5', '1', 'DBDIAGNOSTICOS', 'https://apicenter.adlim.com.br:3338', 'https://apicentral.adlim.com.br:3338'

export default api;

