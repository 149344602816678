import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Droppable, DragDropContext, Draggable } from '@hello-pangea/dnd';
import { AiFillDelete } from "react-icons/ai";

function Beautiful() {


  let [lista, setLista] = useState([]);
  let [descricao, setDescricao] = useState("");

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(lista);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setLista(items);
  }

  function geraStringAleatoria() {
    var stringAleatoria = '';
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 8; i++) {
      stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return stringAleatoria;
  }

  function adicionarNovoItem() {

    if (descricao !== '') {
      let newArray = [];

      lista.map((item, index) => (
        newArray.push({
          name: item.name.toUpperCase()
        })
      ))


      let temp_rows;

      temp_rows = newArray.filter(
        (e) => {
          return e.name === descricao.toUpperCase()
        }
      );

      if (temp_rows.length > 0) {

        Swal.fire({
          icon: 'error',
          title: 'ATENÇÃO',
          text: `Uma seção com está descrição já existe.`,
          confirmButtonColor: "#222d32",
        })

      } else {
        const data = {
          id: geraStringAleatoria(),
          name: descricao,
        }

        setLista([...lista, data]);
        setDescricao("")

      }
      console.log(lista)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'ATENÇÃO',
        text: `Preencha uma descrição válida!`,
        confirmButtonColor: "#222d32",
      })
     
    }
  }

  const onDelete = (ID) => {

    const newArray = lista.filter((transaction) => transaction.id !== ID);
    setLista(newArray);
    // localStorage.setItem("transactions", JSON.stringify(newArray)); 

  };

  return (
    <>

      <div className="container-cad-prod17">
        <div id="content1">
          <div className="input-box-ativos">
            <input
              type="text"
              autoComplete="off"
              onChange={(e) => setDescricao(e.target.value)}
              value={descricao}
            />
          </div>
        </div>

        <div id="content2">
          <button className='btn-activity' type="submit" onClick={adicionarNovoItem}>
            <i className="fa-solid fa-circle-plus icon-ok-btn"></i> Adicionar Seção
          </button>
        </div>

      </div>


      <div className='container-beautiful'>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided) => (
              <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                {lista.map(({ id, name }, index) => {
                  return (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided) => (
                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <p>
                            {name}
                          </p>

                          <div className='icone-resumo' onClick={() => onDelete(id)}>
                            <AiFillDelete />
                          </div>

                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
}

export default Beautiful;
