import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridPlanner from '../../components/GridPlanner'
import { AxiosResponse } from "axios";
import axios from 'axios'; 
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';

function Planner() {

    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {
      
        validatToken();
        
    }, []);
    
    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      
            setExibirGrid(<GridPlanner
                iconeExcluir={ModalDeletar}
                th1='Ativo'
                th2='ID'
                th3='Descrição'
                th4='Frequência'
                th5='Última Exec.'
                th6='Próxima Exec.'
                endPoint='planner/listar'
                col1='1'
                col2='0'
                col3='2'
                col4='39'
                col5='23'
                col6='22'
                Delete='N'
                fromAdd='/planner/add'
                fromEdit='/planner/edit'
                ambiente='ADLIMNOW'
                btnDelete='S'
            />)
              
            })
            .catch(function (error) {
      
                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
      
            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');
     
    }

    function ModalDeletar(obj: any) {

    
    }

    return (
        <>
        <Nav />
        <div className="main-content">
        <Header />
         <main>
            <div className='containerPrincipal'>
                <div className='div-titulo-pages'>
                    <span className='fa-solid fa-calendar-days'/>
                        <h1 className='div-titulo-pages-text'>Planejador de Tarefas</h1>
                    </div>
                    {exibirGrid}
                </div>
            </main>  
         </div>

         <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Planner;