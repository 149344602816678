
import React, { useEffect, useState } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import WalletBox from '../../components/WalletBox';
import { AxiosResponse } from "axios";
import api from '../../Services/api';
import Loading from '../../components/Loading';
import './index.css';
import ProgressBar from '../../components/ProgressBar';
import Table from '../../components/table/Table';
import { useAuth } from '../../context/useAuth';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import axios from 'axios'; 

function Dashboard() {

    const navigate = useNavigate();

    const [totalUsuario, setTotalUsuario] = useState('0');
    const [totalAgenda, setTotalAgenda] = useState('0');
    const [totalLocal, setTotalLocal] = useState('0');
    const [totalAtividade, setTotalAtividade] = useState('0');

    const [componenteTable2, setComponenteTable2] = useState(<></>)
    const [titleTable2, setTitleTable2] = useState(<></>)

    const [componenteTable1, setComponenteTable1] = useState(<></>)
    const [titleTable1, setTitleTable1] = useState(<></>)
    const [optionsFrequencia, setOptionsFrequencia] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

        validatToken();

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
       
        api.defaults.headers.common['Authorization'] = _user as ''


        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      
      
              GetUser();
               GetAgenda();
              GetLocais();
              GetAtividades();
               Getlistarfrequencia();
               Getlistaragentporacesso();
              GetlistarTaskAtualEmCampo();
              
            })
            .catch(function (error) {
      

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
      
      
            });
    }


    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');
     
    }


    async function GetlistarTaskAtualEmCampo() {


        setTitleTable2(<div className="cont-listTitle"><h1 className='listTitle'>Ranking Tarefas Pendentes Hoje</h1></div>)
        setComponenteTable2(<><Loading /></>)



        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
        let valor: any;
		
        axios({
            method: "get",
            url: endpoint+'/dashboard/listartaskatualemcampo',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      

            valor = response.data.data;

          
            if (valor.length > 0) {

                setComponenteTable2(<Table
                    title1={'ID'}
                    title2={'Descrição'}
                    title3={'Quantidade'}
                    title4={'Ordem de Exibição'}
                    data={valor}
                    setarValor={chamarModalForaUso} />)

            }
       
              
            })
            .catch(function (error) {
      
      
            });

    }

    async function Getlistaragentporacesso() {

        setTitleTable1(<div className="cont-listTitle"><h1 className='listTitle'>Últimos Acesso Por Usuário Mobile</h1></div>)
        // setComponenteTable1(<><Loading /></>)

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
        let valor: any;
		
        axios({
            method: "get",
            url: endpoint+'/dashboard/listaragentporacesso',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      

            valor = response.data.data;


            if (valor.length > 0) {

                const arr1 = [] as any;

                let result1 = valor;

                result1.map((user: any) => {

                    let dataMod: any

                    if (user.valor4 === null || user.valor4 === "") {

                        dataMod = ''
                    
                    } else {
                    
                        dataMod = (user.valor4?.substring(8, 10) + '/' + user.valor4?.substring(5, 7) + '/' + user.valor4?.substring(0, 4) + " " + user.valor4?.substring(11, 16))

                    }

                    return arr1.push({ valor1: user.valor1, valor2: user.valor2, valor3: user.valor3, valor4: dataMod });
                });

                setComponenteTable1(<Table
                    title1={'ID'}
                    title2={'Login'}
                    title3={'Função'}
                    title4={'Ultima Sincronização'}
                    data={arr1}
                    setarValor={chamarModalForaUso} />)
            }

              
            })
            .catch(function (error) {
      
      
            });

    }


    function chamarModalForaUso() {

    }

    async function Getlistarfrequencia() {

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
        let valor: any;

        axios({
            method: "get",
            url: endpoint+'/dashboard/listarfrequencia',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      

            valor = response.data.data;

            if(valor.length){
                setOptionsFrequencia(valor)
            }else{
                setOptionsFrequencia([])
            }
          
       
              
            })
            .catch(function (error) {
      
      
            });

      
    }

    async function GetUser() {

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
        let valor: any;
        axios({
            method: "get",
            url: endpoint+'/user/listar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      

            valor = response.data.data;

            setTotalUsuario(valor.length)
       
              
            })
            .catch(function (error) {
      
      
            });

    }

    async function GetAgenda() {
     
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
        let valor: any;


        axios({
            method: "get",
            url: endpoint+'/planner/listar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      

            valor = response.data.data;

            setTotalAgenda(valor.length)

       
              
            })
            .catch(function (error) {
      
      
            });


      
    }

    async function GetLocais() {
       
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
        let valor: any;

        axios({
            method: "get",
            url: endpoint+'/locais/listar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      
            valor = response.data.data;


            setTotalLocal(valor.length)
             
            })
            .catch(function (error) {
      
      
            });

       
    }

    async function GetAtividades() {

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''
        let valor: any;

        axios({
            method: "get",
            url: endpoint+'/activity/listar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      

            valor = response.data.data;

                setTotalAtividade(valor.length)
       
              
            })
            .catch(function (error) {
      
      
            });


       
    }

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <div className='container-card-inicial'>
                    <div className="container-cad-dash1">
                        <WalletBox
                            title="Total de Usuários"
                            color="#f39c12"
                            icon="arrowUp"
                            icone="fa fa-user"
                            valor={totalUsuario}
                            chamarModalTotal={chamarModalForaUso}
                        />
                        <WalletBox
                            title="Total de Agenda"
                            color="#00c0ef"
                            icon="arrowUp"
                            icone="fa-solid fa-calendar-days"
                            valor={totalAgenda}
                            chamarModalTotal={chamarModalForaUso}
                        />
                        <WalletBox
                            title="Total de Locais"
                            color="#00a65a"
                            icon="arrowUp"
                            icone="fa-sharp fa-solid fa-city"
                            valor={totalLocal}
                            chamarModalTotal={chamarModalForaUso}
                        />
                        <WalletBox
                            title="Total de Atividades"
                            color="#d35c4d"
                            icon="arrowUp"
                            icone="fa-solid fa-clipboard-list"
                            valor={totalAtividade}
                            chamarModalTotal={chamarModalForaUso}
                        />
                    </div>
                </div>


                <div className="container-cad-dash2">

                    <div className="listContainer">
                        <div className="alin-listContainer">

                            {titleTable1}

                            {componenteTable1}

                        </div>
                    </div>

                    <ProgressBar obj={optionsFrequencia} totalativos={totalAgenda} />

                </div>

            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default Dashboard;