import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import ModalPrincipal from '../../components/ModalAlert/modal'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';

function FormTipoAgente(props: any) {

    const navigate = useNavigate();
    const dadosTipoAgente = (sessionStorage.getItem("dadosTipoAgente") || '{}');
    let userTip = JSON.parse(dadosTipoAgente);
    const [id, setId] = useState(userTip.agg_id || '')
    const [active, setActive] = useState(userTip.agg_active != undefined ? Boolean(userTip.agg_active) : true)
    const [description, setDescription] = useState(userTip.agg_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userTip.agg_integrationid || '')
    const [descriptionIni, setDescriptionIni] = useState(userTip.agg_description || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userTip.agg_integrationid || '')
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [optionsAgentGroup, setOptionsAgentGroup] = useState([])

    useEffect(() => {

        validatToken();

    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''


        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {


            GetAccessProfile()

        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    async function GetAccessProfile() {

        let ret: AxiosResponse;
        let valor: any;


        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/agentgroup/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {


            valor = response.data.data;


            setOptionsAgentGroup(valor)


        })
            .catch(function (error) {

                setOptionsAgentGroup([])
            });

    }

    async function validatTokenConf() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''


        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {


            ConfirmarTipoAgente();

        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }


    function finalizarModal2() {
        setOpenModal(false)
    }


    function ConfirmarTipoAgente() {


        if (id === undefined || id === '') {
            insertTipoAgente();

        } else {
            UpdateTipoAgente();
        }
    }

    async function UpdateTipoAgente() {
        let ret: AxiosResponse;

        const filtrarDescription = optionsAgentGroup.filter(
			(e: any) => {
				return e.agg_description.toLowerCase() === description.toLowerCase()
			}
		);

        const filtrarAlternativeIdentifier = optionsAgentGroup.filter(
			(e: any) => {
				return e.agg_integrationid.toLowerCase() === alternativeIdentifier.toLowerCase()
			}
		);

		if (descriptionIni.toLowerCase() !== description.toLowerCase() && filtrarDescription.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

            setOpenModal(true)

		} else

        if (description === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else
        if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && filtrarAlternativeIdentifier.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

            setOpenModal(true)

		} else
            if (alternativeIdentifier === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else {

        const dados = {
            agg_id: id,
            agg_active: active,
            agg_description: description,
            agg_integrationid: alternativeIdentifier,
        }
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "put",
            url: endpoint + '/agentgroup/update',
            headers: {
                Authorization: _user
            },
            data: {
                data: dados
            }
        }).then(async function (ret) {

            props.voltar()


        })
            .catch(function (error) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

            });
        }

    }

    async function insertTipoAgente() {
        let ret: AxiosResponse;

        const filtrarDescription = optionsAgentGroup.filter(
			(e: any) => {
				return e.agg_description.toLowerCase() === description.toLowerCase()
			}
		);

        const filtrarAlternativeIdentifier = optionsAgentGroup.filter(
			(e: any) => {
				return e.agg_integrationid.toLowerCase() === alternativeIdentifier.toLowerCase()
			}
		);

		if (filtrarDescription.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

            setOpenModal(true)

		} else

        if (description === "") {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else
        if (filtrarAlternativeIdentifier.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

            setOpenModal(true)

		} else
            if (alternativeIdentifier === "") {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else {

                const dados = [{
                    agg_active: active,
                    agg_description: description,
                    agg_integrationid: alternativeIdentifier,
                }]

                const _user = secureLocalStorage.getItem('t') as ''
                const endpoint = secureLocalStorage.getItem('ep') as ''

                axios({
                    method: "post",
                    url: endpoint + '/agentgroup/insert',
                    headers: {
                        Authorization: _user
                    },
                    data: {
                        data: dados
                    }
                }).then(async function (ret) {

                    if (ret.data.status === 200) {
                        props.voltar()
                    }

                })
                    .catch(function (error) {
                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                    });
            }
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                </div>
                <div className="Bt-button-cad">
                    <button type="submit" onClick={validatTokenConf}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>


            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default FormTipoAgente;