import React, { createContext, useState, useEffect } from "react";
import { IContext, IAuthProvider, IUser } from "./types";
import { AxiosResponse } from 'axios';
import api from '../Services/api';
import { setUserLocalStorage, getUserLocalStorage } from "./util"
import secureLocalStorage from "react-secure-storage";
import ModalAlert from '../components/ModalAlert/modal'
import Swal from 'sweetalert2';
import axios from 'axios'; 

export const AuthContext = createContext<IContext>({} as IContext)

export const AuthProvider = ({ children }: IAuthProvider) => {


    const [user, setUser] = useState<IUser | null>()
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {

        const user = secureLocalStorage.getItem('t') as null;

        if (user) {
            setUser(user)
        }

    }, []);

    async function authenticate(company: string, login: string, password: string) {


        let ret: AxiosResponse;

        try {
            ret = await api.get('http://192.168.4.146:3334/ambientes/validar', {
                params: {
                    ambiente: company,
                }
            })

    

            if (ret.data.status === 200) {

                
                console.log(ret.data.data[0].amb_endpointweb)

                secureLocalStorage.setItem('ep', ret.data.data[0].amb_endpointweb);

                secureLocalStorage.setItem('ep', ret.data.data[0].amb_endpointweb);

                let endpoint = ret.data.data[0].amb_endpointweb

                axios({
                    method: "get",
                    url: endpoint+'/user/login',
                     params: {
                             company: company,
                             login: login,
                             senha: password
                             },
                  }).then(async function (response) {
              

                       const payload = { token: ret.data.userToken, login }

                        setUser(payload)

                        setUserLocalStorage(payload)

                        secureLocalStorage.setItem('t', ret.data.userToken);
                        secureLocalStorage.setItem('acp', ret.data.data[0].acp_integrationid);
                        secureLocalStorage.setItem('n', ret.data.data[0].age_name);
               
                      
                    })
                    .catch(function (error) {

                        Swal.fire({
                            icon: 'error',
                            title: 'ATENÇÃO',
                            text: `Login inválido.`,
                            confirmButtonColor: "#222d32",
                        })
              
                    });

            }

        } catch (e: any) {

            
            Swal.fire({
                icon: 'error',
                title: 'ATENÇÃO',
                text: `Login inválido.`,
                confirmButtonColor: "#222d32",
            })

        }


    }

    function logout() {
        setUser(null)
        setUserLocalStorage(null)
    }

    return (
        <AuthContext.Provider value={{ ...user, authenticate, logout }}>
            <>{children}

                <ModalAlert isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                    <div className='container-modal-alert'>
                        {qualAlert}
                    </div>
                </ModalAlert>



            </>
        </AuthContext.Provider>
    )
}