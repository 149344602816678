import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import GridSecundario from '../../components/GridSecundario'
import FormTipoAgente from '../../components/FormTipoAgente';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import ModalPrincipal from '../../components/ModalAlert/modal'
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'
import axios from 'axios';

function UsuariosEdit() {
	const navigate = useNavigate();

	const [qualTitulo, setQualTitulo] = useState(<></>)
	const [qualGrid, setQualGrid] = useState(<></>)

	const [openModal, setOpenModal] = useState(false)
	const [qualAlert, setQualAlert] = useState(<></>)

	let { id } = useParams();

	const [iduser, setIdUser] = useState('')
	const [integrationId, setIntegrationId] = useState('')
	const [name, setName] = useState('')
	const [nameIni, setNameIni] = useState('')
	const [login, setLogin] = useState('')
	const [loginIni, setLoginIni] = useState('')
	const [password, setPassword] = useState('')
	const [observation, setObservation] = useState('')
	const [country, setCountry] = useState('')
	const [state, setState] = useState('')
	const [city, setCity] = useState('')
	const [neighborhood, setNeighborhood] = useState('')
	const [street, setStreet] = useState('')
	const [streetNumber, setStreetNumber] = useState('')
	const [streetNumberCompl, setStreetNumberCompl] = useState('')
	const [zipcode, setZipcode] = useState('')
	const [phone, setPhone] = useState('')
	const [mobilePhone, setMobilePhone] = useState('')
	const [mail, setMail] = useState('')
	const [imeiLastSync, setImeiLastSync] = useState('')
	const [dateHourLastSync, setDateHourLastSync] = useState('')
	const [agg_id, setAgg_id] = useState('')
	const [active, setActive] = useState(true)
	const [lastGeoPosition, setLastGeoPosition] = useState('')
	const [geoPosition, setGeoPosition] = useState('')
	const [batteryLeve, setBatteryLeve] = useState('')
	const [mobilePlatformVersion, setMobilePlatformVersion] = useState('')
	const [mobileUser, setMobileUser] = useState(false)
	const [centerWebUser, setCenterWebUser] = useState(false)
	const [acp_id, setAcp_id] = useState('')
	const [dateTimeInsert, setDateTimeInsert] = useState('');
	const [dateTimeUpdate, setDateTimeUpdate] = useState('');
	const [id_insert, setId_insert] = useState('');
	const [id_lastUpdate, setId_lastUpdate] = useState('');
	const [phoneddd, setPhoneddd] = useState('');
	const [phoneddi, setPhoneddi] = useState('');
	const [mobilePhoneddd, setMobilePhoneddd] = useState('');
	const [mobilePhoneddi, setMobilePhoneddi] = useState('');


	const [optionsAgentGroup, setOptionsAgentGroup] = useState([])
	const [optionsAccessProfile, setOptionsAccessProfile] = useState([])
	const [optionsUser, setOptionsUser] = useState([])
	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

	useEffect(() => {


		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		validatToken();
	}, []);


	async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {

			setOpenModal(false)

			GetAgentGroup();
			usereditar();
			GetAccessProfile();



		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});
	}


	async function GetUser() {
		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/user/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {

			if (ret.data.status === 200) {
				valor = ret.data.data;

				setOptionsUser(valor)

			}
		})
			.catch(function (error) {
				setOptionsUser([])

			});
	}

	async function GetAccessProfile() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/accessprofile/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.acp_id), label: user.acp_description });
				});
				setOptionsAccessProfile(arr)

			}
		})
			.catch(function (error) {
				setOptionsAccessProfile([])
			});
	}

	async function usereditar() {
		let ret: AxiosResponse;
		let valor: any;

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		if (/^\d+$/.test(id || '') === true) {
			const _user = secureLocalStorage.getItem('t') as ''
			const endpoint = secureLocalStorage.getItem('ep') as ''

			axios({
				method: "get",
				url: endpoint + '/user/usereditar',
				headers: {
					Authorization: _user
				},
				params: {
					id: id
				}
			}).then(async function (ret) {

				if (ret.data.status === 200) {

					GetUser();
					setIdUser(ret.data.data[0].age_id || '')
					setIntegrationId(ret.data.data[0].age_integrationid || '')
					setName(ret.data.data[0].age_name || '')
					setNameIni(ret.data.data[0].age_name || '')
					setLoginIni(ret.data.data[0].age_login || '')
					setLogin(ret.data.data[0].age_login || '')
					setPassword(ret.data.data[0].age_password || '')
					setObservation(ret.data.data[0].age_observation || '')
					setCountry(ret.data.data[0].age_country || '')
					setState(ret.data.data[0].age_state || '')
					setCity(ret.data.data[0].age_city || '')
					setNeighborhood(ret.data.data[0].age_neighborhood || '')
					setStreet(ret.data.data[0].age_street || '')
					setStreetNumber(ret.data.data[0].age_streetnumber || '')
					setStreetNumberCompl(ret.data.data[0].age_streetnumbercompl || '')
					setZipcode(ret.data.data[0].age_zipcode || '')
					setPhone(ret.data.data[0].age_phone || '')
					setMobilePhone(ret.data.data[0].age_mobilephone || '')
					setMail(ret.data.data[0].age_mail || '')
					setImeiLastSync(ret.data.data[0].age_imeilastsync || '')
					setDateHourLastSync(ret.data.data[0].age_datehourlastsync || '')
					setAgg_id(String(ret.data.data[0].agg_id) || '')
					setActive(ret.data.data[0].age_active != undefined ? Boolean(ret.data.data[0].age_active) : true)
					setLastGeoPosition(ret.data.data[0].age_lastgeoposition || '')
					setGeoPosition(ret.data.data[0].age_geoposition || '')
					setBatteryLeve(ret.data.data[0].age_batteryleve || '')
					setMobilePlatformVersion(ret.data.data[0].age_mobileplatformversion || '')
					setMobileUser(ret.data.data[0].age_mobileuser != undefined ? Boolean(ret.data.data[0].age_mobileuser) : true)
					setCenterWebUser(ret.data.data[0].age_centerwebuser != undefined ? Boolean(ret.data.data[0].age_centerwebuser) : true)
					setAcp_id(ret.data.data[0].acp_id || '')
					setDateTimeInsert(ret.data.data[0].age_datetimeinsert.substring(0, 16) || '');
					setDateTimeUpdate(ret.data.data[0].age_datetimeupdate.substring(0, 16) || '');
					setId_insert(ret.data.data[0].age_id_insert || '');
					setId_lastUpdate(ret.data.data[0].age_id_lastupdate || '');
					setPhoneddd(ret.data.data[0].age_phoneddd || '');
					setPhoneddi(ret.data.data[0].age_phoneddi || '');
					setMobilePhoneddd(ret.data.data[0].age_mobilephoneddd || '');
					setMobilePhoneddi(ret.data.data[0].age_mobilephoneddi || '');
					setAcp_id(String(ret.data.data[0].acp_id) || '');

					setOpenModal(false)
				} else {
					setOpenModal(false)
					navigate('/user/edit/' + id + '/n');
				}
			})
				.catch(function (error) {

					console.log(error)
					
					setOpenModal(false)
					navigate('/user/edit/' + id + '/n');
				});

		} else {
			setOpenModal(false)
			navigate('/user/edit/' + id + '/n');
		}
	}

	async function GetAgentGroup() {
		let ret: AxiosResponse;
		let valor: any;
		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/agentgroup/listar',
			headers: {
				Authorization: _user
			},
		}).then(async function (ret) {
			if (ret.data.status === 200) {
				valor = ret.data.data;
				const arr = [] as any;
				let result = valor;
				result.map((user: any) => {
					return arr.push({ value: String(user.agg_id), label: user.agg_description });
				});
				setOptionsAgentGroup(arr)

			}

		})
			.catch(function (error) {
				setOptionsAgentGroup([])
			});
	}


	function ValitarActive() {
		let e = document.getElementById("checkbox-active") as HTMLInputElement;

		if (e.checked) {

			setActive(true)
		} else {

			setActive(false)
		}
	}

	function ValitarActivemobileuser() {
		let e = document.getElementById("checkbox-mobileuser") as HTMLInputElement;

		if (e.checked) {

			setMobileUser(true)
		} else {

			setMobileUser(false)
		}
	}

	function ValitarActiveCenterwebuser() {
		let e = document.getElementById("checkbox-centerwebuser") as HTMLInputElement;

		if (e.checked) {

			setCenterWebUser(true)
		} else {

			setCenterWebUser(false)
		}
	}

	const customStyles = {
		control: (provided: any, state: any) => ({
			...provided,
			background: '#fff',
			minHeight: '30px',
			height: '30px',
			borderColor: state.isFocused ? 'grey' : '#92989e',

		}),


		valueContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
			padding: '0 6px'
		}),

		input: (provided: any, state: any) => ({
			...provided,
			margin: '0px',
			fontSize: 14,

		}),
		indicatorsContainer: (provided: any, state: any) => ({
			...provided,
			height: '30px',
		}),
	};


	function ChamarModalAgentGroup(tipoModal: any) {


		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {

			if (tipoModal === 'Função') {

				setOpenModalIncluirNovo(true)
				sessionStorage.removeItem("dadosTipoAgente");
				setQualTitulo(<><h1 className='modal-tit'>Cadastrar Função</h1></>)
				setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
					th1='Ativo'
					th2='Descrição'
					th3='Identificador Alternativo'
					th4='Subcategoria'
					th5='Data da Inclusão'
					th6='Data Atualização'
					endPoint='agentgroup/listar'
					consult='N'
					col1='1'
					col2='2'
					col3='3'
					ambiente='%'
				/></>)
				)
			}

		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});

	}

	function fecharModal() {
		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		setOpenModal(false)
		navigate('/login');

	}

	function ChamarCadastroAgentGroup() {
		sessionStorage.removeItem("dadosTipoAgente");
		setQualGrid(<><FormTipoAgente voltar={BtnVoltarAgentGroup} /></>)
	}

	function BtnVoltarAgentGroup() {

		setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
			th1='Ativo'
			th2='Descrição'
			th3='Identificador Alternativo'
			th4='Subcategoria'
			th5='Data da Inclusão'
			th6='Data Atualização'
			endPoint='agentgroup/listar'
			consult='N'
			col1='1'
			col2='2'
			col3='3'
			ambiente='%'
		/></>))

		GetAgentGroup()
	}

	async function EditarAgentGroup(obj: any) {
		sessionStorage.setItem("dadosTipoAgente", JSON.stringify(obj));
		setQualGrid(<><FormTipoAgente voltar={BtnVoltarAgentGroup} /></>)
	}

	function AgentGroupDeletar(obj: any) {

		setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarAgentGroup(obj.agg_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)

	}

	async function deletarAgentGroup(id: any) {
		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;


		setOpenModal(false)

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			axios({
				method: "delete",
				url: endpoint + '/agentgroup/delete',
				headers: {
					Authorization: _user
				},
				data: {
					id: id
				},
			}).then(async function (ret) {
				if (ret.data.status === 200) {

					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
						th1='Ativo'
						th2='Descrição'
						th3='Identificador Alternativo'
						th4='Subcategoria'
						th5='Data da Inclusão'
						th6='Data Atualização'
						endPoint='agentgroup/listar'
						consult='N'
						col1='1'
						col2='2'
						col3='3'
						ambiente='%'
					/></>))
					GetAgentGroup()
				}
			})
				.catch(function (error) {
					setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGrid} Iconeadd={ChamarCadastroAgentGroup} Iconeditar={EditarAgentGroup} iconeExcluir={AgentGroupDeletar}
						th1='Ativo'
						th2='Descrição'
						th3='Identificador Alternativo'
						th4='Subcategoria'
						th5='Data da Inclusão'
						th6='Data Atualização'
						endPoint='agentgroup/listar'
						consult='N'
						col1='1'
						col2='2'
						col3='3'
						ambiente='%'
					/></>))
				});


		}).catch(function (error) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
				<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
			</div>)

			setOpenModal(true)


		});

	}

	function PegarValorGrid(valor: any) {

		setAgg_id(String(valor.agg_id))
		setOpenModalIncluirNovo(false)

	}

	function validatTokenConf() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''


		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			confirmarUser();

		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});

	}

	async function confirmarUser() {

		setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

		const FiltrarLogin = optionsUser.filter(
			(e: any) => {
				return e.age_login.toLowerCase() === login.toLowerCase()
			}
		);

		const FiltrarName = optionsUser.filter(
			(e: any) => {
				return e.age_name.toLowerCase() === name.toLowerCase()
			}
		);

		if (nameIni.toLowerCase() !== name.toLowerCase() && FiltrarName.length > 0) {

			setQualAlert(<div className='div-alert'>
				<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
				<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
				<div className='div-info-alert'><span className="info-alert">Nome já está em uso.</span></div>
				<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
			</div>)

		} else

			if (loginIni.toLowerCase() !== login.toLowerCase() && FiltrarLogin.length > 0) {

				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Login já está em uso.</span></div>
					<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
				</div>)

			} else

				if (name === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o Nome.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)


				} else if (login === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite o Login.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (password === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Digite a senha.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (agg_id === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Selecione a função.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else if (acp_id === "") {

					setQualAlert(<div className='div-alert'>
						<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
						<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
						<div className='div-info-alert'><span className="info-alert">Selecione o Perfil de acesso.</span></div>
						<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
					</div>)

				} else {

					let ret: AxiosResponse;
					const ambiente = secureLocalStorage.getItem('cp') as ''

					const dados = {
						age_id: iduser,
						age_integrationid: integrationId,
						age_name: name,
						age_login: login,
						age_password: password,
						age_observation: observation,
						age_country: country,
						age_state: state,
						age_city: city,
						age_neighborhood: neighborhood,
						age_street: street,
						age_streetnumber: streetNumber,
						age_streetnumbercompl: streetNumberCompl,
						age_zipcode: zipcode,
						age_phone: phone,
						age_mobilephone: mobilePhone,
						age_mail: mail,
						agg_id,
						age_active: active,
						age_mobileuser: mobileUser,
						age_centerwebuser: centerWebUser,
						acp_id,
						age_id_insert: id_insert,
						age_id_lastupdate: '1', //colocar o usuario
						age_phoneddd: phoneddd,
						age_phoneddi: phoneddi,
						age_mobilephoneddd: mobilePhoneddd,
						age_mobilephoneddi: mobilePhoneddi,
						age_company: ambiente //INCLUIR O AMBIENTE
					};

					const _user = secureLocalStorage.getItem('t') as ''
					const endpoint = secureLocalStorage.getItem('ep') as ''

					axios({
						method: "put",
						url: endpoint + '/user/update',
						headers: {
							Authorization: _user
						},
						data: {
							data: dados
						}
					}).then(async function (ret) {
						if (ret.data.status === 200) {
							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-check ControleIconAlert"></span>
								<div className='div-info-alert'><span className="info-alert">Alteração realizada com sucesso.</span></div>
								<div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
							</div>)
						}
					})
						.catch(function (error) {
							setQualAlert(<div className='div-alert'>
								<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
								<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
								<div className='div-info-alert'><span className="info-alert">Não foi possível editar o usuário.</span></div>
								<div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
							</div>)
						});

				}
	}

	function finalizarModal1() {
		navigate('/user');
	}

	function finalizarModal2() {
		setOpenModal(false)
	}

	function cancelar() {
		navigate('/user');
	}

	const maskOnlyNumbers = (value: any) => {
		return value.replace(/\D/g, "");
	};

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

	return (
		<>
			<Nav />
			<div className="main-content">
				<Header />
				<main>
					<div className='containerPrincipal'>
						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">1</div>
								<h2>Informações Gerais</h2>
							</div>
							<hr />
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-active" checked={active} onChange={ValitarActive} />
							<h4 className="lab-ativo">Ativo</h4>
						</div>

						<div className="container-cad-prod1">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Nome *</span>
									<input
										type="text"
										onChange={(e) => setName(e.target.value)}
										value={name}
										autoComplete="off"
									/>

								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Login *</span>
									<input
										type="text"
										onChange={(e) => setLogin(e.target.value)}
										value={login}
										autoComplete="off"
										disabled

									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Senha *</span>
									<input
										type="password"
										onChange={(e) => setPassword(e.target.value)}
										value={password}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod10">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">
										ID para Integração</span>
									<input
										type="text"
										onChange={(e) => setIntegrationId(e.target.value)}
										value={integrationId}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setMobilePhoneddi(maskOnlyNumbers(e.target.value))}
										value={mobilePhoneddi}
										autoComplete="off"
									/>
								</div>
							</div>


							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setMobilePhoneddd(maskOnlyNumbers(e.target.value))}
										value={mobilePhoneddd}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Celular</span>
									<input
										maxLength={18}
										type="text"
										onChange={(e) => setMobilePhone(maskOnlyNumbers(e.target.value))}
										value={mobilePhone}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content5">
								<div className="input-box-ativos">
									<span className="details">DDI</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setPhoneddi(maskOnlyNumbers(e.target.value))}
										value={phoneddi}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content6">
								<div className="input-box-ativos">
									<span className="details">DDD</span>
									<input
										maxLength={3}
										type="text"
										onChange={(e) => setPhoneddd(maskOnlyNumbers(e.target.value))}
										value={phoneddd}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content7">
								<div className="input-box-ativos">
									<span className="details">Telefone</span>
									<input
										maxLength={18}
										type="text"
										onChange={(e) => setPhone(maskOnlyNumbers(e.target.value))}
										value={phone}
										autoComplete="off"
									/>
								</div>
							</div>

						</div>

						<div className="container-cad-prod11">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">E-mail</span>
									<input
										type="text"
										onChange={(e) => setMail(e.target.value)}
										value={mail}
										autoComplete="off"
									/>
								</div>
							</div>

							<div id="content2">
								<div className="select-box-ativos-select">
									<div className='details-flex'>
										<span className="details">Função *</span>
										<MdOutlineAddCircle className='details-flex-icon-ativo' />
										<span className="details-esc-ativo" onClick={() => ChamarModalAgentGroup('Função')}>Incluir Novo</span>
									</div>
									<Select
										placeholder={'Selecione...'}
										options={optionsAgentGroup}
										styles={customStyles}
										value={optionsAgentGroup.filter(function (option: any) {
											return option.value === agg_id;
										})}
										onChange={(options: any) =>
											!options ? setAgg_id("") : setAgg_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">IMEI Ultima Sincronização</span>
									<input
										type="text"
										disabled={true}
										onChange={(e) => setImeiLastSync(e.target.value)}
										value={imeiLastSync}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod6">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Versão App Mobile</span>
									<input
										type="text"
										disabled={true}
										onChange={(e) => setMobilePlatformVersion(e.target.value)}
										value={mobilePlatformVersion}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Último Acesso Móvel</span>
									<input
										type="datetime-local"
										disabled
										value={dateHourLastSync}
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Data da Inclusão</span>
									<input
										type="datetime-local"
										value={dateTimeInsert}
										disabled
									/>
								</div>
							</div>

							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Data da Última Atualização</span>
									<input
										type="datetime-local"
										value={dateTimeUpdate}
										disabled
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod3">
							<div className="textareaBox-ativo">
								<span className="details">Observação</span>
								<textarea
									onChange={(e) => setObservation(e.target.value)}
									value={observation}
								/>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">2</div>
								<h2>Endereço</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod6">

							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Pais</span>
									<input
										type="text"
										onChange={(e) => setCountry(e.target.value)}
										value={country}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Estado</span>
									<input
										type="text"
										onChange={(e) => setState(e.target.value)}
										value={state}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Cidade</span>
									<input
										type="text"
										onChange={(e) => setCity(e.target.value)}
										value={city}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">Bairro</span>
									<input
										type="text"
										onChange={(e) => setNeighborhood(e.target.value)}
										value={neighborhood}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>

						<div className="container-cad-prod12">
							<div id="content1">
								<div className="input-box-ativos">
									<span className="details">Logradouro</span>
									<input
										type="text"
										onChange={(e) => setStreet(e.target.value)}
										value={street}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content2">
								<div className="input-box-ativos">
									<span className="details">Número</span>
									<input
										type="text"
										onChange={(e) => setStreetNumber(e.target.value)}
										value={streetNumber}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content3">
								<div className="input-box-ativos">
									<span className="details">Complemento</span>
									<input
										type="text"
										onChange={(e) => setStreetNumberCompl(e.target.value)}
										value={streetNumberCompl}
										autoComplete="off"
									/>
								</div>
							</div>
							<div id="content4">
								<div className="input-box-ativos">
									<span className="details">CEP</span>
									<input
										type="text"
										onChange={(e) => setZipcode(e.target.value)}
										value={zipcode}
										autoComplete="off"
									/>
								</div>
							</div>
						</div>

						<div className="title-Page">
							<div className="title-text">
								<div className="size-x circle">3</div>
								<h2>Permissões</h2>
							</div>
							<hr />
						</div>

						<div className="container-cad-prod13">
							<div id="content1">
								<div className="select-box-ativos-select">
									<span className="details">Perfil de Acesso *</span>
									<Select
										maxMenuHeight={140}
										placeholder={'Selecione...'}
										options={optionsAccessProfile}
										styles={customStyles}
										value={optionsAccessProfile.filter(function (option: any) {
											return option.value === acp_id;
										})}
										onChange={(options: any) =>
											!options ? setAcp_id("") : setAcp_id(options.value)
										}
										isClearable
										noOptionsMessage={() => "Não encontrado..."}
										theme={(theme: any) => ({
											...theme,
											borderRadius: 4,
											colors: {
												...theme.colors,
												primary25: '#ebebeb',
												primary: 'black',
											},
										})}
									/>
								</div>
							</div>
						</div>


						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-centerwebuser" checked={centerWebUser} onChange={ValitarActiveCenterwebuser} />
							<h4 className="lab-ativo">Acessa Center</h4>
						</div>

						<div className="class-checkbox">
							<input type="checkbox" id="checkbox-mobileuser" checked={mobileUser} onChange={ValitarActivemobileuser} />
							<h4 className="lab-ativo">Acessa Dispositivo Móvel</h4>
						</div>


						<div className="Bt-button-cad">
							<button type="submit" onClick={validatTokenConf}>
								<i className="fa fa-check-circle icon-ok-btn"></i> Salvar
							</button>
							<button type="submit" onClick={cancelar}>
								<i className="fa fa-times icon-cancelar-btn"></i> Cancelar
							</button>
						</div>

					</div>
				</main>
			</div>

			<ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

		</>
	);

}

export default UsuariosEdit;