import React, { useState, useEffect } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import './index.css'
import Select from 'react-select'
import { AxiosResponse } from "axios";
import { MdOutlineAddCircle } from 'react-icons/md';
import { useNavigate } from 'react-router';
import Loading from '../../components/Loading'
import GridSecundario from '../../components/GridSecundario'
import FormTipoTarefa from '../../components/FormTipoTarefa'
import FormTipoCorretiva from '../../components/FormTipoCorretiva'
import FormSubgrupoPreventiva from '../../components/FormSubgrupoPreventiva'
import ModalPrincipal from '../../components/ModalAlert/modal'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function TarefasAdd() {

    const navigate = useNavigate();

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [qualTitulo, setQualTitulo] = useState(<></>)
    const [qualGrid, setQualGrid] = useState(<></>)

    const [tsk_id, setTsk_id] = useState('');
    const [tty_id, setTty_id] = useState('');
    const [tsk_active, setTsk_active] = useState(true);
    const [tsk_recreate, setTsk_recreate] = useState(false);
    const [tsk_notification, setTsk_notification] = useState(false);
    const [age_id, setAge_id] = useState('');
    const [tea_id, setTea_id] = useState('');
    const [tsf_id, setTsf_id] = useState('');
    const [loc_id, setLoc_id] = useState('');
    const [tsk_integrationid, setTsk_integrationid] = useState('');
    const [tsk_priority, setTsk_priority] = useState('');
    const [stn_id, setStn_id] = useState('');
    const [src_id, setSrc_id] = useState(String(1));
    const [tsk_datetimeinsert, setTsk_datetimeinsert] = useState('');
    const [tsk_datetimeupdate, setTsk_datetimeupdate] = useState('');
    const [tsk_observation, setTsk_observation] = useState('');
    const [tsk_responsible, setTsk_responsible] = useState('');
    const [tsk_requester, setTsk_requester] = useState('');
    const [fqc_id, setFqc_id] = useState('');
    const [tsk_callback, setTsk_callback] = useState('');
    const [tsk_telephone, setTsk_telephone] = useState('');
    const [pla_id, setPla_id] = useState('');
    const [tsk_agendadescription, setTsk_agendadescription] = useState('');
    const [tcr_id, setTcr_id] = useState('');
    const [sgp_id, setSgp_id] = useState('');
    const [tsk_technicalinstruction, setTsk_technicalinstruction] = useState('');
    const [tsk_scheduleinitialdatehour, setTsk_scheduleinitialdatehour] = useState('');
    const [tsk_schedulefinaldatehour, setTsk_schedulefinaldatehour] = useState('');
    const [tsk_image1, setTsk_image1] = useState('');
    const [tsk_image2, setTsk_image2] = useState('');
    const [tsk_waitingtime, setTsk_waitingtime] = useState('');
    const [tsk_accesstoken, setTsk_accesstoken] = useState('');
    const [tsk_origemid, setTsk_origemid] = useState('');
    const [age_id_insert, setAge_id_insert] = useState('');
    const [age_id_lastupdate, setAge_id_lastupdate] = useState('');


    const [optionsTasktype, setOptionsTasktype] = useState([])
    const [optionsAgent, setOptionsAgent] = useState([])
    const [optionsEquipe, setOptionsEquipe] = useState([])
    const [optionsForma, setOptionsForma] = useState([])
    const [optionsLocal, setOptionsLocal] = useState([])
    const [optionsSituacao, setOptionsSituacao] = useState([])
    const [optionsFonte, setOptionsFonte] = useState([])
    const [optionsSubgrupoPreventiva, setOptionsSubgrupoPreventiva] = useState([])
    const [optionsCorrectivetype, setOptionsCorrectivetype] = useState([])

    const [tipo, setTipo] = useState('');
    const [isDisabledForma, setIsDisabledForma] = useState(true)
    const [isDisabledCorretiva, setIsDisabledCorretiva] = useState(true)
    const [isDisabledPreventiva, setIsDisabledPreventiva] = useState(true)

    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

    useEffect(() => {


        setQualAlert(<div className='div-alert'><Loading /></div>)
		setOpenModal(true)

        let today = new Date();
        let date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            today.getDate().toString().padStart(2, "0");
        let time =
            today.getHours().toString().padStart(2, "0") +
            ":" +
            today.getMinutes().toString().padStart(2, "0");
        let teste = date + "T" + time;
        setTsk_datetimeinsert(teste)

        validatToken();

   
    }, []);

    async function validatToken() {

		let ret: AxiosResponse;
		let valor: any;

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {

			setOpenModal(false)

            GetTasktype();
            GetAgent();
            GetEquipe();
            GetForma();
            GetLocal();
            GetSituacao();
            GetSource();
            GetSubgrupoPreventiva();
            GetCorrectivetype();

		})
			.catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});
	}

    function fecharModal() {
		secureLocalStorage.removeItem('t')
		secureLocalStorage.removeItem('acp')
		setOpenModal(false)
		navigate('/login');

	}

    
    async function SalvarTask() {
        let ret: AxiosResponse;

        setQualAlert(<div className='div-alert'><Loading /></div>)
        setOpenModal(true)

        if (tty_id === "") {


            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione o tipo de tarefa.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        } else if (stn_id === '30' && age_id === "" && tea_id === "") {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        } else if (stn_id === '40' && age_id === "" && tea_id === "") {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione quem vai executar a tarefa (Usuário ou Equipe).</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        } else if (tea_id !== "" && tsf_id === "") {
          
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione a forma de execução da equipe.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        } else if (loc_id === "") {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione o local.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
        

        } else if (stn_id === "") {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione a situação.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
        
        } else if (tsk_scheduleinitialdatehour === "") {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione a data/hora inicial.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        }else if (new Date(tsk_scheduleinitialdatehour) < new Date()) {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Data/hora inicial não pode ser menor ou igual a data/hora atual.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        } else if (tsk_schedulefinaldatehour !== "" && new Date(tsk_schedulefinaldatehour) <= new Date(tsk_scheduleinitialdatehour)) {
          
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">A data/hora final não pode ser menor ou igual a data/hora inicial.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
          

        } else if (tsk_schedulefinaldatehour !== "" && new Date(tsk_schedulefinaldatehour) < new Date()) {
           
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">A data/hora final não pode ser menor que data/hora atual.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)
           
        }
        else {
            const dados = [{
                tty_id: tty_id || null,
                tsk_active: tsk_active,
                tsk_recreate: tsk_recreate,
                tsk_notification: tsk_notification,
                age_id: age_id || null,
                tea_id: tea_id || null,
                tsf_id: tsf_id || null,
                loc_id: loc_id || null,
                tsk_integrationid: tsk_integrationid,
                tsk_priority: tsk_priority || null,
                stn_id: stn_id || null,
                src_id: src_id || null,
                tsk_observation: tsk_observation,
                tsk_responsible: tsk_responsible,
                tsk_requester: tsk_requester,
                fqc_id: fqc_id || null,
                tsk_callback: tsk_callback,
                tsk_telephone: tsk_telephone,
                pla_id: pla_id || null,
                tsk_agendadescription: tsk_agendadescription,
                tcr_id: tcr_id || null,
                sgp_id: sgp_id || null,
                tsk_technicalinstruction: tsk_technicalinstruction,
                tsk_scheduleinitialdatehour: tsk_scheduleinitialdatehour,
                tsk_schedulefinaldatehour: tsk_schedulefinaldatehour || null,
                tsk_image1: tsk_image1,
                tsk_image2: tsk_image2,
                tsk_waitingtime: tsk_waitingtime || null,
                age_id_insert: '1' //inserir usuario

            }]

            const _user = secureLocalStorage.getItem('t') as ''
            const endpoint = secureLocalStorage.getItem('ep') as ''
            
            
                            axios({
                                method: "post",
                                url: endpoint + '/task/insert',
                                headers: {
                                    Authorization: _user
                                },
                                data: {
                                     data: dados,
                                }
                            }).then(async function (ret) {
                if (ret.data.status === 200) {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-check ControleIconAlert"></span>
                        <div className='div-info-alert'><span className="info-alert">Tarefa criada com sucesso.</span></div>
                        <div className='btn-alert' onClick={finalizarModal1}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                }

            })
            .catch(function (error) {
                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado! Você precisa sair e entrar novamente no portal.</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)
            	});



        }


    }


    function finalizarModal1() {
        navigate('/task');
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    async function GetCorrectivetype() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/correctivetype/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tcr_id), label: user.tcr_description });
                });

                setOptionsCorrectivetype(arr)

            }
        })
        .catch(function (error) {
            setOptionsCorrectivetype([])
        });
    }

    async function GetSubgrupoPreventiva() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/preventivesubgroup/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.sgp_id), label: user.sgp_description });
                });

                setOptionsSubgrupoPreventiva(arr)

            }
        })
        .catch(function (error) {
            setOptionsSubgrupoPreventiva([])
        });
    }

    async function GetSource() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/source/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.src_id), label: user.src_description });
                });

                setOptionsFonte(arr)

            }
        })
        .catch(function (error) {
            setOptionsFonte([])
        });
    }

    async function GetSituacao() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/tasksituation/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;

                const situacao = valor.filter(
                    (e: any) => {
                        return e.stn_id !== 60
                    }
                );

                situacao.map((user: any) => {
                    return arr.push({ value: String(user.stn_id), label: user.stn_description });
                });



                setOptionsSituacao(arr)

            }
        })
        .catch(function (error) {
            setOptionsSituacao([])
        });
    }

    async function GetLocal() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/locais/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.loc_id), label: user.loc_description });
                });

                setOptionsLocal(arr)

            }
        })
        .catch(function (error) {
            setOptionsLocal([])
        });
    }


    async function GetForma() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/taskform/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tsf_id), label: user.tsf_description });
                });

                setOptionsForma(arr)

            }
    	})
        .catch(function (error) {
            setOptionsForma([])
        });
    }

    async function GetEquipe() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/team/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tea_id), label: user.tea_description });
                });

                setOptionsEquipe(arr)

            }
        })
        .catch(function (error) {
            setOptionsEquipe([])
        });
    }

    async function GetAgent() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/user/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.age_id), label: user.age_name });
                });

                setOptionsAgent(arr)

            }
       
		})
        .catch(function (error) {
            setOptionsAgent([])
        });
    }

    async function GetTasktype() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
			axios({
					method: "get",
					url: endpoint + '/tasktype/listar',
					headers: {
						Authorization: _user
					},
				}).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;



                valor.map((user: any) => {
                    return arr.push({ value: String(user.tty_id), label: user.tty_description });
                });

                setOptionsTasktype(arr)

            }
        })
        .catch(function (error) {
            setOptionsTasktype([])
        });
    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    function ValitarActive() {
        let e = document.getElementById("checkbox-active") as HTMLInputElement;

        if (e.checked) {

            setTsk_active(true)
        } else {

            setTsk_active(false)
        }
    }

    function ValitarNotification() {
        let e = document.getElementById("checkbox-notification") as HTMLInputElement;

        if (e.checked) {

            setTsk_notification(true)
        } else {

            setTsk_notification(false)
        }
    }

    function ValitarRecreate() {
        let e = document.getElementById("checkbox-recreate") as HTMLInputElement;

        if (e.checked) {

            setTsk_recreate(true)
        } else {

            setTsk_recreate(false)
        }
    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };

    function validarDataFim(id: any) {


        setTsk_schedulefinaldatehour(id)

    }

    function Voltar() {
        navigate('/task');
    }

    function SelecionarAgent(id: any) {

        if (id === '') {
            setAge_id("")
            setTea_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setTea_id("")
            setTsf_id("")
            setAge_id(id)
            setIsDisabledForma(true)
        }
    }

    function SelecionarEquipe(id: any) {

        if (id === '') {
            setTea_id("")
            setAge_id("")
            setTsf_id("")
            setIsDisabledForma(true)
        } else {
            setAge_id("")
            setTea_id(id)
            setIsDisabledForma(false)
        }
    }


    //--------------------------------------------- Tipo de Tarefa

    function ChamarModalTipoAtividade() {

        setTty_id("")
        setOpenModalIncluirNovo(true)
        sessionStorage.removeItem("dadosTipoTarefa");
        setQualTitulo(<><h1 className='modal-tit'>Cadastrar Tipo de atividade</h1></>)
        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTty_id} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
            th1='Ativo'
            th2='Descrição'
            th3='Identificador Alternativo'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='tasktype/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
        /></>)
        )

    }

    function ChamarCadastroTipoAtividade() {
        sessionStorage.removeItem("dadosTipoTarefa");
        setQualGrid(<><FormTipoTarefa voltar={BtnVoltarTipoAtividade} /></>)
    }

    function BtnVoltarTipoAtividade() {

        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTty_id} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
            th1='Ativo'
            th2='Descrição'
            th3='Identificador Alternativo'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='tasktype/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
        /></>))
        setOptionsTasktype([])
        GetTasktype();
    }

    async function EditarTipoAtividade(obj: any) {

        sessionStorage.setItem("dadosTipoTarefa", JSON.stringify(obj));
        setQualGrid(<><FormTipoTarefa voltar={BtnVoltarTipoAtividade} /></>)
    }

    function TipoAtividadeDeletar(obj: any) {

        setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarTipoAtividade(obj.tty_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)

    }

    
    async function deletarTipoAtividade(id: any) {


        setTty_id("")
        setOpenModal(false)
        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
		
						axios({
							method: "delete",
							url: endpoint + '/tasktype/delete',
							headers: {
								Authorization: _user
							},
							 data: { 
							 id: id 
							 }
						}).then(async function (ret) {
            if (ret.data.status === 200) {

                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTty_id} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
                    th1='Ativo'
                    th2='Descrição'
                    th3='Identificador Alternativo'
                    th4='Subcategoria'
                    th5='Data da Inclusão'
                    th6='Data Atualização'
                    endPoint='tasktype/listar'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente='%'
                /></>))
                setOptionsTasktype([])
                GetTasktype();
            }
        })
        .catch(function (error) {
            setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTty_id} Iconeadd={ChamarCadastroTipoAtividade} Iconeditar={EditarTipoAtividade} iconeExcluir={TipoAtividadeDeletar}
                th1='Ativo'
                th2='Descrição'
                th3='Identificador Alternativo'
                th4='Subcategoria'
                th5='Data da Inclusão'
                th6='Data Atualização'
                endPoint='tasktype/listar'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente='%'
            /></>))
            setOptionsTasktype([])
            GetTasktype();
        });
    }

    //-------------------------------------------- Tipo de Tarefa

        //--------------------------------------------- Tipo Corretiva

        function ChamarModalTipoCorretiva() {

            setTcr_id("")
            setOpenModalIncluirNovo(true)
            sessionStorage.removeItem("dadosTipoTarefa");
            setQualTitulo(<><h1 className='modal-tit'>Cadastrar Tipo Corretiva</h1></>)
            setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTcr_id} Iconeadd={ChamarCadastroTipoCorretiva} Iconeditar={EditarTipoCorretiva} iconeExcluir={TipoCorretivaDeletar}
                th1='Ativo'
                th2='Descrição'
                th3='Identificador Alternativo'
                th4='Subcategoria'
                th5='Data da Inclusão'
                th6='Data Atualização'
                endPoint='correctivetype/listar'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente='%'
            /></>)
            )
    
        }
    
        function ChamarCadastroTipoCorretiva() {
            sessionStorage.removeItem("dadosTipoCorretiva");
            setQualGrid(<><FormTipoCorretiva voltar={BtnVoltarTipoCorretiva} /></>)
        }
    
        function BtnVoltarTipoCorretiva() {
    
            setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTcr_id} Iconeadd={ChamarCadastroTipoCorretiva} Iconeditar={EditarTipoCorretiva} iconeExcluir={TipoCorretivaDeletar}
                th1='Ativo'
                th2='Descrição'
                th3='Identificador Alternativo'
                th4='Subcategoria'
                th5='Data da Inclusão'
                th6='Data Atualização'
                endPoint='correctivetype/listar'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente='%'
            /></>))
            setOptionsCorrectivetype([])
            GetCorrectivetype()
        }
    
        async function EditarTipoCorretiva(obj: any) {
    
            sessionStorage.setItem("dadosTipoCorretiva", JSON.stringify(obj));
            setQualGrid(<><FormTipoCorretiva voltar={BtnVoltarTipoCorretiva} /></>)
        }
    
        function TipoCorretivaDeletar(obj: any) {
    

            setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarTipoCorretiva(obj.tcr_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)

        
        }
    
        async function deletarTipoCorretiva(id: any) {

            setOpenModal(false)
            setTcr_id("")
            setQualGrid(<><Loading /></>)
            let ret: AxiosResponse;
            const _user = secureLocalStorage.getItem('t') as ''
            const endpoint = secureLocalStorage.getItem('ep') as ''
            
            
                            axios({
                                method: "delete",
                                url: endpoint + '/correctivetype/delete',
                                headers: {
                                    Authorization: _user
                                },
                                 data: { 
                                 id: id 
                                 }
                            }).then(async function (ret) {
                if (ret.data.status === 200) {
    
                    setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTcr_id} Iconeadd={ChamarCadastroTipoCorretiva} Iconeditar={EditarTipoCorretiva} iconeExcluir={TipoCorretivaDeletar}
                        th1='Ativo'
                        th2='Descrição'
                        th3='Identificador Alternativo'
                        th4='Subcategoria'
                        th5='Data da Inclusão'
                        th6='Data Atualização'
                        endPoint='correctivetype/listar'
                        consult='N'
                        col1='1'
                        col2='2'
                        col3='3'
                        ambiente='%'
                    /></>))
                    setOptionsCorrectivetype([])
                    GetCorrectivetype()
                }
            })
            .catch(function (error) {
                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridTcr_id} Iconeadd={ChamarCadastroTipoCorretiva} Iconeditar={EditarTipoCorretiva} iconeExcluir={TipoCorretivaDeletar}
                    th1='Ativo'
                    th2='Descrição'
                    th3='Identificador Alternativo'
                    th4='Subcategoria'
                    th5='Data da Inclusão'
                    th6='Data Atualização'
                    endPoint='correctivetype/listar'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente='%'
                /></>))
                setOptionsCorrectivetype([])
                GetCorrectivetype()
            });
        }
    
        //-------------------------------------------- Tipo Corretiva

    //--------------------------------------------- Tipo SubgrupoPreventiv

    function ChamarModalSubgrupoPreventiva() {

        setSgp_id("")     
        setOpenModalIncluirNovo(true)
        sessionStorage.removeItem("dadosTipoTarefa");
        setQualTitulo(<><h1 className='modal-tit'>Cadastrar SubGrupo Preventiva</h1></>)
        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridSgp_id} Iconeadd={ChamarCadastroSubgrupoPreventiva} Iconeditar={EditarSubgrupoPreventiva} iconeExcluir={SubgrupoPreventivaDeletar}
            th1='Ativo'
            th2='Descrição'
            th3='Identificador Alternativo'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='preventivesubgroup/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
        /></>)
        )

    }

    function ChamarCadastroSubgrupoPreventiva() {
        sessionStorage.removeItem("dadosSubgrupoPreventiva");
        setQualGrid(<><FormSubgrupoPreventiva voltar={BtnVoltarSubgrupoPreventiva} /></>)
    }

    function BtnVoltarSubgrupoPreventiva() {

        setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridSgp_id} Iconeadd={ChamarCadastroSubgrupoPreventiva} Iconeditar={EditarSubgrupoPreventiva} iconeExcluir={SubgrupoPreventivaDeletar}
            th1='Ativo'
            th2='Descrição'
            th3='Identificador Alternativo'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='preventivesubgroup/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
        /></>))
        setOptionsSubgrupoPreventiva([])
        GetSubgrupoPreventiva();
    }

    async function EditarSubgrupoPreventiva(obj: any) {

        sessionStorage.setItem("dadosSubgrupoPreventiva", JSON.stringify(obj));
        setQualGrid(<><FormSubgrupoPreventiva voltar={BtnVoltarSubgrupoPreventiva} /></>)
    }

    function SubgrupoPreventivaDeletar(obj: any) {
        
        setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarSubgrupoPreventiva(obj.sgp_id)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)


    }

    async function deletarSubgrupoPreventiva(id: any) {
        setSgp_id("")

        setOpenModal(false)

        setQualGrid(<><Loading /></>)
        let ret: AxiosResponse;
        const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''
		
		
						axios({
							method: "delete",
							url: endpoint + '/preventivesubgroup/delete',
							headers: {
								Authorization: _user
							},
							 data: { 
							 id: id 
							 }
						}).then(async function (ret) {
            if (ret.data.status === 200) {
                setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridSgp_id} Iconeadd={ChamarCadastroSubgrupoPreventiva} Iconeditar={EditarSubgrupoPreventiva} iconeExcluir={SubgrupoPreventivaDeletar}
                    th1='Ativo'
                    th2='Descrição'
                    th3='Identificador Alternativo'
                    th4='Subcategoria'
                    th5='Data da Inclusão'
                    th6='Data Atualização'
                    endPoint='preventivesubgroup/listar'
                    consult='N'
                    col1='1'
                    col2='2'
                    col3='3'
                    ambiente='%'
                /></>))
                setOptionsSubgrupoPreventiva([])
                GetSubgrupoPreventiva();
            }
        })
        .catch(function (error) {
            setQualGrid((<><GridSecundario PegarValorGrid={PegarValorGridSgp_id} Iconeadd={ChamarCadastroSubgrupoPreventiva} Iconeditar={EditarSubgrupoPreventiva} iconeExcluir={SubgrupoPreventivaDeletar}
                th1='Ativo'
                th2='Descrição'
                th3='Identificador Alternativo'
                th4='Subcategoria'
                th5='Data da Inclusão'
                th6='Data Atualização'
                endPoint='preventivesubgroup/listar'
                consult='N'
                col1='1'
                col2='2'
                col3='3'
                ambiente='%'
            /></>))
            setOptionsSubgrupoPreventiva([])
            GetSubgrupoPreventiva();
        });
    }

    //-------------------------------------------- SubgrupoPreventiva


	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}
    
    function PegarValorGridTty_id(valor: any) {

		setTty_id(String(valor.tty_id))
		setOpenModalIncluirNovo(false)

	}


    function PegarValorGridTcr_id(valor: any) {

		setTcr_id(String(valor.tcr_id))
		setOpenModalIncluirNovo(false)

		}

        function PegarValorGridSgp_id(valor: any) {

			setSgp_id(String(valor.sgp_id))
			setOpenModalIncluirNovo(false)

		}

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Informações Gerais</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="container-cad-prod16">
                            <div id="content1">
                                <div className="class-checkbox">
                                    <input type="checkbox" id="checkbox-active" checked={tsk_active} onChange={ValitarActive} disabled={true} />
                                    <h4 className="lab-ativo">Ativo</h4>
                                </div>
                            </div>
                            <div id="content2">
                                <div className="class-checkbox">
                                    <input type="checkbox" id="checkbox-recreate" checked={tsk_recreate} onChange={ValitarRecreate} />
                                    <h4 className="lab-ativo">Recriar</h4>
                                </div>
                            </div>
                            <div id="content3">
                                <div className="class-checkbox">
                                    <input type="checkbox" id="checkbox-notification" checked={tsk_notification} onChange={ValitarNotification} />
                                    <h4 className="lab-ativo">Notificação</h4>
                                </div>
                            </div>
                        </div>


                        <div className="container-cad-prod20">
                            <div id="content1">
                                <div className="select-box-ativos-select">

                                    <div className='details-flex'>
                                        <span className="details">Tipo de Tarefa *</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={ChamarModalTipoAtividade} />
                                        <span className="details-esc-ativo" onClick={ChamarModalTipoAtividade}>Incluir Novo</span>
                                    </div>


                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsTasktype}
                                        styles={customStyles}
                                        value={optionsTasktype.filter(function (option: any) {
                                            return option.value === tty_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setTty_id("") : setTty_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <span className="details">Usuário</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsAgent}
                                        styles={customStyles}
                                        value={optionsAgent.filter(function (option: any) {
                                            return option.value === age_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? SelecionarAgent("") : SelecionarAgent(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Equipe</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsEquipe}
                                        styles={customStyles}
                                        value={optionsEquipe.filter(function (option: any) {
                                            return option.value === tea_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? SelecionarEquipe("") : SelecionarEquipe(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>

                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <span className="details">Forma</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsForma}
                                        styles={customStyles}
                                        isDisabled={isDisabledForma}
                                        value={optionsForma.filter(function (option: any) {
                                            return option.value === tsf_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setTsf_id("") : setTsf_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod1">
                            <div id="content1">
                                <div className="select-box-ativos-select">
                                    <span className="details">Local *</span>
                                    <Select
                                    maxMenuHeight={200}
                                        placeholder={'Selecione...'}
                                        options={optionsLocal}
                                        styles={customStyles}
                                        value={optionsLocal.filter(function (option: any) {
                                            return option.value === loc_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setLoc_id("") : setLoc_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">ID para Integração</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setTsk_integrationid(e.target.value)}
                                        value={tsk_integrationid}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="select-box-ativos-select">
                                    <span className="details">Situação *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsSituacao}
                                        styles={customStyles}
                                        value={optionsSituacao.filter(function (option: any) {
                                            return option.value === stn_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setStn_id("") : setStn_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod6">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Prioridade</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setTsk_priority(maskOnlyNumbers(e.target.value))}
                                        value={tsk_priority}
                                    />
                                </div>
                            </div>

                            <div id="content2">
                                <div className="select-box-ativos-select">
                                    <span className="details">Origem *</span>
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsFonte}
                                        styles={customStyles}
                                        value={optionsFonte.filter(function (option: any) {
                                            return option.value === src_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setSrc_id("") : setSrc_id(options.value)
                                        }
                                        isClearable
                                        isDisabled={true}
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Data da inclusão</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        onChange={(e) => setTsk_datetimeinsert(e.target.value)}
                                        value={tsk_datetimeinsert}
                                    />
                                </div>
                            </div>

                            <div id="content4">
                                <div className="input-box-ativos">
                                    <span className="details">Data da inclusão</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        disabled
                                        onChange={(e) => setTsk_datetimeupdate(e.target.value)}
                                        value={tsk_datetimeupdate}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="container-3-ativo">
                            <div className="textareaBox-ativo">
                                <span className="details">Observação</span>
                                <textarea
                                    onChange={(e) => setTsk_observation(e.target.value)}
                                    value={tsk_observation}
                                />
                            </div>
                        </div>

                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Responsável</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setTsk_responsible(e.target.value)}
                                        value={tsk_responsible} />

                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Requisitante</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setTsk_requester(e.target.value)}
                                        value={tsk_requester} />

                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Telefone</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setTsk_telephone(e.target.value)}
                                        value={tsk_telephone} />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Descrição da Agenda</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        disabled
                                        onChange={(e) => setTsk_agendadescription(e.target.value)}
                                        value={tsk_agendadescription} />

                                </div>
                            </div>
                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">ID Agenda</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        disabled
                                        onChange={(e) => setPla_id(e.target.value)}
                                        value={pla_id} />

                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Callback</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        disabled
                                        onChange={(e) => setTsk_callback(e.target.value)}
                                        value={tsk_callback} />
                                </div>
                            </div>
                        </div>

                        <div className="container-cad-prod2">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Frequência</span>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={(e) => setFqc_id(e.target.value)}
                                        value={fqc_id}
                                        disabled
                                    />

                                </div>
                            </div>
                            <div id="content2">
                                <div className="select-box-ativos-select">

                                <div className='details-flex'>
                                        <span className="details">Tipo Corretiva </span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={ChamarModalTipoCorretiva} />
                                        <span className="details-esc-ativo" onClick={ChamarModalTipoCorretiva}>Incluir Novo</span>
                                    </div>
                            
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsCorrectivetype}
                                        styles={customStyles}

                                        value={optionsCorrectivetype.filter(function (option: any) {
                                            return option.value === tcr_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setTcr_id("") : setTcr_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="select-box-ativos-select">

                                <div className='details-flex'>
                                     <span className="details">Subgrupo Preventiva</span>
                                        <MdOutlineAddCircle className='details-flex-icon-ativo' onClick={ChamarModalSubgrupoPreventiva} />
                                        <span className="details-esc-ativo" onClick={ChamarModalSubgrupoPreventiva}>Incluir Novo</span>
                                    </div>

                                    
                                    <Select
                                        placeholder={'Selecione...'}
                                        options={optionsSubgrupoPreventiva}
                                        styles={customStyles}

                                        value={optionsSubgrupoPreventiva.filter(function (option: any) {
                                            return option.value === sgp_id;
                                        })}
                                        onChange={(options: any) =>
                                            !options ? setSgp_id("") : setSgp_id(options.value)
                                        }
                                        isClearable
                                        noOptionsMessage={() => "Não encontrado..."}
                                        theme={(theme: any) => ({
                                            ...theme,
                                            borderRadius: 4,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#ebebeb',
                                                primary: 'black',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        </div>





                        <div className="container-3-ativo">
                            <div className="textareaBox-ativo">
                                <span className="details">Instrução Para o Técnico</span>
                                <textarea
                                    onChange={(e) => setTsk_technicalinstruction(e.target.value)}
                                    value={tsk_technicalinstruction}
                                />
                            </div>
                        </div>


                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Defina quando esta tarefa deve ser executada.</h2>
                            </div>
                            <hr />
                        </div>

                        <div className="container-cad-prod18">
                            <div id="content1">
                                <div className="input-box-ativos">
                                    <span className="details">Data/Hora Inicial *</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        onChange={(e) => setTsk_scheduleinitialdatehour(e.target.value)}
                                        value={tsk_scheduleinitialdatehour}
                                    />
                                </div>
                            </div>

                            <div id="content2">
                                <div className="input-box-ativos">
                                    <span className="details">Data/Hora Final</span>
                                    <input
                                        type="datetime-local"
                                        id="DATA-TIME"
                                        name="DATE-TIME"
                                        onChange={(e) => validarDataFim(e.target.value)}
                                        value={tsk_schedulefinaldatehour}
                                    />
                                </div>
                            </div>
                            <div id="content3">
                                <div className="input-box-ativos">
                                    <span className="details">Tempo de Espera</span>
                                    <input
                                        type="time"
                                        autoComplete="off"
                                        onChange={(e) => setTsk_waitingtime(e.target.value)}
                                        value={tsk_waitingtime} />
                                </div>
                            </div>
                        </div>


                        <div className="Bt-button-cad">
                            <button type="submit" onClick={() => SalvarTask()}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                            </button>
                            <button type="submit" onClick={() => Voltar()}>
                                <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                            </button>
                        </div>

                    </div>
                </main>
            </div>

            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

        </>
    );

}

export default TarefasAdd;