import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import {
    FcAlphabeticalSortingAz,
    FcNumericalSorting12,
    FcOk,
    FcCalendar,
    FcClock,
    FcViewDetails,
    FcMms,
    FcAbout,
    FcGlobe,
    FcPlus
} from "react-icons/fc";
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';

function FormTipoAgente(props: any) {

    const navigate = useNavigate();
    const dadosTipoAgente = (sessionStorage.getItem("dadosTipoAgente") || '{}');
    let userTip = JSON.parse(dadosTipoAgente);
    const [id, setId] = useState(userTip.agg_id || '')
    const [active, setActive] = useState(userTip.agg_active != undefined ? Boolean(userTip.agg_active) : true)
    const [description, setDescription] = useState(userTip.agg_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userTip.agg_integrationid || '')
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [listarModelo, setListarModelo] = useState([])

    useEffect(() => {
        validatToken()
    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            setId(props.Data.ipm_id || '')
            setActive(props.Data.ipm_active != undefined ? Boolean(props.Data.ipm_active) : true)
            setDescription(props.Data.ipm_description || '')
            setAlternativeIdentifier(props.Data.ipm_fileprefix || '')
            Importmodelfieldseditar();



        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }


    async function Importmodelfieldseditar() {
        let ret: AxiosResponse;
        let valor: any;


        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/importmodelfields/listar',
            headers: {
                Authorization: _user
            },
            params: {
                id: props.Data.ipm_id
            }
        }).then(async function (ret) {

            if (ret.data.status === 200) {
                setListarModelo(ret.data.data)
                console.log(ret.data.data)
            }
        })
            .catch(function (error) {

            });


    }

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" disabled checked={active} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod4">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                                disabled
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Prefixo do arquivo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier}
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className='importmodelfields-div'>
                    {listarModelo.map((tdata: any, i: any) => (
                        <div className='importmodelfields'>
                            {tdata.fte_id === 1 ? (<FcAlphabeticalSortingAz className='icon-modelos' />) : null}
                            {tdata.fte_id === 2 ? (<FcNumericalSorting12 className='icon-modelos' />) : null}
                            {tdata.fte_id === 3 ? (<FcOk className='icon-modelos' />) : null}
                            {tdata.fte_id === 4 ? (<FcCalendar className='icon-modelos' />) : null}
                            {tdata.fte_id === 5 ? (<FcClock className='icon-modelos' />) : null}
                            {tdata.fte_id === 6 ? (<FcViewDetails className='icon-modelos' />) : null}
                            {tdata.fte_id === 7 ? (<FcMms className='icon-modelos' />) : null}
                            {tdata.fte_id === 8 ? (<FcAbout className='icon-modelos' />) : null}
                            {tdata.fte_id === 9 ? (<FcGlobe className='icon-modelos' />) : null}
                            <h1 className='importmodelfields-titulo'>{tdata.imf_descriptionlfields} ({tdata.imf_description})</h1>
                        </div>
                    ))}
                </div>

            </div>


            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default FormTipoAgente;