import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import GridTask from '../../components/GridTask'
import GridTaskHistory from '../../components/GridTaskHistory'
import { AxiosResponse } from "axios";
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function Task() {

    const navigate = useNavigate();
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
    const [qualTitulo, setQualTitulo] = useState(<></>)
    const [qualGrid, setQualGrid] = useState(<></>)

    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

    useEffect(() => {

        validatToken()

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            setExibirGrid(<GridTask
                iconeExcluir={ModalDeletar}
                iconEditar={editarTask}
                iconVisualizar={visualizarTask}
                th1='Ativo'
                th2='ID'
                th3='Onde'
                th4='Quem'
                th5='Previsto'
                th6='Executado'
                th7='Situação'
                th8='Tipo de tarefa'
                endPoint='task/buscartask'
                col1='1'
                col2='0'
                col3='2'
                col4='3'
                col5='4'
                col6='5'
                col7='9'
                col8='8'
                col9='6'
                col10='7'
                fromAdd='/task/add'
                fromEdit='/task/edit'
                btnDelete='S'
                pag="tarefas"
            />)


        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function visualizarTask(id: any,  obj: any){

        if (obj === null) {
    	   
            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">A tarefa não foi iniciada.</span></div>
            <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)

        }else{
            setOpenModalIncluirNovo(true)
			sessionStorage.removeItem("dadosTipoAtividade");
			setQualTitulo(<><h1 className='modal-tit'>Histórico</h1></>)
			setQualGrid((<><GridTaskHistory
				th1=''
				th2='Descrição'
				th3='Período de Execução'
				th4='Agente'
				th5='Transferência'
				endPoint='taskhistory/listar'
				consult='N'
				col1='1'
				col2='2'
				col3='3'
                col4='4'
				ambiente='%'
                idtask={id}
			/></>))
        }

    }

    function editarTask(id: any, obj: any) {

        if (obj === 'Retornada de Campo' || obj === 'Cancelada') {

            setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Não é possível editar essa tarefa.</span></div>
                    <div className='btn-alert' onClick={fecharModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

        } else {
            navigate('/task/edit/' + id);
        }

    }

    function fecharModal2(){
        setOpenModal(false)
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }

    function ModalDeletar(obj: any) {


    }

    async function deletarProduto(id: any) {

    }


	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}


    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>
                        <div className='ContainerBody'>
                            <div className='div-titulo-pages'>
                                <span className='fa-sharp fa-solid fa-table-list' />
                                <h1 className='div-titulo-pages-text'>Tarefas</h1>
                            </div>

                        </div>
                        <div className='grid-container-principal'>
                            {exibirGrid}
                        </div>
                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>
        </>
    );

}

export default Task;