import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import ModalPrincipal from '../ModalAlert/modal'
import Select from 'react-select'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';

function FormSubgrupoPreventiva(props: any) {

    const navigate = useNavigate();

    const dadosTipoAgente = (sessionStorage.getItem("dadosSubgrupoPreventiva") || '{}');
    let userTip = JSON.parse(dadosTipoAgente);
    const [id, setId] = useState(userTip.sgp_id || '')
    const [active, setActive] = useState(userTip.sgp_active != undefined ? Boolean(userTip.sgp_active) : true)
    const [description, setDescription] = useState(userTip.sgp_description || '')
    const [descriptionIni, setDescriptionIni] = useState(userTip.sgp_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(userTip.sgp_alternativeIdentifier || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(userTip.sgp_alternativeIdentifier || '')
    const [tcr_id, setTcr_id] = useState(String(userTip.tcr_id || ''))

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [optionsCorrectivetype, setOptionsCorrectivetype] = useState([])
    const [optionsPreventivesubgroup, setOptionsPreventivesubgroup] = useState([])

    useEffect(() => {

        validatToken()

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''


        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            GetCorretiva();
            GetCorrectivetype();

        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }

    async function GetCorrectivetype() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/correctivetype/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;
                const arr = [] as any;
                let result = valor;
                result.map((user: any) => {
                    return arr.push({ value: String(user.tcr_id), label: user.tcr_description });
                });

                setOptionsCorrectivetype(arr)

            }
        })
            .catch(function (error) {
                setOptionsCorrectivetype([])
            });
    }

    async function GetCorretiva() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/preventivesubgroup/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;


                setOptionsPreventivesubgroup(valor)

            }
        })
            .catch(function (error) {

                setOptionsPreventivesubgroup([])
            });
    }


    async function validatTokenConf() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            ConfirmarTipoAgente();


        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function ConfirmarTipoAgente() {

        if (id === undefined || id === '') {
            insertTipoAgente();

        } else {
            UpdateTipoAgente();
        }
    }

    async function UpdateTipoAgente() {
        let ret: AxiosResponse;


        const FiltrarLogin = optionsPreventivesubgroup.filter(
            (e: any) => {
                return e.sgp_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsPreventivesubgroup.filter(
            (e: any) => {
                return e.sgp_alternativeIdentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarLogin.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else
            if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltraralternativeIdentifier.length > 0) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else
                if (description === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)

                    } else {

                        const dados = {
                            sgp_id: id,
                            sgp_active: active,
                            sgp_description: description,
                            sgp_alternativeIdentifier: alternativeIdentifier,
                            tcr_id: tcr_id || null
                        }
                        const _user = secureLocalStorage.getItem('t') as ''
                        const endpoint = secureLocalStorage.getItem('ep') as ''


                        axios({
                            method: "put",
                            url: endpoint + '/preventivesubgroup/update',
                            headers: {
                                Authorization: _user
                            },
                            data: {
                                data: dados,
                            }
                        }).then(async function (ret) {
                            if (ret.data.status === 200) {
                                setOpenModal(false)
                                props.voltar()
                            }
                        })
                            .catch(function (error) {
                                setQualAlert(<div className='div-alert'>
                                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                    <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                </div>)
                                setOpenModal(true)
                            });
                    }
    }

    async function insertTipoAgente() {
        let ret: AxiosResponse;


        const FiltrarLogin = optionsPreventivesubgroup.filter(
            (e: any) => {
                return e.sgp_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsPreventivesubgroup.filter(
            (e: any) => {
                return e.sgp_alternativeIdentifier.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );


        if (FiltrarLogin.length > 0) {

            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else
            if (FiltraralternativeIdentifier.length > 0) {

                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)

            } else
                if (description === "") {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)

                    } else {
                        const dados = [{
                            sgp_active: active,
                            sgp_description: description,
                            sgp_alternativeIdentifier: alternativeIdentifier,
                            tcr_id: tcr_id || null
                        }]
                        const _user = secureLocalStorage.getItem('t') as ''
                        const endpoint = secureLocalStorage.getItem('ep') as ''


                        axios({
                            method: "post",
                            url: endpoint + '/preventivesubgroup/insert',
                            headers: {
                                Authorization: _user
                            },
                            data: {
                                data: dados,
                            }
                        }).then(async function (ret) {

                            if (ret.data.status === 200) {
                                setOpenModal(false)
                                props.voltar()
                            }
                        })
                            .catch(function (error) {

                                setQualAlert(<div className='div-alert'>
                                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                    <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                </div>)
                                setOpenModal(true)

                            });
                    }
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            minHeight: '30px',
            height: '30px',
            borderColor: state.isFocused ? 'grey' : '#92989e',

        }),


        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
            padding: '0 6px'
        }),

        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px',
            fontSize: 14,

        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '30px',
        }),
    };

    return (
        <>
            <div className="container-modal-pric">
                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod2">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                    <div id="content3">


                        <div className="select-box-ativos-select">
                            <span className="details">Tipo Corretiva </span>
                            <Select
                                placeholder={'Selecione...'}
                                options={optionsCorrectivetype}
                                styles={customStyles}
                                maxMenuHeight={90}
                                value={optionsCorrectivetype.filter(function (option: any) {
                                    return option.value === tcr_id;
                                })}
                                onChange={(options: any) =>
                                    !options ? setTcr_id("") : setTcr_id(options.value)
                                }
                                isClearable
                                noOptionsMessage={() => "Não encontrado..."}
                                theme={(theme: any) => ({
                                    ...theme,
                                    borderRadius: 4,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#ebebeb',
                                        primary: 'black',
                                    },
                                })}
                            />
                        </div>




                    </div>
                </div>
                <div className="Bt-button-cad">
                    <button type="submit" onClick={validatTokenConf}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default FormSubgrupoPreventiva;