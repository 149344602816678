import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import { AxiosResponse } from "axios";
import GridMyRecords from '../../components/GridMyRecords'
import FormMeusCadastros from '../../components/FormMeusCadastros'
import FormMyrecordsvalue from '../../components/FormMyrecordsvalue'
import Loading from '../../components/Loading';
import axios from 'axios'; 
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function Myrecords() {

    const [exibirGrid, setExibirGrid] = useState(<></>);

    const [qualTitulo, setQualTitulo] = useState(<></>)
	const [qualGrid, setQualGrid] = useState(<></>)
  
    const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    useEffect(() => {
        validatToken();
    }, []);


    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      
            setExibirGrid(<GridMyRecords
                th1='Descrição'
                th2='Identificador Alternativo'
                endPoint='myrecords/listar'
                consult='N'
                col1='0'
                col2='2'
                col3='3'
                ambiente='%'
                converter="S"
                ChamarModalCad={ChamarModalCad}
                ChamarCadastro={ChamarCadastro}
                IconeEditar={IconeEditar}
                params={null}
                Visualizar={'S'}
              />)
           
              
            })
            .catch(function (error) {
      

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
      
      
            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');
     
    }

    function IconeEditar(obj: any){
        sessionStorage.setItem("dadosMeusCadastro", JSON.stringify(obj));
        setQualTitulo(<><h1 className='modal-tit'>Meus cadastros</h1></>)
        setQualGrid(<><FormMeusCadastros/></>)
        setOpenModalIncluirNovo(true)
    }


    function ChamarCadastro(){
        sessionStorage.removeItem("dadosMeusCadastro");
            setQualTitulo(<><h1 className='modal-tit'>Meus cadastros</h1></>)
			setQualGrid(<><FormMeusCadastros/></>)
            setOpenModalIncluirNovo(true)
    }

//--------------------CADASTRO----------------------------------------------------

    function ChamarModalCad(obj: any) {

        sessionStorage.setItem("idcadastro", JSON.stringify(obj.mrc_id));
        
        
        setQualTitulo(<><h1 className='modal-tit'>{obj.mrc_description}</h1></>)
        setQualGrid(<GridMyRecords
            th1='Descrição'
            th2='Identificador Alternativo'
            endPoint='myrecordsvalue/listar'
            consult='N'
            col1='0'
            col2='3'
            col3='4'
            ambiente='%'
            converter="S"
            ChamarModalCad={ChamarModalCad}
            ChamarCadastro={ChamarCadastroMyrecordsvalue}
            IconeEditar={IconeEditarMyrecordsvalue}
            params={obj.mrc_id}
            IconeExcluir={ModalDeletar}
            btnDelete={'S'}
          />)
          setOpenModalIncluirNovo(true)
        
    }

    
    function ChamarCadastroMyrecordsvalue(){     
        sessionStorage.removeItem("dadosCadastro");
        setQualTitulo(<><h1 className='modal-tit'>Cadastros</h1></>)
        setQualGrid(<><FormMyrecordsvalue voltar={BtnVoltarMyrecordsvalue}/></>)
          setOpenModalIncluirNovo(true)
    }


    function IconeEditarMyrecordsvalue(obj: any){
        sessionStorage.setItem("dadosCadastro", JSON.stringify(obj));
        setQualTitulo(<><h1 className='modal-tit'>Meus cadastros</h1></>)
        setQualGrid(<><FormMyrecordsvalue voltar={BtnVoltarMyrecordsvalue}/></>)
          setOpenModalIncluirNovo(true)
    }

    function BtnVoltarMyrecordsvalue(obj: any) {

        setQualGrid(<GridMyRecords
            th1='Descrição'
            th2='Identificador Alternativo'
            endPoint='myrecordsvalue/listar'
            consult='N'
            col1='0'
            col2='3'
            col3='4'
            ambiente='%'
            converter="S"
            ChamarModalCad={ChamarModalCad}
            ChamarCadastro={ChamarCadastroMyrecordsvalue}
            IconeEditar={IconeEditarMyrecordsvalue}
            params={obj}
            IconeExcluir={ModalDeletar}
            btnDelete={'S'}
          />)

	
	}

    function ModalDeletar(obj: any) {
    
        setOpenModal(true)

		setQualAlert(<div className='div-alert'>
			<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
			<div className='div-info-alert-atencao'><span className="info-alert-atencao">Deseja deletar o registro?</span></div>
			<div className='div-info-alert'><span className="info-alert">Você não poderá reverter.</span></div>

			<div className='btn-alert-div'>
				<span className='btn-alert-esc' onClick={() => deletarCadastro(obj)}>
					<span className="btn-alert-lab">SIM</span>
				</span>
				<span className='btn-alert-dir' onClick={finalizarModal2}>
					<span className="btn-alert-lab">NÃO</span>
				</span>
			</div>
		</div>)
        
    }

    function finalizarModal2() {
		setOpenModal(false)
	}

    async function deletarCadastro(obj: any,) {
		setQualGrid(<><Loading /></>)
		let ret: AxiosResponse;

		setOpenModal(false)

		const _user = secureLocalStorage.getItem('t') as ''
		const endpoint = secureLocalStorage.getItem('ep') as ''

		axios({
			method: "get",
			url: endpoint + '/status/validar',
			headers: {
				Authorization: _user
			},
		}).then(async function (response) {


			axios({
				method: "delete",
				url: endpoint + '/myrecordsvalue/delete',
				headers: {
					Authorization: _user
				},
				data: { id: obj.mrv_id}
			}).then(async function (response) {

				 setQualGrid(<GridMyRecords
                    th1='Descrição'
                    th2='Identificador Alternativo'
                    endPoint='myrecordsvalue/listar'
                    consult='N'
                    col1='0'
                    col2='3'
                    col3='4'
                    ambiente='%'
                    converter="S"
                    ChamarModalCad={ChamarModalCad}
                    ChamarCadastro={ChamarCadastroMyrecordsvalue}
                    IconeEditar={IconeEditarMyrecordsvalue}
                    params={obj.mrc_id}
                    IconeExcluir={ModalDeletar}
                    btnDelete={'S'}
                  />)


			})
				.catch(function (error) {

					 setQualGrid(<GridMyRecords
                    th1='Descrição'
                    th2='Identificador Alternativo'
                    endPoint='myrecordsvalue/listar'
                    consult='N'
                    col1='0'
                    col2='3'
                    col3='4'
                    ambiente='%'
                    converter="S"
                    ChamarModalCad={ChamarModalCad}
                    ChamarCadastro={ChamarCadastroMyrecordsvalue}
                    IconeEditar={IconeEditarMyrecordsvalue}
                    params={obj.mrc_id}
                    IconeExcluir={ModalDeletar}
                    btnDelete={'S'}
                  />)
				  
				});

		}).catch(function (error) {


				setQualAlert(<div className='div-alert'>
					<span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
					<div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
					<div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
					<div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
				</div>)

				setOpenModal(true)


			});
	}

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

    return (
        <>
        <Nav />
        <div className="main-content">
        <Header />
         <main>
            <div className='containerPrincipal'>
            <div className='div-titulo-pages'>
                 <span className='fa fa-list' />
                    <h1 className='div-titulo-pages-text'>Meus Cadastros</h1>
                </div>
                 {exibirGrid}
            </div>
            </main>  
         </div>

         <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

			<ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
				<div className='container-modal-alert'>
					{qualAlert}
				</div>
			</ModalPrincipal>

        </>
    );

}

export default Myrecords;