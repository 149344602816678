import React, {useContext, useEffect, useState} from "react";
import "./index.css";
import { useNavigate } from 'react-router';
import  secureLocalStorage  from  "react-secure-storage";
import DropdownItemUser from '../DropdownItemUser'

const Header = (props: any) => {

  const [user, setUser] = useState("")

  useEffect(() => {   

    setUser(secureLocalStorage.getItem('n') as '')

   }, []);

  const navigate = useNavigate();
  
  const handleLogoff = () => {

    navigate('/login');
    secureLocalStorage.removeItem('t')
    secureLocalStorage.removeItem('acp')
    
 
  };

  return (
    <div className="main-content">
      <header>
        <h2>
          <label htmlFor="nav-toggle">
            <span className="fa fa-bars ico"></span>
          </label>
        </h2>

                  <div className='username'>
                      <p>Olá, <span className="username-sair">{user}</span></p>
                      <a onClick={handleLogoff} className="sair-username">Sair</a>
                  </div>
      </header>
    </div>
  );
};

export default Header;
