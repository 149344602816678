import React, { useState, useEffect } from 'react'
import './index.css'
import { AxiosResponse } from "axios";
import DualListBox from "react-dual-listbox";
import ModalPrincipal from '../../components/ModalAlert/modal'
import Loading from '../../components/Loading'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from 'react-router';

function FormTipoTarefa(props: any) {

    const navigate = useNavigate();

    const dadosEquipe = (sessionStorage.getItem("dadosTipoTarefa") || '{}');
    let usertlc = JSON.parse(dadosEquipe);

    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)

    const [id, setId] = useState(usertlc.tty_id || '')
    const [active, setActive] = useState(usertlc.tty_active != undefined ? Boolean(usertlc.tty_active) : true)
    const [description, setDescription] = useState(usertlc.tty_description || '')
    const [descriptionIni, setDescriptionIni] = useState(usertlc.tty_description || '')
    const [alternativeIdentifier, setAlternativeIdentifier] = useState(usertlc.tty_integrationid || '')
    const [alternativeIdentifierIni, setAlternativeIdentifierIni] = useState(usertlc.tty_integrationid || '')
    const [tty_displayorder, setTty_displayorder] = useState(usertlc.tty_displayorder || '')

    const [dadosActivity, setDadosActivity] = useState([]);
    const [activitySelecionada, setActivitySelecionada] = useState([]);

    const [optionsTasktype, setOptionsTasktype] = useState([])

    useEffect(() => {

        validatToken()

    }, []);

    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''


        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {


            GetTasktype();

            if (usertlc.tty_id !== undefined) {
                setQualAlert(<div className='div-alert'><Loading /></div>)
                setOpenModal(true)
                GetTasktypeactivityEditar(usertlc.tty_id)
                GetActivity();
            } else {
                GetActivity();
            }

        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }



    async function GetTasktype() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/tasktype/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;


                setOptionsTasktype(valor)

            }
        })
            .catch(function (error) {

                setOptionsTasktype([])
            });
    }

    async function GetTasktypeactivityEditar(id: any) {
        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/tasktypeactivity/listar',
            headers: {
                Authorization: _user
            },
            params: {
                id: id
            }
        }).then(async function (ret) {

            if (ret.data.status === 200) {

                if (ret.data.data.length > 0) {

                    const newarray = ret.data.data.map((element: any) => {
                        return String(element.act_id)
                    })

                    setActivitySelecionada(newarray)
                    setOpenModal(false)
                }

            }
        })
            .catch(function (error) {

                setOpenModal(false)
            });
    }

    async function GetActivity() {
        let ret: AxiosResponse;
        let valor: any;
        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/activity/listar',
            headers: {
                Authorization: _user
            },
        }).then(async function (ret) {
            if (ret.data.status === 200) {
                valor = ret.data.data;


                setDadosActivity(valor)

            }
        })
            .catch(function (error) {

                setDadosActivity([])
            });
    }


    async function validatTokenConf() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            ConfirmarTeamtype();


        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function ConfirmarTeamtype() {

        if (id === undefined || id === '') {
            insertTipoAgente();

        } else {
            UpdateTipoAgente();
        }
    }

    async function UpdateTipoAgente() {
        let ret: AxiosResponse;


        const FiltrarLogin = optionsTasktype.filter(
            (e: any) => {
                return e.tty_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsTasktype.filter(
            (e: any) => {
                return e.tty_integrationid.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (descriptionIni.toLowerCase() !== description.toLowerCase() && FiltrarLogin.length > 0) {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
        } else
            if (alternativeIdentifierIni.toLowerCase() !== alternativeIdentifier.toLowerCase() && FiltraralternativeIdentifier.length > 0) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)
            } else
                if (description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)

                    setOpenModal(true)
                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)
                    } else
                        if (tty_displayorder === "") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Digite a ordem de exibição.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        } else
                            if (activitySelecionada.length <= 0) {

                                setQualAlert(<div className='div-alert'>
                                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                    <div className='div-info-alert'><span className="info-alert">Selecione ao menos uma atividade.</span></div>
                                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                </div>)
                                setOpenModal(true)

                            } else {

                                const dados = {
                                    tty_id: id,
                                    tty_active: active,
                                    tty_description: description,
                                    tty_integrationid: alternativeIdentifier,
                                    tty_displayorder: tty_displayorder,
                                }

                                const _user = secureLocalStorage.getItem('t') as ''
                                const endpoint = secureLocalStorage.getItem('ep') as ''


                                axios({
                                    method: "put",
                                    url: endpoint + '/tasktype/update',
                                    headers: {
                                        Authorization: _user
                                    },
                                    data: {
                                        data: dados,
                                        dadosactivitySelecionada: activitySelecionada
                                    }
                                }).then(async function (ret) {
                                    if (ret.data.status === 200) {
                                        setOpenModal(false)
                                        props.voltar()
                                    }
                                })
                                    .catch(function (error) {
                                        setQualAlert(<div className='div-alert'>
                                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir a alteração.</span></div>
                                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                        </div>)
                                        setOpenModal(true)
                                    });
                            }
    }

    async function insertTipoAgente() {
        let ret: AxiosResponse;



        const FiltrarLogin = optionsTasktype.filter(
            (e: any) => {
                return e.tty_description.toLowerCase() === description.toLowerCase()
            }
        );

        const FiltraralternativeIdentifier = optionsTasktype.filter(
            (e: any) => {
                return e.tty_integrationid.toLowerCase() === alternativeIdentifier.toLowerCase()
            }
        );

        if (FiltrarLogin.length > 0) {


            setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Descrição já está em uso.</span></div>
                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)

        } else
            if (FiltraralternativeIdentifier.length > 0) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                    <div className='div-info-alert'><span className="info-alert">Identificador alternativo já está em uso.</span></div>
                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                </div>)
                setOpenModal(true)

            } else
                if (description === "") {

                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                        <div className='div-info-alert'><span className="info-alert">Digite a descrição.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                    setOpenModal(true)

                } else
                    if (alternativeIdentifier === "") {

                        setQualAlert(<div className='div-alert'>
                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                            <div className='div-info-alert'><span className="info-alert">Digite o identificador alternativo.</span></div>
                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                        </div>)
                        setOpenModal(true)
                    } else
                        if (tty_displayorder === "") {

                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                <div className='div-info-alert'><span className="info-alert">Digite a ordem de exibição.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)

                            setOpenModal(true)
                        } else
                            if (activitySelecionada.length <= 0) {

                                setQualAlert(<div className='div-alert'>
                                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                    <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                    <div className='div-info-alert'><span className="info-alert">Selecione ao menos uma atividade.</span></div>
                                    <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                </div>)
                                setOpenModal(true)
                            } else {
                                const dados = [{
                                    tty_active: active,
                                    tty_description: description,
                                    tty_integrationid: alternativeIdentifier,
                                    tty_displayorder: tty_displayorder
                                }]

                                const _user = secureLocalStorage.getItem('t') as ''
                                const endpoint = secureLocalStorage.getItem('ep') as ''


                                axios({
                                    method: "post",
                                    url: endpoint + '/tasktype/insert',
                                    headers: {
                                        Authorization: _user
                                    },
                                    data: {
                                        data: dados,
                                        dadosactivitySelecionada: activitySelecionada
                                    }
                                }).then(async function (ret) {

                                    if (ret.data.status === 200) {
                                        setOpenModal(false)
                                        props.voltar()
                                    }
                                })
                                    .catch(function (error) {
                                        setQualAlert(<div className='div-alert'>
                                            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                                            <div className='div-info-alert'><span className="info-alert">Não foi possível concluir o cadastramento.</span></div>
                                            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                                        </div>)
                                        setOpenModal(true)
                                    });
                            }
    }

    function finalizarModal2() {
        setOpenModal(false)
    }

    function ValitarActive() {
        let e = document.getElementById("checkbox-active-fab") as HTMLInputElement;

        if (e.checked) {

            setActive(true)
        } else {

            setActive(false)
        }
    }

    function voltar() {

        props.voltar();

    }

    let options = dadosActivity.map((element: any) => {
        return { value: String(element.act_id), label: element.act_description }
    });

    function valor(selected: any) {
        setActivitySelecionada(selected)
    }

    const maskOnlyNumbers = (value: any) => {
        return value.replace(/\D/g, "");
    };


    return (
        <>
            <div className="container-modal-pric">

                <div className="title-Page">
                    <div className="title-text">
                        <div className="size-x circle">1</div>
                        <h2>Informações Gerais</h2>
                    </div>
                    <hr />
                </div>

                <div className="class-checkbox">
                    <input type="checkbox" id="checkbox-active-fab" checked={active} onChange={ValitarActive} />
                    <h4 className="lab-ativo">Ativo</h4>
                </div>
                <div className="container-cad-prod2">
                    <div id="content1">
                        <div className="input-box-ativos">
                            <span className="details">Descrição *</span>
                            <input
                                type="text"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div id="content2">
                        <div className="input-box-ativos">
                            <span className="details">Identificador Alternativo *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setAlternativeIdentifier(e.target.value)}
                                value={alternativeIdentifier} />
                        </div>
                    </div>
                    <div id="content3">
                        <div className="input-box-ativos">
                            <span className="details">Ordem de exibição *</span>
                            <input
                                autoComplete="off"
                                type="text"
                                onChange={(e) => setTty_displayorder(maskOnlyNumbers(e.target.value))}
                                value={tty_displayorder} />
                        </div>
                    </div>
                </div>

                <div className="title-Page">
                    <div className="title-text">
                        <div className="size-x circle">2</div>
                        <h2>Atividades</h2>
                    </div>
                    <hr />
                </div>

                <div className='DualListBox'>
                    <DualListBox
                        options={options}
                        selected={activitySelecionada}
                        onChange={valor}
                        icons={{
                            moveLeft: "<",
                            moveAllLeft: "<<",
                            moveRight: ">",
                            moveAllRight: ">>"
                        }}
                        preserveSelectOrder={true}
                    />
                </div>

                <div className="Bt-button-cad">
                    <button type="submit" onClick={validatTokenConf}>
                        <i className="fa fa-check-circle icon-ok-btn"></i> Salvar
                    </button>
                    <button type="submit" onClick={props.voltar}>
                        <i className="fa fa-times icon-cancelar-btn"></i> Cancelar
                    </button>
                </div>
            </div>


            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        </>
    );

}

export default FormTipoTarefa;