import "./featured.scss";
import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";

const Progressbar = (props) => {
  return (
    <div className="featured-ProgressBar">
      <div className="top">
        <h1 className="title">Ranking de Agenda Por Frêquencia</h1>
      </div>


      <div className="container-progressbar">
        {props.obj.map((row) => (
           <div className="ProgressBar" key={row.fqc_id}>
              <h1 className="title-desc">{row.fqc_description +": "+ row.total}</h1>
              <ProgressBar completed = {(parseInt(row.total)/parseInt(props.totalativos)*100).toFixed(2)} bgColor="#13293D" animateOnRender={true} key={row.ctg_id} customLabel=" "/>    
              <div className="porcentagem-ProgressBar">
                    <h1 className="porcentagem-text">{(parseInt(row.total)/parseInt(props.totalativos)*100).toFixed(2)+'%'}</h1>
                    </div> 
           </div> 
          ))}
          
      </div>
     
    </div>
  );
};

export default Progressbar;
