import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const List = (props) => {

  function testando(id, valor2, valor5){
    props.setarValor(id, valor2, valor5)
  }

  return (
    <TableContainer component={Paper} className="table">
      <Table aria-label="simple table" className="table-tab">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">{props.title1}</TableCell>     
            <TableCell className="tableCell">{props.title2}</TableCell>
            <TableCell className="tableCell">{props.title3}</TableCell>
            <TableCell className="tableCell">{props.title4}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow  className="TableRow" key={row.valor1} onClick={() => props.setarValor(row.valor1, row.valor2, row.valor5)}>
              <TableCell className="tableCell">{row.valor1}</TableCell>
              <TableCell className="tableCell">{row.valor2}</TableCell>
              <TableCell className="tableCell">{row.valor3}</TableCell>
              <TableCell className="tableCell">{row.valor4}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
