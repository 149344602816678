import React, { useEffect, useState, useRef } from 'react'
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import Papa from 'papaparse';
import './index.css';
import GridImportacao from '../../components/GridImportacao'
import { AxiosResponse } from "axios";
import GridSecundarioModelos from '../../components/GridSecundarioModelos'
import FormModeloImportacao from '../../components/FormModeloImportacao'
import FormLogExecucao from '../../components/FormLogExecucao'
import Loading from '../../components/Loading';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import ModalPrincipal from '../../components/ModalAlert/modal'
import { useNavigate } from 'react-router';
import ModalIncluirNovo from '../../components/ModalIncluirNovo/modal'

function ImportarDados() {

    const navigate = useNavigate();
  
    const [qualTitulo, setQualTitulo] = useState(<></>)
    const [qualGrid, setQualGrid] = useState(<></>)
    const [exibirGrid, setExibirGrid] = useState(<></>);
    const [dataCSV, setDataCSV] = useState([]);
    const [nomeCSV, setNomeCSV] = useState([]);
    const [propriedadeCSV, setPropriedadeCSV] = useState([]);
    const [gridImportacao, setGridImportacao] = useState(<></>)
    const [openModal, setOpenModal] = useState(false)
    const [qualAlert, setQualAlert] = useState(<></>)
	const [openModalIncluirNovo, setOpenModalIncluirNovo] = useState(false)

    useEffect(() => {

        validatToken()

    }, []);




    async function validatToken() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint + '/status/validar',
            headers: {
                Authorization: _user
            },
        }).then(async function (response) {

            setGridImportacao(<GridImportacao
                th1='Nome do arquivo'
                th2='Status'
                th3='Origem'
                th4='Quem'
                th5='Agendamento em'
                th6='Importado em'
                th7='Log de execução'
                endPoint='importregistration/listar'
                col1='1'
                col2='4'
                col3='5'
                col4='8'
                col5='3'
                col6='6'
                fromAdd='/task/add'
                fromEdit='/task/edit'
                btnDelete='S'
                VisualizarLog={visualizarLog}
                Atualizar={Atualizar}
            />)



        })
            .catch(function (error) {


                setQualAlert(<div className='div-alert'>
                    <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                    <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                    <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
                </div>)

                setOpenModal(true)


            });
    }

    function fecharModal() {
        secureLocalStorage.removeItem('t')
        secureLocalStorage.removeItem('acp')
        setOpenModal(false)
        navigate('/login');

    }

    // Criar a variável para receber os dados
    const [dados, setDados] = useState([]);

    // Função para ler os dados do CSV
    const lerDadosArquivo = (e: any) => {

        // Receber o arquivo do formulário

        const arquivo = e.target.files[0];

        // Verificar se existe o arquivo
        if (arquivo) {



            // Usar a biblioteca para analisar o arquivo
            Papa.parse(arquivo, {
                //Papa.parse('arquivo_inexistente.csv', { // Simular erro
                //download: true,  // Simular erro
                encoding: "ISO-8859-1",
                header: true, // Define se a primeira linha do CSV é um cabeçalho
                dynamicTyping: true, // Converte automaticamente números e datas
                complete: (result) => { // Acessar complete após realizar a leitura do CSV



                    setPropriedadeCSV(Object.keys(result.data[0] as any) as any)

                    setDataCSV(result.data as any);

                    setNomeCSV(e.target.files[0].name)


                },
                error: (error: any) => { // Acessar error se houver erro na leitura do CSV
                    alert('Erro ao analisar o CSV:');
                },
            });
        }
    }

    async function validatTokeninsert() {

        let ret: AxiosResponse;
        let valor: any;

        const _user = secureLocalStorage.getItem('t') as ''
        const endpoint = secureLocalStorage.getItem('ep') as ''

        axios({
            method: "get",
            url: endpoint+'/status/validar',
            headers: {
              Authorization: _user
            },
          }).then(async function (response) {
      
          
            insertImportregistration()
              
            })
            .catch(function (error) {
      

                setQualAlert(<div className='div-alert'>
                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
                <div className='div-info-alert'><span className="info-alert">Token inválido ou expirado!</span></div>
                <div className='btn-alert' onClick={fecharModal}><span className="btn-alert-lab">OK</span></div>
            </div>)

            setOpenModal(true)
      
      
            });
    }

    async function insertImportregistration() {

        let ret: AxiosResponse;


        if (nomeCSV.length > 0) {

            setQualAlert(<div className='div-alert'><Loading /></div>)
            setOpenModal(true)


            const dados = [{
                irg_description: nomeCSV,
                age_id_insert: 5, //inserir usuário 
                irg_status: 'Pendente',
                irg_origin: 'Manual',
            }]

            const _user = secureLocalStorage.getItem('t') as ''
            const endpoint = secureLocalStorage.getItem('ep') as ''

            axios({
                method: "post",
                url: endpoint + '/importregistration/insert',
                headers: {
                    Authorization: _user
                },
                data: {
                    nomeCSV: dados
                }
            }).then(async function (ret) {
                if (ret.data.status === 200) {


                    console.log(ret.data.status)
                    const _user = secureLocalStorage.getItem('t') as ''
                    const endpoint = secureLocalStorage.getItem('ep') as ''

                    axios({
                        method: "put",
                        url: endpoint + '/importregistration/update',
                        headers: {
                            Authorization: _user
                        },
                        data: {
                            id: ret.data.data[0].irg_id,
                            dataCSV: dataCSV,
                            nomeCSV: dados,
                            propriedadeCSV: propriedadeCSV
                        }
                    }).then(async function (ret) {
                        if (ret.data.status === 200) {


                            window.location.reload();



                        }

                    })
                        .catch(function (error) {
                            setQualAlert(<div className='div-alert'>
                                <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                                <div className='div-info-alert'><span className="info-alert">Não foi realizar a importação.</span></div>
                                <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                            </div>)


                        });

                }

            })
                .catch(function (error) {
                    setQualAlert(<div className='div-alert'>
                        <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
                        <div className='div-info-alert'><span className="info-alert">Não foi realizar a importação.</span></div>
                        <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
                    </div>)
                });
        } else {

            setQualAlert(<div className='div-alert'>
            <span className="fa-regular fa-circle-xmark ControleIconAlertcancel"></span>
            <div className='div-info-alert-atencao'><span className="info-alert-atencao">ATENÇÃO</span></div>
            <div className='div-info-alert'><span className="info-alert">Selecione o arquivo.</span></div>
            <div className='btn-alert' onClick={finalizarModal2}><span className="btn-alert-lab">OK</span></div>
        </div>)

        setOpenModal(true)

  
        }

    }

    function finalizarModal2() {
        setOpenModal(false)
    }




    function ChamarVisualizacao(obj: any) {

        setQualGrid(<><FormModeloImportacao Data={obj} /></>)
    }


    function AbrirModelo(form: any) {
        setQualGrid((<><GridSecundarioModelos Iconeadd={ChamarVisualizacao}
            th1='Ativo'
            th2='Descrição'
            th3='Prefixo do arquivo *'
            th4='Subcategoria'
            th5='Data da Inclusão'
            th6='Data Atualização'
            endPoint='importmodels/listar'
            consult='N'
            col1='1'
            col2='2'
            col3='3'
            ambiente='%'
        /></>)
        )
   
        setQualTitulo(<><h1 className='modal-tit'>Gerenciar Modelos</h1></>)
        setOpenModalIncluirNovo(true)

    }


    function visualizarLog(obj: any) {

        setQualGrid((<><FormLogExecucao label={obj.irg_executionrecord} /></>)
        )
        setQualTitulo(<><h1 className='modal-tit'>Log de Execução</h1></>)
        setOpenModalIncluirNovo(true)
    }

    function Atualizar() {
        window.location.reload();
    }

	function fecharOpenModalIncluirNovo() {
		setOpenModalIncluirNovo(false)
	}

    return (
        <>
            <Nav />
            <div className="main-content">
                <Header />
                <main>
                    <div className='containerPrincipal'>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">1</div>
                                <h2>Importar Arquivo</h2>
                            </div>
                            <hr />
                        </div>

                        <div className='ContainerBodyComple'>

                            <div className='import-div'>
                                <div className='import-div-lab'>
                                    <label>Arquivo: </label>
                                </div>
                                <input type='file' accept='.csv' onChange={lerDadosArquivo} />
                            </div>

                            <button className='dropOpcao-import' onClick={AbrirModelo}>
                                <a>Gerenciar Modelos</a>
                            </button>
                        </div>

                        <div className="Bt-button-cad-imp">
                            <button type="submit" onClick={validatTokeninsert}>
                                <i className="fa fa-check-circle icon-ok-btn"></i> Confirmar Importação
                            </button>
                          
                        </div>

                        <div className="title-Page">
                            <div className="title-text">
                                <div className="size-x circle">2</div>
                                <h2>Lista de importação</h2>
                            </div>
                            <hr />
                        </div>

                        {gridImportacao}


                    </div>
                </main>
            </div>


            <ModalIncluirNovo isOpen={openModalIncluirNovo} setModalOpen={() => setOpenModalIncluirNovo(!openModalIncluirNovo)}>
				<>
					<div className='title-modal-incluir'>
						{qualTitulo}
						<button className="fa fa-times icone-modal-IncluirNovo" data-dismiss="modal" aria-label="Close" onClick={fecharOpenModalIncluirNovo} />
					</div>

					<div className='container-modal-incluir'>
						{qualGrid}
					</div>
				</>
			</ModalIncluirNovo>

            <ModalPrincipal isOpen={openModal} setModalOpen={() => setOpenModal(!openModal)}>
                <div className='container-modal-alert'>
                    {qualAlert}
                </div>
            </ModalPrincipal>

        


        </>
    );

}

export default ImportarDados;