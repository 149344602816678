import React, {useState, useEffect, useRef} from 'react';
import './App.css';

function DropdownIt(props) {

  const [open, setOpen] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false);
        
      }      
    };
    document.addEventListener("mousedown", handler);
    return() =>{
      document.removeEventListener("mousedown", handler);
    }
  });

  function selecionar(){
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOpen(false);
        
      }      
    };
    document.addEventListener("mousedown", handler);
    return() =>{
      document.removeEventListener("mousedown", handler);
    }
  }
  
  return (
    <div className="App">
      <div className='menu-container' ref={menuRef}>
        <div className='menu-trigger' onClick={()=>{setOpen(!open)}}>
          <div className='dropOpcao'><h1>Opções</h1> <span className='fa-sharp fa-solid fa-chevron-down ControleIco'></span> </div>
         
        </div>
        <div className={`dropdown-menu ${open? 'active' : 'inactive'}`} >
          <ul>
          <li className = 'dropdownItem' onClick={() => props.funtoggle('Importar')}>
            <span className='fa-solid fa-file-import ControleIco'></span>
              <a>Importação Dados</a>
          </li>
          
          </ul>
        </div>
      </div>
    </div>
  );
}

function DropdownItem(props){
  return(
    <li className = 'dropdownItem'>
      <img src={props.img}></img>
      <a> {props.text} </a>
    </li>
  );
}

export default DropdownIt;
