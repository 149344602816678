import React, { useMemo } from 'react';
import { Container }  from './styles';

interface IWalletBoxProps {
    title: string;
    icon: 'dolar' | 'arrowUp' | 'arrowDown';
    color: string;
    icone: string;
    valor: string;
    chamarModalTotal: any
}

const WalletBox: React.FC<IWalletBoxProps> = ({
    title,
    color,
    icone,
    valor,
    chamarModalTotal
}) => {
    return (
        <Container color={color}>        
            <span>{title}</span>
            <h1>
                {valor}
            </h1>     
            <div className='ali-icon'>
                <i className={icone}></i>
            </div>
        </Container>
    );
}

export default WalletBox;